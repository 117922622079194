import { Popup as NativePopup } from 'react-map-gl';
import { Button } from 'components';
import { TL } from 'utils';
import styles from './styles.module.scss';

const Popup = ({ popupInfo, setPopupInfo, onPopupClick }: any) => (
  //@ts-ignore
  <NativePopup
    tipSize={5}
    className={styles.popup}
    longitude={Number(popupInfo.longitude)}
    latitude={Number(popupInfo.latitude)}
    closeOnClick={false}
    onClose={() => setPopupInfo(false)}
  >
    <div className={styles.popupContent}>
      <p className={`${styles.popupTitle} H600Medium`}>{popupInfo.fullName || popupInfo.displayName}</p>
      <p className={`${styles.popupTitle} H400Medium`}>{popupInfo.email}</p>
      <div>
        <Button onClick={() => onPopupClick(popupInfo.id) || null} text={TL('backend_orders_drawer_edit_item_btn')} />
      </div>
    </div>
  </NativePopup>
);
export default Popup;
