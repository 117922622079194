import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Accordion, Button, Icon, PromptDialog, AvatarName } from 'components';
import Drawer from './components/drawer';
import { setLocalStorage, getLocalStorage } from 'utils/localStorage';
import { configMenu } from 'services';
import { useStateTableScreenValue, _resetState } from 'store/tableScreen';
import { useUser, useStateRef, useConfig } from 'hooks';
import { TL } from 'utils';
import styles from './styles.module.scss';

export default function Sidebar() {
  const [collapsed, setCollapsed] = useState(getLocalStorage('collapsedSidebar') || false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [displaySubitems, setDisplaySubitems, displaySubitemsRef] = useStateRef('');
  const [menu, setMenu]: any = useState([]);
  const [modal, setModal] = useState(false);
  const { user, setUser } = useUser();
  const [, tableScreenDispatch] = useStateTableScreenValue();
  const navigate = useNavigate();
  const location = useLocation();
  const { channelIcon, colors } = useConfig();

  const logout = () => {
    localStorage.clear();
    setUser({});
    navigate('/login');
  };

  const goToDashboard = () => {
    tableScreenDispatch(_resetState({}));
    navigate('/admin/dashboard');
  };

  const toggleSubitems = (label: string) => {
    if (label === displaySubitems) return setDisplaySubitems('');
    if (collapsed) setCollapsed(false);
    setDisplaySubitems(label);
  };

  const renderSubitems = (subitems: any, index: number, isPopup = false) => {
    return subitems.map((subitem: any, idx: number) => {
      const selected = window.location.pathname === subitem.id;
      return (
        <Link
          key={`subitem_${index}_${idx}`}
          onClick={(e) =>
            subitem.id !== location.pathname ? tableScreenDispatch(_resetState({})) : e.preventDefault()
          }
          to={subitem.id}
          className={`${styles.route} ${!isPopup ? styles.subitem : ''} ${selected ? styles.selected : ''}`}
        >
          <span className={`${styles.label} h200`}>{subitem.label}</span>
        </Link>
      );
    });
  };

  // Uncomment this useEffect in case you want to enable popups
  // useEffect(() => {
  //   const closePopup = (e: MouseEvent) => {
  //     // @ts-ignore on older browsers it can be path instead of composedPath (?)
  //     const path = e.path || (e.composedPath && e.composedPath());
  //     if (!path) return;
  //     if (displaySubitemsRef.current) {
  //       if (
  //         !path.some(
  //           (x: any) => x.className && typeof x.className === 'string' && x.className.includes(styles.popupWrapper)
  //         )
  //       ) {
  //         setDisplaySubitems('');
  //       }
  //     }
  //   };

  //   window.addEventListener('click', (e) => closePopup(e));
  //   return () => {
  //     window.removeEventListener('click', (e) => closePopup(e));
  //   };
  // }, [setDisplaySubitems, displaySubitemsRef, collapsed]);

  useEffect(() => {
    setLocalStorage('collapsedSidebar', collapsed);
  }, [collapsed]);

  useEffect(() => {
    const menuItems: any = [];
    const subItems: any = [];
    const routes = getLocalStorage('routes') || configMenu.currentRoutes || [];

    routes.forEach((route: any) => {
      if (route.subitem) {
        subItems.push(route);
      } else {
        menuItems.push(route);
      }
    });

    subItems.forEach((item: any) => {
      const { subitem } = item;
      const menuId = menuItems.findIndex((r: any) => r.id === subitem);
      if (menuId !== -1) {
        if (!menuItems[menuId].subitems) {
          menuItems[menuId].subitems = [];
        }
        menuItems[menuId].subitems.push(item);
      }
    });

    menuItems.forEach((i: any) => {
      const { subitems } = i;
      if (subitems) {
        const isSubItemSelected = i.subitems.some((subitem: any) => subitem.id === window.location.pathname);
        if (isSubItemSelected) toggleSubitems(i.label);
      }
    });

    setMenu(menuItems);
    //eslint-disable-next-line
  }, []);

  const openModal = () => {
    setCollapsed(false);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const SideBarRender = () => {
    return (
      <div className={`${styles.sidebar} ${collapsed ? styles.collapsed : ''} H300Medium`}>
        <div className={styles.header}>
          <div onClick={goToDashboard} className={styles.mainLogo} role="button">
            <img src={channelIcon} alt="main_logo" width={collapsed ? 45 : 100} />
          </div>
        </div>

        <div className={styles.routes}>
          {menu.length > 0 &&
            menu
              .sort((a: any, b: any) => {
                if (a.position && b.position) return a.position - b.position;
                return 0;
              })
              .map((item: any, index: any) => {
                const selected = window.location.pathname === item.id;
                const { type, subitems } = item;

                if (type === 'subitem' && subitems && subitems.length > 0) {
                  const isSubItemSelected = subitems.some((subitem: any) => subitem.id === window.location.pathname);
                  const isOpen = displaySubitems === item.label;
                  const isPopupOpen = isOpen && collapsed;
                  const isAccordionOpen = isOpen && !collapsed;
                  return (
                    <React.Fragment key={`menu-item_${index}`}>
                      <div className={styles.subitemWrapper}>
                        <div
                          role={'button'}
                          tabIndex={0}
                          className={`${styles.route} ${styles.withSubitems} ${
                            (isSubItemSelected || isOpen) && styles.selected
                          }`}
                          onClick={(e) => {
                            toggleSubitems(item.label);
                            e.preventDefault();
                          }}
                        >
                          <Icon icon={item.icon} size="M" color={colors.secondary} />
                          <span className={`${styles.label} H200Medium`}>{item.label}</span>
                        </div>
                        <Accordion open={isPopupOpen} alignment="horizontal" className={styles.popupWrapper}>
                          <div className={styles.popup}>{renderSubitems(subitems, index, true)}</div>
                        </Accordion>
                      </div>
                      <Accordion open={isAccordionOpen}>{renderSubitems(subitems, index)}</Accordion>
                    </React.Fragment>
                  );
                }
                return (
                  <Link
                    key={`menu-item_${index}`}
                    onClick={(e) =>
                      item.id !== location.pathname ? tableScreenDispatch(_resetState({})) : e.preventDefault()
                    }
                    to={item.id}
                    className={`${styles.route} ${selected && styles.selected}`}
                  >
                    <Icon icon={item.icon} size="M" color={colors.secondary} />
                    <span className={`${styles.label} H200Medium`}>{item.label}</span>
                  </Link>
                );
              })}
        </div>

        {user ? (
          <>
            <div className={styles.user}>
              <AvatarName />
              <span className={`${styles.userName} H200Medium`}>
                {user?.firstName} {user?.lastName}
              </span>
              <div className={styles.logout}>
                <div onClick={() => setShowPrompt(!showPrompt)} role="button">
                  <Icon icon="Logout" size="M" />
                </div>
              </div>
            </div>
            <div
              className={styles.logoutCollapsed}
              onClick={() => setShowPrompt(!showPrompt)}
              role="button"
              tabIndex={1}
            >
              <Icon icon="Logout" size="M" />
            </div>
          </>
        ) : null}

        <div className={styles.flex}>
          <Button
            onClick={() => {
              setCollapsed(!collapsed);
            }}
            type={'secondaryLine'}
            icon={collapsed ? 'ChevronsRight' : 'ChevronsLeft'}
            iconAlignment={'only'}
            fullWidth
          />
        </div>

        <PromptDialog
          open={showPrompt}
          onCancel={() => setShowPrompt(!showPrompt)}
          onConfirm={logout}
          icon={'Logout'}
          iconColor={configMenu.config?.colors.colors.black}
          message={TL('backend_logout_confirmation')}
        />
      </div>
    );
  };

  return (
    <>
      <div className={styles.sidebarBtn} onClick={openModal}>
        <Button icon="Menu" iconAlignment="only" type="secondaryLine" />
      </div>
      {modal && (
        <Drawer closeFn={() => closeModal()}>
          <div className={styles.sidebarModalWrapper}>{SideBarRender()}</div>
        </Drawer>
      )}
      {SideBarRender()}
    </>
  );
}
