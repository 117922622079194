import { State } from '../types';

const onRowClick = (itemId: any, state: State) => {
  let id = itemId;
  if (typeof id !== 'string' && typeof id !== 'number') {
    // workaround to for the rowClick;
    if (itemId.props.id) {
      id = itemId.props.id;
    } else {
      id = itemId.props.children;
    }
  }
  const selectedItem = state.list.items.find((item) => item.id === id);
  if (process.env.NODE_ENV === 'development') console.log('ROW CLICK: ', selectedItem);
  if (!selectedItem) return state.selectedItem;
  // eslint-disable-next-line
  let newItem = { ...state.selectedItem };
  if (state?.selectedItem?.open === true) {
    return (newItem = { open: false, item: false });
  } else {
    return (newItem = {
      open: true,
      item: { ...selectedItem, colorPicker: '#cccccc' },
    });
  }
};

export default onRowClick;
