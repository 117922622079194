import { ConfigContextProvider } from 'context/ConfigContext';
import { LocaleContextProvider } from 'context/LocaleContext';
import Router from './router';

import '../styles/typography.scss';
import 'react-components/build/theme.default';

const App = () => {
  return (
    <LocaleContextProvider>
      <ConfigContextProvider>
        <Router />
      </ConfigContextProvider>
    </LocaleContextProvider>
  );
};

export default App;
