import { useState } from 'react';
import { Props } from './types';
import './index.scss';

const TextTruncater = ({ defaultExpanded, text, sufix, limit, mode, Function, showMore }: Props) => {
  const [isTruncated, setIsTruncated] = useState(defaultExpanded ? false : true);

  const createMarkup = (text: string) => {
    return { __html: text };
  };

  function getMoreTextDiv() {
    let truncateSufix = sufix;
    if (truncateSufix === '' || typeof truncateSufix === 'undefined') truncateSufix = '...';

    let truncateLimit = limit;
    if (truncateLimit <= 0 || typeof truncateLimit === 'undefined') truncateLimit = 300;

    let truncateMode = mode;
    if (truncateMode === '' || typeof truncateMode === 'undefined') truncateMode = 'char';
    if (truncateMode !== 'word' && truncateMode !== 'char') truncateMode = 'char';

    if (!isTruncated) {
      return (
        <div>
          <span dangerouslySetInnerHTML={createMarkup(text)}></span>
          <span className="toggler" onClick={() => setIsTruncated(!isTruncated)}>
            {' Show less'}
          </span>
        </div>
      );
    }
    if (text.length <= truncateLimit) {
      return <span dangerouslySetInnerHTML={createMarkup(text)} />;
    }

    // truncate's limit correction, truncating by word
    if (truncateMode === 'word') {
      for (let i = truncateLimit; i < text.length; i--) {
        if (text[i] === ' ') {
          truncateLimit = i;
          break;
        }
      }
    }

    let finalText = text.substr(0, truncateLimit);
    finalText += truncateSufix;

    return showMore ? (
      <div className="textTruncated showMore">
        {finalText}
        <span onClick={Function ? () => Function() : () => setIsTruncated(!isTruncated)} className="toggler">
          {' Lear more'}
        </span>
      </div>
    ) : (
      <div>
        <span className="textTruncated" dangerouslySetInnerHTML={createMarkup(finalText)} />
      </div>
    );
  }

  if (!text) return <></>;

  return <>{getMoreTextDiv()}</>;
};

export default TextTruncater;
