import MapGL, { NavigationControl } from 'react-map-gl';
import Popup from './popup';
import useMap from './useMap';
import Markers from './markers';
import styles from './styles.module.scss';

const TOKEN = 'pk.eyJ1Ijoic3BvdGxpbyIsImEiOiJjam51MnJzbTEwd3VuM3BsZWoyaDFjeWVkIn0.XDA_557e1cKki5sBpZFE7w';

const Map = ({ suppliers, update, className, mapPadding, onPopupClick }: any) => {
  const { viewport, points, popupInfo, setViewport, setPopupInfo } = useMap(suppliers, mapPadding);

  return (
    <div className={`${styles.mapWrapper} ${className}`}>
      <MapGL
        dragPan={update !== false && true}
        touchZoom={update !== false && true}
        scrollZoom={update !== false && true}
        doubleClickZoom={update !== false && true}
        {...viewport}
        onViewportChange={(nextViewport: any) => setViewport(nextViewport)}
        width={'100%'}
        height={'100%'}
        mapStyle="mapbox://styles/mapbox/outdoors-v11"
        mapboxApiAccessToken={TOKEN}
        mapboxAccessToken={TOKEN}
      >
        {/* {update && (
          <div style={{ position: 'absolute', left: 15, top: 15 }}>
            <NavigationControl />
          </div>
        )} */}
        {popupInfo && update && <Popup popupInfo={popupInfo} setPopupInfo={setPopupInfo} onPopupClick={onPopupClick} />}
        {points && <Markers points={points} setPopupInfo={setPopupInfo} />}
      </MapGL>
    </div>
  );
};

export default Map;
