import React, { createContext, useContext, useReducer } from 'react';
import { State, StateProviderProps, Action } from './types';

export const initialState: State = {
  stateLoaded: false,
  loading: true,
  list: {
    total: 0,
    filteredTotal: 0,
    paginator: {
      start: 0,
      limit: 100,
      sort: undefined,
    },
    page: 1,
    items: [],
    loading: true,
    filters: undefined,
    searchQuery: null,
  },
  selectedItem: {
    open: false,
    item: false,
  },
  toCreateItem: {
    open: false,
    item: false,
  },
  customFilters: undefined,
  defaultFilters: null,
  filterTableFields: null,
  formErrors: undefined,
  imageAccessor: undefined,
  isGalleryOpen: false,
  queryStringMode: false,
  quickFiltersButtons: undefined,
  quickFilterToEdit: null,
  refresh: false,
  selectedCheckboxs: null,
  selectedDrawerMode: 'view',
  selectedDrawerTab: 0,
  selectedFilterBtn: 0,
  showMap: 0,
  showPromptDelete: false,
  showPromptMissingInfoEmail: false,
  showPromptQRCode: false,
  showPromptResendEmail: false,
  showSnackbar: false,
  snackbarMessage: '',
  snackbarType: undefined,
  toDeleteIds: null,
  showFilters: false,
  advancedFilters: [],
  showModal: false,
  createModal: false,
  selectedCreateTab: -1,
  addProductLoading: false,
  addProductState: undefined,
  loadingReadOnlyDrawer: false,
  readOnlyDrawerOpen: false,
  routeToQuery: undefined,
  filterToQuery: undefined,
  readOnlyDrawerData: undefined,
  textFields: undefined,
  arrayFilters: [],
};

export const TableScreenContext = createContext<[State, React.Dispatch<Action>]>([initialState, () => initialState]);

export const TableScreenProvider: React.FC<StateProviderProps> = ({ tableReducer, children }: StateProviderProps) => {
  const [state, dispatch] = useReducer(tableReducer, initialState);
  return <TableScreenContext.Provider value={[state, dispatch]}>{children}</TableScreenContext.Provider>;
};

export const useStateTableScreenValue = () => useContext(TableScreenContext);
