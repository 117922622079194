import ReactEcharts from 'echarts-for-react';
import useChartConfig from './useChartConfig';
import useParserChart from './useParserChart';

const Chart = ({ data, allData, view, newGraphicData, height = '300px' }: any) => {
  const { parsedData } = useParserChart({ data, allData, view, newGraphicData });
  const { options } = useChartConfig({ data, allData, view, parsedData });

  return <ReactEcharts option={options} style={{ height, maxWidth: window.innerWidth - 350 }} />;
};

export default Chart;
