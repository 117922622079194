import axios from 'axios';
import logged from './logged';

class Requester {
  constructor() {
    this.UNEXPECTED_ERROR_CODE = 500;

    let initConf = {
      headers: {
        /*"X-Custom-Header": "foobar"*/
      },
      timeout: 30000,
    };
    let service = axios.create(initConf);
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleResponse(response) {
    if (response && response.status) {
      if (response.status >= 200 && response.status <= 299) {
        return Promise.resolve(response.data);
      } else {
        return this.handleError(response.status);
      }
    }
    return this.handleError(this.UNEXPECTED_ERROR_CODE);
  }

  handleSuccess(response) {
    return response;
  }

  handleError(error) {
    return Promise.reject(error);
  }

  // TODO. More options for headers, base url, etc...
  handleRequestMethod(method, path, data, headers) {
    let request = {
      responseType: 'json',
      method: method,
      url: path,
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        ...headers,
      },
    };

    if (data) {
      request = { ...request, data: data };
    }

    return request;
  }

  async get(path, config) {
    try {
      const response = await this.service.get(path, config);
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(this.UNEXPECTED_ERROR_CODE);
    }
  }

  async post(path, data, headers) {
    try {
      //Parseamos a formato JSON - {"email": "florentinoperez@gmail.com"}
      // data = JSON.stringify(data, { arrayFormat: 'brackets' });
      const response = await this.service.request(this.handleRequestMethod('POST', path, data, headers));
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(this.UNEXPECTED_ERROR_CODE);
    }
  }

  async patch(path, data = false) {
    try {
      const response = await this.service.request(this.handleRequestMethod('PATCH', path, data));
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(this.UNEXPECTED_ERROR_CODE);
    }
  }

  async put(path, data, headers) {
    try {
      const response = await this.service.request(this.handleRequestMethod('PUT', path, data, headers));
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(this.UNEXPECTED_ERROR_CODE);
    }
  }

  async delete(path, headers) {
    try {
      const response = await this.service.request(this.handleRequestMethod('DELETE', path, false, headers));
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(this.UNEXPECTED_ERROR_CODE);
    }
  }

  async requestWithBearer(method, url, data) {
    const token = logged.getSessionToken();

    const headers = {
      Authorization: 'Bearer ' + token,
    };
    try {
      if (method === 'get') {
        const response = await this.get(url, { headers: headers });
        return response;
      }
      if (method === 'post') {
        const response = await this.post(url, data, headers);
        return response;
      }
      if (method === 'put') {
        const response = await this.put(url, data, headers);
        return response;
      }
      if (method === 'delete') {
        const response = await this.delete(url, headers);
        return response;
      }
    } catch (e) {
      return null;
    }
  }
}

export default new Requester();
