import { useState, ReactNode, createContext } from 'react';
import getLocale from 'logic/getLocale';
import { Locale, Locales } from 'interfaces/config';
interface Prop {
  children: ReactNode;
}
const Context = createContext<{
  locale: Locale;
  setLocale: (c: Locale) => void;
  contentLocale: Locales;
  setContentLocale: (c: Locales) => void;
}>({
  locale: {
    id: '',
    label: 'en',
  },
  setLocale: (c: Locale) => {},
  contentLocale: 'en',
  setContentLocale: (c: Locales) => {},
});

export function LocaleContextProvider({ children }: Prop) {
  const [locale, setLocale] = useState(getLocale());
  const [contentLocale, setContentLocale] = useState<Locales>('en');

  return <Context.Provider value={{ locale, setLocale, contentLocale, setContentLocale }}>{children}</Context.Provider>;
}

export default Context;
