type Client = {
  [a: number]: string;
};

const CLIENT: Client = {
  0: 'bjorli',
  1: 'flamingo',
  2: 'aramon',
};

export { CLIENT };
