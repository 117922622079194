import { DateTime } from 'luxon';
import { PassholderPhoto } from 'components';
import { removeLastWordAfterScript, TL } from 'utils';
import styles from './styles.module.scss';

const Passholder = ({ passholder }: any) => {
  if (!passholder) return <p>{TL('backend_no_data')}</p>;
  const { productName, type, requirements } = passholder;
  const parsedData: any = {};
  requirements?.forEach(
    (el: any) =>
      (parsedData[removeLastWordAfterScript(el.identifier)] =
        typeof el.value === 'string' ? el?.value?.toUpperCase() : el?.value)
  );
  const birthdate = parsedData['age-all'] || parsedData['age-adult'] || parsedData['age-junior'];

  const firstName = parsedData['first-name'];
  const lastName = parsedData['last-name'];

  const firstNameFirstLetter = firstName[0];
  const lastNameFirstLetter = lastName[0];

  const parseAge = () => {
    let age = DateTime.fromFormat(birthdate, 'yyyy-MM-dd').diffNow('years').years;
    if (isNaN(age)) {
      age = DateTime.fromFormat(birthdate, 'dd/MM/yyyy').diffNow('years').years;
      if (isNaN(age)) {
        return '--';
      }
    }
    return prettifyAge(age) + ' years';
  };

  const prettifyAge = (age: number) => Math.trunc(Math.abs(age));

  const renderType = type === 'isJunior' ? TL('backend_junior') : TL('backend_adult');

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <PassholderPhoto src={parsedData['photo']} name={`${firstNameFirstLetter}${lastNameFirstLetter}`} />
      </div>
      <div>
        {productName && (
          <div className={`${styles.title} H300Medium`}>
            <span>{`${firstName} ${lastName}`}</span>
          </div>
        )}
        <div className={`${styles.details} H300Medium`}>
          {type && <span>{renderType}</span>}
          <span>{parseAge()}</span>
          <span>{productName}</span>
          {parsedData['keycard'] && (
            <span>{parsedData['keycard'] !== '0' ? parsedData['keycard'] : TL('backend_passholder_keycard_new')}</span>
          )}
        </div>
      </div>
    </div>
  );
};
export default Passholder;
