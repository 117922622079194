import { useConfig } from 'hooks';
import { Navigate } from 'react-router-dom';
import { configMenu } from 'services';

const DefaultRoute = () => {
  const { channels } = useConfig();
  return <Navigate to={channels?.length > 1 ? '/admin/dashboard' : configMenu.getDefaultRoute()} />;
};

export default DefaultRoute;
