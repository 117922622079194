import { useContext } from 'react';
import UserContext from 'context/UserContext';

const useConfig = () => {
  const { user, setUser } = useContext(UserContext);

  return {
    user,
    setUser,
  };
};

export default useConfig;
