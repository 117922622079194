import { useEffect, useState } from 'react';
import WebMercatorViewport from 'viewport-mercator-project';

const useMap = (suppliers: any, mapPadding: any) => {
  const [viewport, setViewport] = useState<any>({
    width: document.getElementsByClassName('mapboxgl-map')[0]?.clientWidth,
    height: document.getElementsByClassName('mapboxgl-map')[0]?.clientHeight,
    latitude: 0,
    longitude: 0,
    zoom: 1,
  });
  const [points, setPoints] = useState<any[]>([]);
  const [popupInfo, setPopupInfo] = useState<any>(false);

  useEffect(() => {
    if (viewport.width && viewport.height && suppliers) {
      updateMap(suppliers);
    }
    //eslint-disable-next-line
  }, [suppliers.length, viewport.height, viewport.width, suppliers]);

  const updateMap = (markers: any, newViewport = viewport) => {
    const arrayPoints: any[] = [];
    const bottomLeft = [undefined, undefined];
    const topRight = [undefined, undefined];

    markers.forEach((marker: any, index: number) => {
      marker.latitude = Number(marker.latitude);
      marker.longitude = Number(marker.longitude);
      if (marker.latitude !== null && marker.latitude !== 0 && marker.latitude < 90 && marker.latitude > -90) {
        if (bottomLeft[1] === undefined || marker.latitude < bottomLeft[1]) {
          bottomLeft[1] = marker.latitude;
        }
        if (topRight[1] === undefined || marker.latitude > topRight[1]) {
          topRight[1] = marker.latitude;
        }
        if (bottomLeft[0] === undefined || marker.longitude < bottomLeft[0]) {
          bottomLeft[0] = marker.longitude;
        }
        if (topRight[0] === undefined || marker.longitude > topRight[0]) {
          topRight[0] = marker.longitude;
        }

        marker.index = index;
        arrayPoints.push(marker);
      }
    });
    if (arrayPoints.length > 1) {
      const corners: any = [bottomLeft, topRight];
      const WBViewport = new WebMercatorViewport({
        width: viewport.width,
        height: viewport.height,
      });
      const bound = WBViewport.fitBounds(corners, {
        padding: mapPadding ? mapPadding : { top: 40, bottom: 5, left: 20, right: 20 },
      });
      newViewport.latitude = bound.latitude;
      newViewport.longitude = bound.longitude;
      newViewport.zoom = bound.zoom;
    } else if (suppliers.length > 0) {
      newViewport.latitude = suppliers[0].latitude;
      newViewport.longitude = suppliers[0].longitude;
      newViewport.zoom = 13;
    }

    setViewport(newViewport);
    setPoints(arrayPoints);
  };

  return {
    points,
    popupInfo,
    viewport,
    setViewport,
    setPopupInfo,
  };
};

export default useMap;
