import { Button } from 'components';
import { Props } from './types';
import style from './style.module.scss';

export default function Toggle({ selectedBtn, onChange, wrapperClass, leftLabel, rightLabel }: Props) {
  return (
    <div className={`${style.filtersToggle} ${wrapperClass}`}>
      {/* @to-do apply the spotlio toggle component */}
      <Button type={selectedBtn === 0 ? 'primary' : 'secondary'} onClick={() => onChange(0)} text={leftLabel} />
      <Button type={selectedBtn === 1 ? 'primary' : 'secondary'} onClick={() => onChange(1)} text={rightLabel} />
    </div>
  );
}
