import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import formatDates from 'components/formatdates';
import { getLiveStatus } from 'services/liveStatusService';

const useLiveStatus = (path: string, token?: string) => {
  const [status, setStatus] = useState<any>({});
  const [now, setNow] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const res = await getLiveStatus(path);
      const date: any = DateTime.now();
      setNow(formatDates({ date, full: true }));
      setStatus(res);
      setLoading(false);
    })();
  }, [path, token]);

  const succeessfulResponse = status?.Contact?.Id || status?.payment?.paymentId;

  return {
    loading,
    succeessfulResponse,
    now,
  };
};
export default useLiveStatus;
