import { Props } from './types';
import styles from './styles.module.scss';

const TextArea = ({ placeholder, onBlur, value, name, onChange, id, disabled }: Props) => (
  <div className={`${styles.textareaWrapperBackend} drawerTextarea`}>
    <textarea
      className={`${styles.textarea} H400Medium`}
      rows={2}
      placeholder={placeholder}
      onBlur={onBlur}
      value={value}
      onChange={onChange}
      name={name}
      id={id}
      disabled={disabled}
    />
  </div>
);

export default TextArea;
