import { Destination } from 'components';
import { TL } from 'utils';
import { Props } from './types';
import styles from './styles.module.scss';

export default function DestinationList({ destinations }: Props) {
  return (
    <ul className={styles.container}>
      <h1 className={`${styles.title} H600Medium`}>{TL('backend_dashboard_select_destination')}</h1>
      {destinations?.map((destination, i) => (
        <Destination destination={destination} key={i} />
      ))}
    </ul>
  );
}
