import { Option } from 'components';

const OptionsContainer = ({
  options,
  onClick,
  selectedOption,
}: {
  options: any[];
  onClick: any;
  selectedOption: any;
}) => (
  <>
    {options.map((option, i) => {
      return (
        <Option onClick={onClick} key={i} value={option} isActive={selectedOption === option ? true : false}>
          <span>{option}</span>
        </Option>
      );
    })}
  </>
);

export default OptionsContainer;
