import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import styles from './styles.module.scss';

const AlertDialog = ({ open, close, title, description, buttonText, onClick, content }) => (
  <div>
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ styles: styles.dialog }}
    >
      <div className={styles.container}>
        {title && (
          <div className={`${styles.title} H400Medium`} id="alert-dialog-title">
            {title}
            iaejfie
          </div>
        )}
        {description && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
          </DialogContent>
        )}

        {content ? content : null}

        {buttonText && (
          <div onClick={onClick} className={`${styles.mainButton} H400Medium`}>
            <span>{buttonText}</span>
          </div>
        )}
      </div>
    </Dialog>
  </div>
);

export default AlertDialog;
