import { ReactNode } from 'react';
import { Button } from 'components';
import styles from './styles.module.scss';

const Option = ({
  children,
  onClick,
  value,
  isActive,
}: {
  children: ReactNode;
  onClick: (e: any) => void;
  value: any;
  isActive: boolean;
}) => (
  <Button onClick={() => onClick(value)} type={`${isActive ? 'primary' : 'secondary'}`}>
    {children}
  </Button>
);

export default Option;
