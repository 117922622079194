import { ReactNode } from 'react';
import styles from './styles.module.scss';

const Container = ({
  children,
  verticalPadding,
  horizontalPadding,
  display,
  justifyContent,
  flexDirection,
  height,
}: {
  children: ReactNode;
  verticalPadding?: number;
  horizontalPadding?: number;
  display?: 'flex';
  justifyContent?: 'flex-end';
  flexDirection?: 'column';
  height?: string;
}) => {
  return (
    <div
      className={styles.container}
      style={{
        paddingTop: `${verticalPadding}rem`,
        paddingBottom: `${verticalPadding}rem`,
        paddingRight: `${horizontalPadding}rem`,
        paddingLeft: `${horizontalPadding}rem`,
        gap: '8px',
        height,
        display,
        justifyContent,
        flexDirection,
      }}
    >
      {children}
    </div>
  );
};

export default Container;
