import { formatBigNumbers } from 'utils/formatBigNumbers';

const useChartConfig = ({ view, parsedData }: any) => {
  const { xData, yData, mobile, desktop, products, productsValues } = parsedData;

  const options: any = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: [],
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ['line', 'bar', 'stack'] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: xData,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: function (value: number, index: number) {
            return formatBigNumbers(value);
          },
        },
      },
    ],
    series: [],
  };

  if (view === 'byProduct') {
    options.legend.data = ['By Product'];
    options.series = [];

    const byProduct: any = [];

    products.forEach((p: any, i: number) => {
      p.forEach((name: any) => {
        const alreadyExists = byProduct.some((pr: any) => pr.name === name);
        if (!alreadyExists) {
          // options.legend.data.push(name);
          byProduct.push({
            name: name,
            type: 'bar',
            stack: 'By Product',
            emphasis: {
              focus: 'series',
            },
            data: [],
          });
        }
      });
    });

    products.forEach((prodArray: any, i: number) => {
      byProduct.forEach((pr: any, idx: number) => {
        const findIndex = prodArray.findIndex((p: any) => p === pr.name);
        if (findIndex !== -1) {
          byProduct[idx].data.push(productsValues[i][findIndex]);
        } else {
          byProduct[idx].data.push(undefined);
        }
      });
    });

    options.series = byProduct;
  } else if (view === 'byPlatform') {
    options.legend.data = ['Mobile', 'Desktop'];
    options.series = [
      {
        name: 'Mobile',
        type: 'bar',
        stack: 'By Platform',
        // label: 'mobile',
        emphasis: {
          focus: 'series',
        },
        data: mobile,
      },
      {
        name: 'Desktop',
        type: 'bar',
        stack: 'By Platform',
        // label: 'desktop',
        emphasis: {
          focus: 'series',
        },
        data: desktop,
      },
    ];
  } else if (view === 'total') {
    options.legend.data = ['Total'];
    options.series = [
      {
        name: 'Total',
        type: 'bar',
        barGap: 0,
        stack: 'Total',
        // label: labelOption,
        emphasis: {
          focus: 'series',
        },
        data: yData,
      },
    ];
  }

  return { options };
};

export default useChartConfig;
