import { TableRouteTabFieldType } from 'interfaces';

const TabFiledTypes: any = {
  boolean: false,
  colorPicker: '#ffffff',
  dropdownsArray: [],
  module: [],
  group: null,
  image: null,
  default: '',
};

const getDefaultValueByType = (type: TableRouteTabFieldType) =>
  TabFiledTypes[type] ? TabFiledTypes[type] : TabFiledTypes['default'];

export default getDefaultValueByType;
