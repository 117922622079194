import { Session } from 'hooks/types';
import { createContext, useState, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getLocalStorage } from 'utils';

interface Prop {
  children: ReactNode;
}
const defaultSesion: Session = {
  token: undefined,
  timestamp: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  id: undefined,
  isLogged: false,
  role: undefined,
};
const Context = createContext({
  user: defaultSesion,
  setUser: (c: Session) => {},
});

export function UserContextProvider({ children }: Prop) {
  const [user, setUser] = useState<Session>({ ...getLocalStorage('session') } || defaultSesion);
  const location = useLocation();

  useEffect(() => {
    setUser({ ...getLocalStorage('session') } || defaultSesion);
  }, [location]);

  return <Context.Provider value={{ user, setUser }}>{children}</Context.Provider>;
}

export default Context;
