import { requester } from 'logic';

export const getLiveStatus = async (path: string) => {
  try {
    const res = await requester.get(path);
    return res;
  } catch (error) {
    console.log({ error });
  }
};
