import { useState } from 'react';
import { Icon, Accordion, Button } from 'components';
import styles from './styles.module.scss';

export default function NativeSelect({ placeholder, options, value, onChange, readOnly = false }: any) {
  const [active, setActive] = useState(false);

  const displayValue = options.find((o: any) => o.id === value)?.label;

  return (
    <div className={styles.selectWrapper}>
      <button
        className={`${styles.select} ${readOnly ? styles.readOnly : ''}`}
        onClick={() => setActive(!active)}
        disabled={readOnly}
      >
        {displayValue ? (
          <span className="H300Medium">{displayValue}</span>
        ) : (
          <span className={`${styles.placeholder} H300Medium`}>{placeholder}</span>
        )}
        {!readOnly && <Icon icon="ChevronDown" size="M" />}
      </button>
      <Accordion className={styles.dropdownWrapper} open={active}>
        <div className={styles.dropdown}>
          {options.map((o: any, idx: number) => {
            const { id, label } = o;
            return (
              <Button
                type="text"
                onClick={() => {
                  onChange(id);
                  setActive(false);
                }}
                key={`${label}_${idx}`}
                text={label}
              />
            );
          })}
        </div>
      </Accordion>
    </div>
  );
}
