import { DTAOrder, Spinner } from 'components';
import useDTA from './useDTA';
import { Props } from './types';
import styles from './styles.module.scss';

const DTAList = ({ client, uuid }: Props) => {
  const { data, loading, error } = useDTA({ client, uuid });

  if (error) return <p>{error}</p>;

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading}>
          <Spinner />
        </div>
      ) : null}
      {!loading && data ? (
        <div className={styles.content}>
          {' '}
          <DTAOrder data={data} />{' '}
        </div>
      ) : null}
    </div>
  );
};

export default DTAList;
