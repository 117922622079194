//@ts-nocheck
import { AlertDialog, Button, Icon } from 'components';
import { TL } from 'utils';
import styles from './style.module.scss';

export default function PromptDialog({ open, message, onConfirm, onCancel, icon, iconColor }: any) {
  return (
    <AlertDialog
      open={open}
      content={
        <div className={styles.alertDialogContent}>
          <Icon icon={icon} color={iconColor} size="L" />
          <span className={`${styles.alertDialogTitle} H400Medium`}>{message}</span>
          <div className={styles.alertDialogButtonsContainer}>
            <Button onClick={onCancel} text={TL('backend_cancel')} />
            <Button onClick={onConfirm} text={TL('backend_confirm')} />
          </div>
        </div>
      }
    />
  );
}
