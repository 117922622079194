import styles from './index.module.scss';
import Props from './types';

const SwitchComponent = ({ name, checked, value, disabled = false, onChange }: Props) => {
  const isReadOnly = onChange ? false : true;

  return (
    <>
      <label className={`${styles.switchLabel} ${checked ? styles.checked : styles.unchecked}`}>
        <input
          checked={checked}
          className={styles.switchCheckbox}
          type="checkbox"
          name={name}
          onChange={onChange}
          value={value}
          disabled={disabled}
          readOnly={isReadOnly}
        />
        <span className={styles.switchButton} />
      </label>
    </>
  );
};

export default SwitchComponent;
