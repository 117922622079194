import useCurrency from './useCurrency';
import { useConfig } from 'hooks';
import { Props } from './types';
import styles from './styles.module.css';

const Currency = ({
  amount,
  bold,
  numberOfDecimals,
  reverseUnit,
  shortFormat,
  thousandSeparator,
  unit,
  withDecimals,
  withDecimalSeparator,
  align,
}: Props) => {
  const { currency } = useConfig();
  const { unitMarginStyles, containerStyles, total } = useCurrency({
    align,
    amount,
    numberOfDecimals,
    reverseUnit,
    shortFormat,
    thousandSeparator,
    withDecimals,
    withDecimalSeparator,
  });

  return (
    <div className={containerStyles}>
      <div className={styles.content}>
        <div className={reverseUnit ? styles.reverseUnit : styles.unit}>
          <span className={bold ? styles.bold : undefined}>{total}</span>
          {unit ||
            (currency && (
              <span className={bold ? `${styles.unitMarginBold} ${unitMarginStyles}` : unitMarginStyles}>
                {unit === '' ? '' : currency}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Currency;
