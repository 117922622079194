import { Props } from './types';
import { TL } from 'utils';
import styles from './styles.module.scss';

const Overview = ({ data, title }: Props) => (
  <div className={styles.block}>
    <div className={`${styles.title} H400Medium`}>{TL(title)}</div>
    <div className={styles.overviews}>
      {data &&
        data.map((ow, i) => (
          <div className={styles.overview} key={ow.label}>
            <div className="H1100Medium">{ow.value}</div>
            <div className="H300Medium">{ow.label}</div>
          </div>
        ))}
    </div>
  </div>
);

export default Overview;
