import { useState } from 'react';
import { FieldError, Switch } from 'components';
import { Props } from './types';
import style from './style.module.scss';

const FieldWrapper = ({ children, error, field, onSwitchChange }: Props) => {
  const [visible, setVisible] = useState(field.optionalSwitch !== true);

  const handleSwitchChange = (ev: any) => {
    setVisible(ev.target.checked);
    if (onSwitchChange) onSwitchChange(ev.target.checked);
  };

  return (
    <div className={style.formGroup}>
      <div className={style.labelWrapper}>
        <label htmlFor={field.id} className="H300Medium">
          {field.label}
        </label>
        {field.optionalSwitch && <Switch checked={visible} onChange={handleSwitchChange} />}
      </div>
      {visible && children}
      {error && <FieldError error={error} />}
    </div>
  );
};

export default FieldWrapper;
