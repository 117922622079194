const CONNECT = process.env.REACT_APP_CONNECT;
const CONNECTV3 = process.env.REACT_APP_CONNECTV3;
const STRAPI_FLAMINGO = process.env.REACT_APP_STRAPI_FLAMINGO;
const STRAPI = process.env.REACT_APP_STRAPI_FLAMINGO;
const DKT = process.env.REACT_APP_DKT;
const SKIDATA_STATUS = process.env.REACT_APP_SKIDATA_STATUS;
const NEW_SKIDATA_STATUS = process.env.REACT_APP_NEW_SKIDATA_STATUS;
const NETSEASY_STATUS = process.env.REACT_APP_NETSEASY_STATUS;
//const STRAPI_USERS = process.env.REACT_APP_STRAPI_USERS;
//const CONNECTV3 = 'https://localhost:8000'; // SSL error cambiar de HTTP A HTPPS o viceversa.

const ANALYTICS = CONNECT + '/s-cart/summary';
const ARRIVALS = CONNECT + '/s-cart/ordersByDate';
const CONNECT3_LOGIN = CONNECTV3 + '/login';
const CREATE_PRODUCT_CONTEXT = CONNECTV3 + '/getCreateProductContext';
const DTA_DETAILS = CONNECT + '/checkout/dta-data';
const LOGIN = STRAPI_FLAMINGO + '/admin/login';
const ORDER_CUSTOMER_EDIT = STRAPI_FLAMINGO + '/sessions';
const PERMISSIONS = STRAPI_FLAMINGO + '/admin/users/me/permissions';
const PRODUCT_ENDPOINT = CONNECT + '/flamingo/products';

// config
const GET_CONFIG_MENU = CONNECTV3 + '/getMenuElements';

// mediaGallery
const GET_MEDIA_GALLERY = CONNECTV3 + '/getImages'; // @to-do usar el endpoint '/getImages';
const UPLOAD_MEDIA_GALLERY = CONNECTV3 + '/uploadImages';
const DELETE_MEDIA_GALLERY = CONNECTV3 + '/deleteImages';
const RENAME_MEDIA_GALLERY = CONNECTV3 + '/modifyImages';

// translations
const TRANSLATIONS = CONNECTV3 + '/translations';

const ORDERS_REPORT = CONNECTV3 + '/getOrdersReport';
const DKT_SUPPLIER_PAGE = DKT + '/supplier';

export {
  ANALYTICS,
  ARRIVALS,
  CONNECT,
  CONNECT3_LOGIN,
  CONNECTV3,
  CREATE_PRODUCT_CONTEXT,
  DELETE_MEDIA_GALLERY,
  DKT_SUPPLIER_PAGE,
  DKT,
  DTA_DETAILS,
  GET_CONFIG_MENU,
  GET_MEDIA_GALLERY,
  LOGIN,
  NETSEASY_STATUS,
  NEW_SKIDATA_STATUS,
  ORDER_CUSTOMER_EDIT,
  ORDERS_REPORT,
  PERMISSIONS,
  PRODUCT_ENDPOINT,
  RENAME_MEDIA_GALLERY,
  SKIDATA_STATUS,
  STRAPI_FLAMINGO,
  STRAPI,
  TRANSLATIONS,
  UPLOAD_MEDIA_GALLERY,
};
