import { Icon } from 'components';
import { useConfig } from 'hooks';
import styles from './style.module.scss';

export default function InfoContainer({ text }: any) {
  const { colors } = useConfig();
  return (
    <div className={styles.infoContainer}>
      <div className={styles.infoContent}>
        <Icon icon="AlertTriangle" size="M" color={colors.red} />
        <p className="H300Medium">{text}</p>
      </div>
    </div>
  );
}
