import { ReactComponent as CheckboxSvg } from './checkbox.svg';
import { ReactComponent as CheckedSvg } from './checked.svg';
import { Props } from './types';

const CheckBoxCustom = ({ checked, onClick, className }: Props) => {
  let CheckboxIcon: JSX.Element = <CheckboxSvg />;

  if (checked) CheckboxIcon = <CheckedSvg />;

  return (
    <div onClick={onClick ? () => onClick(checked) : undefined} className={className}>
      {CheckboxIcon}
    </div>
  );
};

export default CheckBoxCustom;
