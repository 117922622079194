import { TypographyProps } from './types';
import styles from './styles.module.scss';

const Typography = (props: TypographyProps) => {
  const {
    className,
    children,
    variant,
    component = 'p',
    style,
    dangerouslySetInnerHTML = false,
    onClick,
    wrap = true,
  } = props;

  const CustomTag: any = `${component}`;

  const classes = [];

  if (variant) {
    const variantToCamelCase = variant.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
    classes.push(styles[variantToCamelCase]);
  }

  if (className) classes.push(className);

  if (wrap) classes.push(styles.wordWrap);

  const commonProps = {
    className: classes.join(' '),
    style: { ...style },
  };

  if (dangerouslySetInnerHTML) return <CustomTag {...commonProps} dangerouslySetInnerHTML={dangerouslySetInnerHTML} />;

  if (component === 'label') {
    return (
      <CustomTag {...commonProps} htmlFor={props.for}>
        {children}
      </CustomTag>
    );
  }

  if (component === 'input' || component === 'select') {
    return (
      <CustomTag {...commonProps} {...props.inputLogic}>
        {children}
      </CustomTag>
    );
  }

  return (
    <CustomTag {...commonProps} onClick={() => onClick && onClick()}>
      {children}
    </CustomTag>
  );
};

export default Typography;
