import styled from 'styled-components';
import { Colors } from 'interfaces/config';

const Wrapper = styled.div`
  // layout - sidebar
  // a.activeSection {
  //   color: #ffffff;
  //   background-color: ${(props) => props.theme.primary};
  //   svg {
  //     fill: #ffffff;
  //   }
  // }
  // a.notActiveSection {
  //   color: ${(props) => props.theme.grey_5};
  //   &:hover {
  //     background-color: ${(props) => props.theme.primary};
  //     svg {
  //       fill: #ffffff;
  //     }
  //     color: #ffffff;
  //   }
  //   svg {
  //     fill: ${(props) => props.theme.secondary};
  //   }
  // }
  .sidebarSettingsIcon {
    svg {
      fill: ${(props) => props.theme.primary};
    }
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: ${(props) => props.theme.secondary};
  }
  .drawerTabSelected {
    background-color: #fff;
    color: ${(props) => props.theme.secondary};
    border-color: ${(props) => props.theme.secondary};
  }
  #uploadZoneButton {
    background-color: ${(props) => props.theme.primary};
    color: #ffffff;
  }

  // dashboard
  .channelBtn {
    span:first-child {
      border: solid ${(props) => props.theme.primary};
      svg {
        fill: ${(props) => props.theme.primary};
      }
    }
    &:hover {
      span:first-child {
        background-color: ${(props) => props.theme.primary};
      }
    }
  }

  // header
  .headerUserOption {
    &:hover {
      background-color: ${(props) => props.theme.primary};
    }
  }

  // filter menu table screen
  .filterButton {
    &:not(:last-child) {
      background-color: ${(props) => props.theme.primary};
    }
  }

  .tableScreenCreateButton {
    background-color: ${(props) => props.theme.primary};
    color: #ffffff;
    fill: #ffffff;
    &:hover {
      background-color: ${(props) => props.theme.primary};
    }
  }

  .tableScreenDownloadButton {
    background-color: ${(props) => props.theme.white};
    span,
    svg {
      color: ${(props) => props.theme.grey_5};
    }
  }

  .tableScreenFilterButton {
    padding: 18px;
    background-color: ${(props) => props.theme.white};
    span,
    svg {
      color: ${(props) => props.theme.grey_5};
    }
  }

  // tableScreen pagination
  .pagination {
    button {
      border-radius: 8px;
      border: unset;
      background-color: ${(props) => props.theme.white};
      color: ${(props) => props.theme.grey_5};
    }
  }
  .selectedPaginationItem {
    background-color: ${(props) => props.theme.primary} !important;
    color: #ffffff !important;
  }

  .activeDateOption {
    background-color: ${(props) => props.theme.secondary} !important;
    color: #ffffff !important;
  }

  // limitSelector
  .limitSelectorContainer {
    background-color: ${(props) => props.theme.white};
    border-color: ${(props) => props.theme.grey_1};
  }
  .limitSelector {
    color: ${(props) => props.theme.grey_5};
  }

  // tableScreen table
  .table {
    th {
      border-top: 1px solid ${(props) => props.theme.grey_1};
      border-bottom: 1px solid ${(props) => props.theme.grey_1};
      color: ${(props) => props.theme.grey_5};
      background-color: ${(props) => props.theme.grey_0};
      &:first-child {
        border-left: 1px solid ${(props) => props.theme.grey_1};
        border-top-left-radius: 20px;
      }
      &:last-child {
        border-right: 1px solid ${(props) => props.theme.grey_1};
        border-top-right-radius: 20px;
      }
    }
    td {
      border-bottom: 1px solid ${(props) => props.theme.grey_1};
      color: ${(props) => props.theme.grey_5};
      &:first-child {
        border-left: 1px solid ${(props) => props.theme.grey_1};
      }
      &:last-child {
        border-right: 1px solid ${(props) => props.theme.grey_1};
      }
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 20px;
    }
    tr:last-child td:first-child {
      border-bottom-left-radius: 20px;
    }
  }

  // table screen header
  .headerRow {
  }

  // filters toggler
  .advancedOptions {
    color: ${(props) => props.theme.secondary};
  }

  .filterToggleButtonActive {
    color: #ffffff;
    background-color: ${(props) => props.theme.primary};
  }
  // map button
  .toggleMapContainer {
    background-color: ${(props) => props.theme.grey_0};
  }
  // search
  .searchInputWrapper {
    background-color: #ffffff !important;
  }
  .filterSelectorContainer {
    border-color: ${(props) => props.theme.grey_1};
  }
  .filterSelector {
    color: ${(props) => props.theme.grey_5};
  }
  // filterCloseButton
  .filterCloseButton {
    background-color: #ffffff;
    &:hover {
      background-color: #ffffff;
    }
    svg {
      fill: ${(props) => props.theme.grey_5};
    }
  }
  // addFilterButton & applyFiltersButton
  .addAndApplyFilterButton {
    background-color: ${(props) => props.theme.primary};
    &:hover {
      background-color: ${(props) => props.theme.primary};
    }
    &.disabled {
      background-color: ${(props) => props.theme.grey_1};
      span {
        color: ${(props) => props.theme.primary};
      }
      svg {
        fill: ${(props) => props.theme.primary};
      }
    }
    svg {
      fill: #ffffff;
    }
    span {
      color: #ffffff;
    }
  }
  // clearFiltersButton
  .clearFiltersButton {
    background-color: ${(props) => props.theme.primary};
    svg {
      fill: ${(props) => props.theme.white};
    }
  }

  .saveAsQuickFilterFiltersButton {
    background-color: ${(props) => props.theme.primary};
    &:hover {
      background-color: ${(props) => props.theme.primary};
    }
    &.disabled {
      background-color: ${(props) => props.theme.grey_1};
      span {
        color: ${(props) => props.theme.primary};
      }
      svg {
        fill: ${(props) => props.theme.primary};
      }
    }
    svg {
      fill: #ffffff;
    }
    span {
      color: #ffffff;
    }
  }

  // filter value input/date
  .filterInputValue,
  .filterValueDate {
    background-color: #ffffff !important;
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      display: none !important;
    }
  }

  .reportsDatePicker {
    background-color: #ffffff !important;
  }

  // orderInfoCardInput
  .orderInfoCardInput {
    background-color: ${(props) => props.theme.grey_0};
  }

  // drawer
  .drawerTextInput {
    background-color: ${(props) => props.theme.grey_0};
  }
  .drawerSelectorContainer {
    background-color: ${(props) => props.theme.grey_0};
  }
  .drawerSelector {
    color: ${(props) => props.theme.grey_5};
  }
  .drawerDate {
    background-color: ${(props) => props.theme.grey_0};
  }
  .drawerTextarea {
    textarea {
      background-color: ${(props) => props.theme.grey_0};
      color: ${(props) => props.theme.grey_5};
    }
  }
  .drawerWysiwyg {
    background-color: ${(props) => props.theme.white};
  }

  // orders drawer
  .sectionTitle {
    color: ${(props) => props.theme.grey_5};
  }
  .sectionContent {
    background-color: ${(props) => props.theme.grey_0};
  }
  .customerInfoCardTitle {
    svg {
      fill: ${(props) => props.theme.secondary};
    }
    span {
      color: ${(props) => props.theme.grey_5};
    }
  }
  .labelBlockLabel {
    span {
      color: ${(props) => props.theme.grey_3};
    }
  }
  .labelBlockValue {
    span {
      color: ${(props) => props.theme.grey_5};
    }
  }
  .priceContainer {
    span {
      color: ${(props) => props.theme.grey_5};
    }
  }
  .ordersDrawerProductButton {
    border: 1px solid ${(props) => props.theme.primary};
    &:hover {
      background-color: ${(props) => props.theme.grey_0};
    }
    svg {
      fill: ${(props) => props.theme.primary};
    }
  }
  .ordersProductName {
    span {
      color: ${(props) => props.theme.grey_5};
    }
  }
  .ordersProductHotel {
    span {
      color: ${(props) => props.theme.grey_2};
    }
  }
  .ordersBottomDrawerButton {
    border: 1px solid ${(props) => props.theme.primary};
    &:hover {
      background-color: ${(props) => props.theme.grey_0};
    }
    svg {
      fill: ${(props) => props.theme.primary};
    }
  }
  .ordersBottomDrawerButtonDisabled {
    border: 1px solid ${(props) => props.theme.grey_3};
    svg {
      fill: ${(props) => props.theme.grey_3};
    }
  }
  .ordersBottomDrawerButtonCancel {
    border: 1px solid red;
    svg {
      fill: red;
    }
    span {
      color: red;
    }
  }

  // quickFilter drawer
  .quickFilterDrawerSave {
    background-color: ${(props) => props.theme.secondary};
    &:hover {
      background-color: ${(props) => props.theme.secondary};
    }
    color: #ffffff;
  }
  .quickFilterDrawerCancel {
    background-color: ${(props) => props.theme.grey_1};
    &:hover {
      background-color: ${(props) => props.theme.grey_1};
    }
    color: ${(props) => props.theme.grey_5};
  }

  // map zoom buttons styles
  .mapControls {
    background-color: ${(props) => props.theme.primary};
    button {
      background-color: white;
      &:hover {
        background-color: white;
      }
    }
  }

  // tableScreen table items
  .referenceLink {
    color: ${(props) => props.theme.secondary};
  }

  // @to-do aramon styles demo

  .spqr_button.primary.default {
    background: #cb3251;
    & svg > * {
      stroke: #fff;
    }
  }
  .spqr_button.primary.default:hover {
    background: #bd2544;
  }
  .spqr_button.text.default {
    color: #bd2544;
  }
  .spqr_button.primary.default:disabled:hover {
    color: #ffffff;
  }

  .spqr_button.primary:not(.spqr_button.primary:disabled):hover {
    background: #bd2544;
  }
  .spqr_button.secondary.line:not(.spqr_button.secondary.line:disabled):hover {
    color: #bd2544;
    border-color: #cb3251;
  }
  .spqr_button.secondary:not(.spqr_button.secondary:disabled):hover {
    border: 2px solid #cb3251;
    color: #cb3251;
  }
  .spqr_Icon {
    & svg > * {
      stroke: #cb3251;
    }
  }
  .aramon > .spqr_Icon {
    & svg > * {
      stroke: ${(props) => props.theme.secondary};
    }
  }
  .paginationItem.selected {
    color: #cb3251;
  }
`;

interface Props {
  colors: Colors;
  children: any;
}

function ThemeWrapper(props: Props) {
  return <Wrapper theme={props.colors}>{props.children}</Wrapper>;
}

export default ThemeWrapper;
