import { useUser } from 'hooks';

const useUserAvatar = () => {
  const { user } = useUser();
  const { firstName = '?', lastName = '?' } = user;
  const initials = `${firstName[0].toUpperCase()}${lastName && lastName[0].toUpperCase()}`;
  return {
    initials,
  };
};

export default useUserAvatar;
