import { DateTime } from 'luxon';
import { Props } from './types';

const isPastTime = ({ date, duration = 'minutes', attributeToObtain = 'minutes' }: Props) => {
  const date1 = date && DateTime.fromISO(date);
  const date2 = DateTime.now().toUTC();
  const diff = date1 && date2 && date2?.diff(date1, duration).toObject()[attributeToObtain];
  if (typeof diff === 'number') {
    return Number(diff);
  } else {
    return undefined;
  }
};
export default isPastTime;
