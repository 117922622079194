import { requester } from 'logic';
import { CONNECTV3, getNestedValue } from 'utils';
import { DrawerModeContent, TableQueries } from 'interfaces/config';
import { State } from '../types';

const onCreateNewItem = async (
  createMode: DrawerModeContent,
  queries: TableQueries,
  state: State,
  destinationId: number
): Promise<any> => {
  const currentItem = state.toCreateItem.item;
  if (!queries || (!queries.createEndPoint && !queries.create)) return Promise.reject();
  const createEndpoint = queries.createEndPoint || queries.create;
  const fields = createMode.fields;
  if (!fields || !Array.isArray(fields)) return Promise.reject();

  const data: any = {};
  fields.forEach((field) => {
    if (field.path && field.path !== '' && field.path !== 'image.url' && field.path !== 'largeImage.url') {
      data[field.path] = getNestedValue(currentItem, field.path);
    } else {
      data[field.id] = currentItem[field.id];
    }
  });
  data.destination = destinationId + 1; // beacuse on strapi the first destination is 1

  try {
    const res = await requester.requestWithBearer('post', `${CONNECTV3}${createEndpoint}`, data);
    const currentItems = [...state.list.items];
    currentItems.push(res);
    return currentItems;
  } catch (error) {
    return error;
  }
};

export default onCreateNewItem;
