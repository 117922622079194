const countDecimals = (value: number) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

const parseToTwoDecimals = (value: number) => {
  if (countDecimals(value) > 2) {
    return value.toFixed(2).toString();
  }
  return value.toString();
};

export const formatBigNumbers = (value: number) => {
  let number: string;
  let convertedValue: string;
  if (value >= 1000000) {
    convertedValue = parseToTwoDecimals(value / 1000000);
    number = `${convertedValue}M`;
  } else if (value >= 1000) {
    convertedValue = parseToTwoDecimals(value / 1000);
    number = `${convertedValue}K`;
  } else {
    number = parseToTwoDecimals(value);
  }
  return `${number} €`;
};
