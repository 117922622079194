import { useState } from 'react';

// Parameter is the initial value
const useSet = (initialState = {}): [any, any] => {
  // Initialize the state
  const [state, setState] = useState<any>(initialState);

  return [state, setState];
};

export default useSet;
