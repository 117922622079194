import { Props } from './types';
import style from './style.module.scss';

const FieldError = ({ error }: Props) => (
  <div className={style.formError}>
    <span>{error}</span>
  </div>
);

export default FieldError;
