//@ts-nocheck
// import { LocaleAsString } from "../../../localeEngine";
import { Icon } from 'components';
import Typography from './newComponents/typography';
import useInput from './useInput';
import { TL } from 'utils';
import { InputProps } from './types';
import styles from './styles.module.scss';

export default function TextInput({
  className,
  label,
  required,
  name,
  value,
  type = 'text',
  onChange,
  disablePaste,
  validation,
  readonly = false,
}: InputProps) {
  const {
    focus,
    setFocus,
    error,
    setError,
    TriggerInputEvent,
    ValidateInput,
    HandleOnBlur,
    isFocused,
    showPassword,
    setShowPassword,
  } = useInput({ className, label, required, name, value, type, disablePaste, validation, readonly });

  const InputLogicConfig = { tabIndex: readonly ? '-1' : '' };

  return (
    <div className={`${styles.inputWrapper} ${className}`}>
      <div className={`${styles.input} ${error && styles.error}`}>
        <Typography
          component="label"
          variant={'h300-medium'}
          for={name}
          className={`${styles.label} ${isFocused && styles.focused} H200Medium`}
        >
          {label}
          {required && (
            <>
              {' '}
              <span title="required" aria-label="required">
                *
              </span>
            </>
          )}
        </Typography>
        {type === 'password' ? (
          <div
            className={`${styles.utilityIcon} ${isFocused && styles.focused}`}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword === true ? <Icon icon="Eye" size="M" /> : <Icon icon="EyeOff" size="M" />}
          </div>
        ) : null}
        <Typography
          component="input"
          variant={'h400-medium'}
          className={`${styles.textfield} H400Medium`}
          inputLogic={{
            readOnly: readonly ? 'readOnly' : false,
            name: name,
            type: showPassword === true ? 'text' : type,
            value: value,
            onChange: onChange,
            onPaste: (e: any) => (disablePaste ? e.preventDefault() : TriggerInputEvent()),
            onFocus: (e: any) => {
              !focus && setFocus(true);
              TriggerInputEvent();
            },
            onBlur: () => {
              HandleOnBlur(className, label, required, name, value, type, onChange, disablePaste, validation, readonly);
              TriggerInputEvent();
            },
            ...InputLogicConfig,
          }}
        />
      </div>
      <Typography
        component="p"
        variant="h200-medium"
        className={`${styles.errorMessage} ${error && styles.visible} H300Medium`}
      >
        {/* <LocaleAsString id={error} /> */}
        {TL(error)}
      </Typography>
    </div>
  );
}
