import DrawerComponent from './components/drawer';
import { Props } from './types';
import style from './style.module.scss';

const Drawer = ({ children, open, onClose, layout }: Props) => {
  if (layout === 'no-container') {
    return (
      <DrawerComponent open={open} onClose={onClose}>
        {children}
      </DrawerComponent>
    );
  }

  if (layout === 'orders') {
    return (
      <DrawerComponent open={open} onClose={onClose} openClassName={style.ordersOpen}>
        <div className={style.wrapper}>{children}</div>
      </DrawerComponent>
    );
  }

  if (layout === 'entries') {
    return (
      <DrawerComponent open={open} onClose={onClose} openClassName={style.ordersOpen}>
        <div className={style.wrapper}>{children}</div>
      </DrawerComponent>
    );
  }

  return (
    <DrawerComponent open={open} onClose={onClose}>
      <div className={style.wrapper}>{children}</div>
    </DrawerComponent>
  );
};

export default Drawer;
