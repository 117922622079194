import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import { Icon } from 'components';
import { useConfig } from 'hooks';
import { Props } from './types';
import styles from './style.module.scss';

export default function SearchInput({
  id,
  name,
  type,
  placeholder,
  onChange,
  value,
  icon,
  endIcon,
  label,
  outsideLabel,
  wrapperClass,
  endIconClick,
  disabled,
}: Props) {
  const { colors } = useConfig();
  return (
    <div className={styles.textInputContainer}>
      {outsideLabel && (
        <div className={styles.inputLabelContainer}>
          <span>{outsideLabel}</span>
        </div>
      )}
      <div className={`${styles.formGroup} ${wrapperClass}`}>
        <TextField
          fullWidth
          id={id}
          className={`${styles.input} H400Medium`}
          name={name}
          label={label}
          type={type || 'text'}
          variant="outlined"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled}
          InputProps={{
            startAdornment: icon ? (
              <InputAdornment position="start">
                <Icon icon={icon as any} color={colors.secondary} size="SC" />
              </InputAdornment>
            ) : undefined,
            endAdornment: endIcon ? (
              <InputAdornment position="end" onClick={endIconClick} className={styles.clicable}>
                <Icon icon={endIcon as any} color={colors.secondary} size="SC" />
              </InputAdornment>
            ) : undefined,
          }}
        />
      </div>
    </div>
  );
}
