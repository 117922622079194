//@ts-nocheck
import styles from './styles.module.scss';

const StatusIndicator = ({ color }: any) => {
  return (
    <div className={styles.container}>
      <div className={styles.pulse} style={{ '--color': color }}></div>
    </div>
  );
};
export default StatusIndicator;
