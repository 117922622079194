import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Props } from './types';

const Alert = (props: any) => <MuiAlert elevation={6} variant="filled" {...props} />;

const SnackBar = ({ open, onClose, duration, type, message }: Props) => {
  if (!message) return null;

  return (
    <Snackbar open={open} autoHideDuration={duration || 3000} onClose={onClose}>
      <Alert onClose={onClose} severity={type || 'success'}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
