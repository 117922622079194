import { ReactNode } from 'react';
import styles from './styles.module.scss';

const Tooltip = ({ children, text }: { children?: ReactNode; text?: string }) => {
  if (!text) return null;
  return (
    <div className={styles.tooltip}>
      {children}
      <span className={styles.tooltiptext}>{text}</span>
    </div>
  );
};
export default Tooltip;
