import ReactDOM from 'react-dom';
import { Button } from 'components';
import { DrawerProps } from './types';
import styles from './styles.module.scss';

const Layout: any = {
  small: styles.small,
  medium: styles.medium,
};

const DrawerComponent = function ({ config }: { config: any }) {
  const { closeFn, children, title, tabs, header, footer, layout } = config;

  const layoutStyle = Layout[layout] || styles.small;

  return (
    <div className={styles.drawer} aria-hidden={'false'}>
      <div className={`${styles.box} ${layoutStyle}`}>
        <Header title={title} tabs={tabs} header={header} />
        <div>{children}</div>
        <Footer closeFn={closeFn}>{footer}</Footer>
      </div>
      <div className={styles.overlay} onClick={closeFn} />
    </div>
  );
};

const Header = function ({ title, tabs, header }: any) {
  return (
    <div className={`${styles.header} ${tabs ? styles.tabs : ''}`}>
      <div className={styles.main}>
        <span className="h600">{title}</span>
        {tabs && <div>{tabs}</div>}
      </div>
      <div className={styles.secondary}>{header}</div>
    </div>
  );
};

const Footer = function ({ children, closeFn }: any) {
  return (
    <div className={styles.footer}>
      <Button onClick={closeFn} text="Close" icon="X" iconAlignment="right" />
      <div className={styles.footerContent}>{children}</div>
    </div>
  );
};

const body = document.getElementsByTagName('body')[0];

const DrawerRefactor = function (props: DrawerProps) {
  const { open } = props;

  if (open) return ReactDOM.createPortal(<DrawerComponent config={props} />, body);
  return null;
};

export default DrawerRefactor;
