import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvatarName } from 'components';
import { Props } from './types';
import styles from './styles.module.scss';
import { useUser } from 'hooks';

const Header = ({ leftContent }: Props) => {
  const [collapsed, setCollapsed] = useState(true);
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  const logout = () => {
    setUser({});
    localStorage.clear();
    navigate('/');
  };

  return (
    <div className={styles.header}>
      <div>{leftContent}</div>
      {user ? (
        <div className={styles.loggedUser} onClick={() => setCollapsed(!collapsed)}>
          <AvatarName />
          <div className={styles.userData}>
            <span className="H400Medium">
              {user.firstName} {user.lastName}
            </span>
            <span className={`${styles.greySpan} H300Medium`}>{user.email}</span>
          </div>
          <div className={`${styles.userMenu} ${collapsed ? styles.collapsed : ''}`}>
            <div className={`headerUserOption ${styles.option} H300Medium`} onClick={logout}>
              Logout
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
