import { Fragment, useMemo } from 'react';
import { formatDates, Switch, Selector, Icon, CheckBoxCustom, Currency, Chip, Tooltip } from 'components';
import { getNestedValue } from 'utils';
import { useConfig } from 'hooks';
import style from './style.module.scss';

export default function useTableWrapper({
  checkboxAllIsChecked,
  columns,
  data,
  onItemRowClick,
  rowIsChecked,
  onRowClick,
  search,
  filters,
}: any) {
  const excludedSortByFields: any = [];
  const filteredColumns = columns.filter((col: any) => col.hideOnTable !== true);
  const { colors } = useConfig();

  const getTableAction = (type: any, element: any) => {
    switch (type) {
      case 'eraseButton':
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (onItemRowClick) {
                onItemRowClick('eraseButton', null, element);
              }
            }}
          >
            <Icon icon="Trash" color={colors.red} size={'M'} />
          </div>
        );

      case 'editButton':
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (onRowClick) {
                onRowClick(element.id);
              }
            }}
          >
            {/* @to-do aramon styles demo */}
            <div className="aramon">
              <Icon icon="Edit" size="M" color={colors.secondary} />
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const getProductValue = (obj: any, path: any, placeholder: any): any => {
    if (!path) return obj;
    if (!path.includes('.')) {
      let value = '';
      if (obj.length > 0) {
        const lodge = obj[0];
        value = lodge[path];
      }
      return !value ? placeholder : value;
    }
    const properties = path.split('.');
    if (properties.length === 0 || !obj[properties[0]]) return undefined;
    return getProductValue(obj[properties.shift()], properties.join('.'), placeholder);
  };

  const getTotal = (obj: any, path: any, placeholder: any): any => {
    if (!path) return obj;
    if (!path.includes('.')) {
      let total: any = 0;
      if (obj.length > 0) {
        obj.forEach((item: any, idx: any) => {
          total += item[path];
        });
      }
      return total === 0 ? (
        placeholder
      ) : (
        <Currency amount={total} bold thousandSeparator={' '} unit={''} withDecimals={false} align="right" />
      );
    }
    const properties = path.split('.');
    if (properties.length === 0 || !obj[properties[0]]) return undefined;
    return getTotal(obj[properties.shift()], properties.join('.'), placeholder);
  };

  const checkFilter = (value: any, column: any) => {
    const id = column.id;
    let filterExists = false;
    if (filters) {
      filters.forEach((element: any) => {
        if (element.toLowerCase() === id.toLowerCase()) {
          filterExists = true;
        }
        if (element.includes('.') && element.toLowerCase() === column.path) {
          filterExists = true;
        }
      });

      if (filterExists && value) return setHighlighted(value.toString());
      return value;
    } else {
      return value;
    }
  };

  const setHighlighted = (value: any) => {
    if (value) {
      const newSearch = search.toLowerCase();
      const valueToLowerCase = value.toLowerCase();
      const indexSearchStart = valueToLowerCase.indexOf(newSearch);
      const indexSearchFinal = valueToLowerCase.indexOf(newSearch) + newSearch.length;

      let firstIndex = '';
      if (indexSearchStart !== 0) {
        firstIndex = value.substring(0, indexSearchStart);
      }
      const highlightedValue = value.substring(indexSearchStart, indexSearchFinal);
      const secondString = value.substring(indexSearchFinal, valueToLowerCase.length);

      if (highlightedValue.toLowerCase() === newSearch.toLowerCase()) {
        return (
          <div id={value}>
            {firstIndex}
            <span style={{ backgroundColor: 'yellow' }}>{highlightedValue}</span>
            {secondString}
          </div>
        );
      } else {
        return <div>{value}</div>;
      }
    } else {
      return;
    }
  };

  const renderCellByType = (field: any, element: any) => {
    let ret: any;
    if (field.type === 'simple') {
      ret = checkFilter(element[field.id] || field.passholder, field);
      if (field.sufix && typeof field.sufix === 'string') ret = ret + field.sufix;
    }

    if (field.type === 'boolean') {
      ret = (
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (onItemRowClick) {
              onItemRowClick(field.id, !element[field.id], element);
            }
          }}
        >
          <Switch checked={element[field.id]} value={element[field.id]} />
        </div>
      );
    }

    if (field.type === 'enum') {
      const options = field.filterValues;
      ret = (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Selector
            id={field.id}
            name={field.id}
            options={options}
            value={element[field.id] || ''}
            onChange={(ev) => {
              onItemRowClick(field.id, ev.target.value, element);
            }}
            containerClass={`${style.tableInputSelector} limitSelectorContainer`}
          />
        </div>
      );
    }

    if (field.type === 'multiple') {
      field.value.forEach((id: any, index: any) => {
        const separator = index === 0 ? '' : field.separator;
        ret = ret + separator + element[id];
      });
    }

    if (field.type === 'date') {
      if (!element[field.id]) ret = field.placeholder;
      else {
        ret = formatDates({ date: element[field.id], timeago: true });
      }
    }

    if (field.type === 'referenceLink' || (field.type === 'nested' && field.referenceLink)) {
      if (!field.referenceLink) return null;
      let value = element[field.id] || field.placeholder;
      if (field.path) {
        value = getNestedValue(element, field.path) || field.placeholder;
      }
      const valueHighlighted = checkFilter(value, field);
      return (
        <div
          className={style.referenceLinkContainer}
          onClick={(e) => {
            e.stopPropagation();
            onItemRowClick('referenceLink', field.referenceLink, element);
          }}
        >
          <span className={`referenceLink`}>{valueHighlighted}</span>
        </div>
      );
    }

    // añadir tipo images array para pillar solo la primera imagen
    if (field.type === 'nested') {
      const nested = getNestedValue(element, field.path) || field.placeholder;
      const valueHighlighted = checkFilter(nested, field);
      return valueHighlighted;
    }

    if (field.type === 'image') {
      const path = field.path || field.id;
      const image = getNestedValue(element, path);
      const altValue = getNestedValue(element, field.altPath) || field.label;
      if (image) {
        return (
          <div className={style.imageContainer}>
            <img src={image} alt={altValue} className={style.image} />
          </div>
        );
      }
    }

    if (field.type === 'total') {
      return getTotal(element, field.path, field.placeholder);
    }

    if (field.type === 'productvalue') {
      return getProductValue(element, field.path, field.placeholder);
    }

    if (field.type === 'tableActions') {
      if (!field.actions || !Array.isArray(field.actions)) return null;

      return (
        <div className={style.tableActionsContainer}>
          {field.actions.map((action: any, key: any) => (
            <Fragment key={key}>{getTableAction(action, element)}</Fragment>
          ))}
        </div>
      );
    }

    if (field.type === 'bulkAction') {
      let checked = false;
      if (rowIsChecked) {
        checked = rowIsChecked(element.id);
      }
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (onItemRowClick) {
              onItemRowClick(field.id, !element[field.id], element);
            }
          }}
        >
          <CheckBoxCustom checked={checked} /*onClick={null}*/ className={style.checkboxCustomContainer} />
        </div>
      );
    }

    if (field.type === 'chip') {
      ret = <Chip value={element[field.id]}>{element[field.id] || field.placeholder}</Chip>;
    }

    if (field.type === 'publishChip') {
      let publishStatus = 'draft';
      if (element['published_at']) {
        publishStatus = 'published';
      }
      ret = <Chip value={publishStatus}>{publishStatus || field.placeholder}</Chip>;
    }

    if (field.type === 'notes') {
      {
        element.notes
          ? (ret = (
              <Tooltip text={element.notes}>
                <Icon icon="AlertTriangle" color={colors.secondary} size="M" />
              </Tooltip>
            ))
          : (ret = <p>{field.placeholder}</p>);
      }
    }

    return ret;
  };

  const renderHeaderCheckBox = (onItemRowClick: any, checkboxAllIsChecked: any) => {
    let checked = false;
    if (checkboxAllIsChecked) {
      checked = checkboxAllIsChecked();
    }

    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (onItemRowClick) {
            onItemRowClick('bulkActionAll');
          }
        }}
      >
        <CheckBoxCustom checked={checked} /*onClick={null}*/ className={style.checkboxCustomContainer} />
      </div>
    );
  };

  const getColumns = () => {
    return filteredColumns.map((headerColumn: any, key: any) => {
      if (headerColumn.sort === false) excludedSortByFields.push(headerColumn.id);

      const column = {
        Header: headerColumn.label,
        accessor: headerColumn.id,
        align: headerColumn.alignColumn,
      };
      if (headerColumn.id === 'bulkAction') {
        column.Header = (col: any) => {
          return <Fragment key={key}>{renderHeaderCheckBox(onItemRowClick, checkboxAllIsChecked)}</Fragment>;
        };
      }
      return column;
    });
  };

  const fetchData = () => {
    if (data)
      return data.map((dest: any) => {
        const cellData: any = {};
        filteredColumns.forEach((objProp: any) => {
          cellData[objProp.id] = renderCellByType(objProp, dest);
        });
        return cellData;
      });
  };

  const cols = useMemo(getColumns, [getColumns]);
  const rowsData = useMemo(fetchData, [fetchData]);

  return {
    cols,
    rowsData,
    excludedSortByFields,
  };
}
