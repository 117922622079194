import ReactDOM from 'react-dom';
import { Icon } from 'components';
import styles from './styles.module.scss';

const Modal = ({ config }: { config: any }) => {
  const { closeFn, children } = config;

  return (
    <div className={`${styles.drawer} ${closeFn === undefined && styles.buttonHidden}`} aria-hidden={'false'}>
      <div
        className={`
          ${styles.modalBox}    
        `}
      >
        <div className={styles.closeBtn} onClick={closeFn} role="button" tabIndex={1}>
          <Icon icon="X" size="M" />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
      <div className={styles.modalOverlay} onClick={closeFn} />
    </div>
  );
};

interface ModalProps {
  closeFn?: any;
  children: any;
}

const body = document.getElementsByTagName('body')[0];

export default function ModalComponent(props: ModalProps) {
  return ReactDOM.createPortal(<Modal config={props} />, body);
}
