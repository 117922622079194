import { GraphQLFilterConfig, TableRouteField } from 'interfaces';
import { QueryStringParams, State, GetInitialState } from '../types';
import { getLocalStorage, queryString } from 'utils';
import { apiSelector } from 'services';
import { cloneDeep } from 'lodash';
import getInitialOnSortBy from 'containers/tableScreen/logic/getInitialOnSortBy';

const getInitialState = ({ structure, quickFilters, sort, label }: GetInitialState) => {
  const { filter, value }: QueryStringParams = queryString();

  let sortCondition = undefined;
  let filters = undefined;
  let filterTableFields: Array<TableRouteField> = [];
  let defaultFilters: GraphQLFilterConfig[] | null = null;
  let queryStringMode = false;
  let selectedFilterBtn: 0 | 1 = 0;
  let textFields: any = undefined;
  const arrayFilters: any[] = [];

  if (structure) {
    structure.forEach((field) => {
      if (field.filter && typeof field.filter === 'string') {
        const Field = { ...field };
        if (field.type === 'nested' && field.path) Field.id = field.path;
        if (filterTableFields === null) filterTableFields = [Field];
        else filterTableFields.push(Field);
      }
    });
    textFields =
      filterTableFields && filterTableFields.filter((f: any) => f.filter === 'string' && f.hideOnSearch !== true);

    textFields?.map((element: any) => {
      arrayFilters.push(element.id);
    });

    if (filter && value) {
      filters = [{ field: filter, value: value, condition: '=' }];
      const field = structure.find((field) => field.id === filter);
      if (field && field.type === 'nested' && field.path) {
        filters[0].field = field.path;
        filters[0].condition = '_contains';
      }
      defaultFilters = filters;
      // bloquear los filtros hasta que clican al botón del mensaje
      queryStringMode = true;
      // enseñar los advanced filters por defecto
      selectedFilterBtn = 1;
    }

    // habilitar los quickFilters que estan a enabled por defecto
    if (!queryStringMode) {
      if (quickFilters && quickFilters.length) {
        const selectedButtons: Array<GraphQLFilterConfig> = [];
        for (let i = 0; i < quickFilters.length; i++) {
          const filterGroup = cloneDeep(quickFilters[i]);
          const enabledButtons = filterGroup.buttons.filter((btn) => btn.enabled);
          if (!enabledButtons.length) continue;
          enabledButtons.forEach((btn) => selectedButtons.push(...btn.filters));
        }
        if (selectedButtons.length) {
          if (Array.isArray(filters)) filters = filters.concat(selectedButtons);
          else filters = selectedButtons;
        }
      } else if (defaultFilters && defaultFilters.length) {
        // habilitar el config defaultFilters (advanced filters activados por defecto)
        if (Array.isArray(filters)) filters = filters.concat(defaultFilters);
        else filters = defaultFilters;
        defaultFilters = filters;
      }
    }
    if (Array.isArray(filters)) filters = apiSelector.filtersGraphQLParser(filters);
    if (sort && sort.field && sort.order) {
      sortCondition = getInitialOnSortBy(sort);
    }
  }

  //@to-do control the double set filters in linking orders/purchasers

  const customFilters = getLocalStorage(`quickFiltersButtons-${label}`) || [];

  const state: State = {
    stateLoaded: true,
    loading: true,
    list: {
      total: 0,
      filteredTotal: 0,
      paginator: {
        start: 0,
        limit: 100,
        sort: sortCondition,
      },
      page: 1,
      items: [],
      loading: true,
      filters: filters,
      searchQuery: null,
    },
    selectedItem: {
      open: false,
      item: false,
    },
    toCreateItem: {
      open: false,
      item: false,
    },
    showMap: 0,
    selectedDrawerMode: 'view',
    filterTableFields,
    isGalleryOpen: false,
    imageAccessor: undefined,
    toDeleteIds: null,
    selectedCheckboxs: null,
    formErrors: undefined,
    showSnackbar: false,
    snackbarMessage: '',
    snackbarType: undefined,
    showPromptDelete: false,
    showPromptResendEmail: false,
    selectedFilterBtn,
    customFilters: customFilters,
    quickFiltersDefault: cloneDeep(quickFilters),
    quickFiltersButtons: cloneDeep(quickFilters) || [],
    quickFilterToEdit: null,
    defaultFilters,
    selectedDrawerTab: 0,
    showPromptMissingInfoEmail: false,
    showPromptQRCode: false,
    queryStringMode: false,
    refresh: false,
    showModal: false,
    showFilters: false,
    advancedFilters: [],
    createModal: false,
    addProductLoading: false,
    addProductState: undefined,
    selectedCreateTab: -1,
    readOnlyDrawerOpen: false,
    routeToQuery: undefined,
    filterToQuery: undefined,
    readOnlyDrawerData: undefined,
    loadingReadOnlyDrawer: false,
    textFields: textFields,
    arrayFilters: arrayFilters,
  };
  return state;
};

export default getInitialState;
