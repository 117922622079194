import { formatDates } from 'components';
import { PurchaserInfo } from 'interfaces/orders';
import { requester } from 'logic';
import {
  OrderCardInfo,
  Product,
  ProductType,
  Customer,
  CustomerEdit,
  PaymentLog,
  SummaryOrder,
} from '../interfaces/orders_bjorli';
import { TL, ORDER_CUSTOMER_EDIT } from 'utils';

const productIcons = {
  lodging: 'bed',
  'lift-tickets': 'lift',
  rental: 'rentals',
  payment: 'credit-card',
};

class Orders {
  getGuestDetails(data: Customer): any {
    if (!data || typeof data !== 'object') return;
    return [
      {
        label: `${TL('backend_fullname')}`,
        icon: 'User',
        value: `${data.firstName} ${data.lastName}`,
      },
      {
        label: `${TL('backend_email')}`,
        icon: 'Email',
        value: data.email,
      },
      {
        label: `${TL('backend_phone')}`,
        icon: 'MapPin',
        value: `${data.prefix} ${data.phoneNumber}`,
      },
      {
        label: `${TL('backend_line')}1`,
        icon: 'Mailbox',
        value: `${data.zipCode} ${data.addressLine1}`,
      },
      {
        label: `${TL('backend_city')}`,
        icon: 'Town',
        value: data.city,
      },
      {
        label: `${TL('backend_country')}`,
        icon: 'Flag',
        value: data.country,
      },
    ];
  }

  getProductInfo(data: Product): Array<OrderCardInfo> {
    return [
      {
        label: `${TL('backend_sesong')}`,
        value: data.season,
      },
      {
        label: `${TL('backend_brukere')}`,
        value: data.guests,
      },
      {
        label: `${TL('backend_quantity')}`,
        value: data.quantity,
      },
      {
        label: `${TL('backend_total_price')}`,
        value: data.price,
        type: 'currency',
        currency: data.currency,
      },
    ];
  }

  getPaymentLog(data: PaymentLog): Array<OrderCardInfo> {
    if (!data || typeof data !== 'object') return [];
    return [
      {
        label: `${TL('backend_payment_id')}`,
        value: data.paymentId,
      },
      {
        label: `${TL('backend_completed_at')}`,
        value: formatDates({ date: data.date, format: 'yyyy-MM-dd hh:mm:ss' }),
      },
      {
        label: `${TL('backend_payment_method')}`,
        value: data.paymentMethod,
      },
      {
        label: `${TL('backend_payment_type')}`,
        value: data.paymentType,
      },
      {
        label: `${TL('backend_total_amount')}`,
        value: data.totalPayment,
        type: 'currency',
        currency: data.currency,
      },
    ];
  }

  getSummaryInfo(data: SummaryOrder): Array<OrderCardInfo> {
    if (!data || typeof data !== 'object') return [];
    return [
      {
        label: `${TL('backend_created_at')}`,
        value: formatDates({ date: data.created_at }),
      },
      {
        label: `${TL('backend_completed_at')}`,
        value: data.completed_at ? formatDates({ date: data.completed_at }) : '',
      },
      {
        label: `${TL('backend_status')}`,
        value: data.order_status,
        type: 'status',
      },
      {
        label: `${TL('backend_fulfillment')}`,
        value: data.order_fulfillmentStatus,
        type: 'status',
      },
    ];
  }

  getProductIcon(type: ProductType): string | undefined {
    return productIcons[type];
  }

  async setGuestDetails(id: number, customer: PurchaserInfo) {
    try {
      await requester.put(`${ORDER_CUSTOMER_EDIT}/${id}`, { customer });
    } catch (error) {}
  }

  getGuestEditDetails(data: Customer): CustomerEdit | undefined {
    if (!data || typeof data !== 'object') return;
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      prefix: data.prefix,
      phoneNumber: data.phoneNumber,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      zipCode: data.zipCode,
      country: data.country,
    };
  }
}

export default new Orders();
