import { useRef, useState, useEffect } from 'react';
import { Props } from './types';

import styles from './styles.module.scss';

const Accordion = ({ open, children, className, responsive = false, alignment = 'vertical' }: Props) => {
  const content: any = useRef(null);
  const isVertical = alignment === 'vertical';
  const isHorizontal = alignment === 'horizontal';
  const [accordionMaxHeight, setAccordionMaxHeight] = useState(open || isHorizontal ? 'undefined' : '0px');
  const [accordionMaxWidth, setAccordionMaxWidth] = useState(open || isVertical ? 'undefined' : '0px');

  useEffect(() => {
    if (!responsive) {
      if (isVertical) setAccordionMaxHeight(open ? `${content.current && content.current.scrollHeight}px` : '0px');
      if (isHorizontal) setAccordionMaxWidth(open ? `${content.current && content.current.scrollWidth}px` : '0px');
    } else {
      if (isVertical) setAccordionMaxHeight(open ? `` : '0px');
      if (isHorizontal) setAccordionMaxWidth(open ? `` : '0px');
    }
  }, [open, setAccordionMaxHeight, setAccordionMaxWidth, responsive, isVertical, isHorizontal]);

  return (
    <div
      ref={content}
      style={{ maxHeight: accordionMaxHeight, maxWidth: accordionMaxWidth }}
      className={`${styles.accordion} ${className || ''} ${responsive && styles.responsive} ${
        responsive && !open && styles.collapsed
      }`}
    >
      {children}
    </div>
  );
};

export default Accordion;
