import { Button } from 'components';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import style from './style.module.scss';

export default function TabsBar({ drawerTabs, selectedDrawerTab, handleDrawerTabChange }: any) {
  return (
    <div className={style.editTabsContainer}>
      {drawerTabs &&
        drawerTabs?.length > 0 &&
        drawerTabs.map((tab: any, index: number) => (
          //@to-do apply the new spotlio tabsbar
          <Button
            type={selectedDrawerTab === index ? 'primary' : 'secondary'}
            onClick={() => handleDrawerTabChange(index)}
            text={capitalizeFirstLetter(tab.label)}
            key={`${tab.id}_${index}`}
          />
        ))}
    </div>
  );
}
