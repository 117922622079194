import { useNavigate } from 'react-router-dom';
import { Icon } from 'components';
import { configMenu } from 'services';
import { useConfig } from 'hooks';
import { setLocalStorage } from 'utils';
import styles from './styles.module.scss';

export default function Channel({ channel, destinationId }: any) {
  const navigate = useNavigate();
  const { setChannelId, setDestinationId } = useConfig();

  const selectChannel = (destinationId: number, channelId: number) => {
    setLocalStorage('destinationId', destinationId);
    setLocalStorage('channelId', channelId);
    setChannelId(channelId);
    setDestinationId(destinationId);
    const defaultRoute = configMenu.getDefaultRoute();
    navigate(defaultRoute);
  };

  return (
    <div className={styles.container} onClick={() => selectChannel(destinationId, channel.id)}>
      <Icon icon={channel?.icon} size="L" />
      <li className="H300Medium">{channel?.name}</li>
    </div>
  );
}
