import { useNavigate } from 'react-router-dom';
import { Button } from 'components';
import { TL } from 'utils';

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1>{TL('backend_error_page_title') || '404 page not found.'}</h1>
      <div>
        <Button onClick={() => navigate(-1)} text={TL('backend_go_back')} />
      </div>
    </div>
  );
};

export default ErrorPage;
