import { getNestedValue, setNestedValue } from 'utils';
import { State } from '../types';

const onHandleArrayItemRemove = (arrayProp: string, arrayIndex: number, state: State) => {
  if (!state.selectedItem || !state.selectedItem.item || !state.selectedItem.open) return;

  const currentSelectedItem = { ...state.selectedItem.item };
  if (typeof arrayProp === 'undefined' || typeof arrayIndex === 'undefined') return;

  const arr = getNestedValue(currentSelectedItem, arrayProp);
  if (!arr || !Array.isArray(arr)) return;
  arr.splice(arrayIndex, 1);
  setNestedValue(currentSelectedItem, arrayProp, arr);
  return currentSelectedItem;
};

export default onHandleArrayItemRemove;
