import { Channel, DestinationIcon } from 'components';
import { Props } from './types';
import styles from './styles.module.scss';

export default function Destination({ destination }: Props) {
  return (
    <li className={styles.container}>
      <DestinationIcon name={destination?.label} icon={destination?.icon} />
      <ul className={styles.content}>
        {destination?.channels?.map((channel, i) => (
          <Channel channel={channel} destinationId={destination.id} key={i} />
        ))}
      </ul>
    </li>
  );
}
