import { Icon } from 'components';
import { Props } from './types';
import styles from './styles.module.scss';

export default function DestinationIcon({ name = 'destinationName', icon }: Props) {
  return (
    <div className={styles.container}>
      {icon ? <img src={icon} alt={'destination_img'} width={112} /> : <Icon icon="User" />}
      <p className="H600Medium">{name}</p>
    </div>
  );
}
