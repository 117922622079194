import { Container } from '@material-ui/core';
import { Modal } from 'components';
import Sidebar from './sidebar';
import Header from './header';
import { useUser } from 'hooks';
import { Props } from './types';
import styles from './styles.module.scss';

const Layout = ({ type = 'sidebar', title, children, open }: Props) => {
  const { user } = useUser();
  let layout = null;

  if (type === 'sidebar') {
    layout = (
      <div className={styles.optionWrapper} id={'optionWrapper'}>
        <Sidebar />
        <div className={styles.contentWrapper}>
          <Container classes={{ root: styles.containerRoot }} maxWidth={false}>
            {children}
          </Container>
        </div>
      </div>
    );
  }

  if (type === 'modal') {
    layout = open ? (
      <Modal>
        <div className={`${styles.contentWrapper} ${styles.modal}`}>{children}</div>
      </Modal>
    ) : null;
  }

  if (type === 'menu') {
    layout = (
      <div className={styles.menuWrapper}>
        <Header title={title} user={user} />
        <div className={styles.contentWrapper}>
          <Container maxWidth="lg">{children}</Container>
        </div>
      </div>
    );
  }

  return layout;
};

export default Layout;
