import { UseChipProps } from './types';

const useChip = ({ isActive, value, quickFiltersColors }: UseChipProps) => {
  const configColors = quickFiltersColors;
  if (!configColors[value])
    return {
      color: 'black',
      backgroundColor: 'white',
    };

  let color, backgroundColor, borderColor;

  if (isActive) {
    color = configColors[value].color;
    backgroundColor = configColors[value].bgColor;
    borderColor = configColors[value].color;
  } else {
    color = configColors[value].color;
    backgroundColor = configColors[value].bgColor;
    borderColor = configColors[value].bgColor;
  }

  return {
    color: color,
    backgroundColor: backgroundColor,
    borderColor: borderColor,
  };
};

export default useChip;
