import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { gql } from '@apollo/client';
import { STRAPI } from '../utils/definitions';

import Logged from '../logic/logged';
import { GraphQLMutation, GraphQLQuery, GraphQLVariable } from '../interfaces/graphql';

const httpLink = createHttpLink({
  uri: STRAPI + '/graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = Logged.getSessionToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function executeCount(model: string, variables?: GraphQLVariable): Promise<number> {
  let param = 'totalCount';
  if (variables) {
    param = 'count';
  }
  return client
    .query({
      query: gql`
        query GetTotal($where: JSON) {
            ${model}Connection(where: $where) {
                aggregate{
                    ${param}
                }
            }
        }
      `,
      variables: variables,
    })
    .then((res) => {
      const field = model + 'Connection';
      if (res.data && res.data[field] && res.data[field].aggregate) {
        if (res.data[field].aggregate[param]) {
          return res.data[field].aggregate[param];
        }
      }
      return 0;
    })
    .catch((err) => console.log(err));
}

function executeQuery(query: GraphQLQuery, variables = {}) {
  const returnField = query.field;
  const gqlQuery = query.query;

  return client
    .query({
      query: gqlQuery,
      variables: variables,
      // fetchPolicy: "no-cache"
    })
    .then((res) => {
      if (res && res.data && res.data[returnField]) {
        if (res.data[returnField].length > 0) {
          return res.data[returnField];
        }
      }
      return false;
    });
}

function executeMutation(query: GraphQLMutation, original: any) {
  const gqlQuery = query.mutation;
  const data = original;

  return client
    .mutate({
      mutation: gqlQuery,
      variables: data,
    })
    .then((res) => {
      console.log('MUTATION result', res.data);
      return true;
    })
    .catch((err) => {
      console.log('ERR', err);
    });
}

export { client, executeCount, executeQuery, executeMutation };
