import { requester } from 'logic';
import { DeleteImageData, EditImageNameData } from '../interfaces/mediaLibrary';
import {
  DELETE_MEDIA_GALLERY,
  GET_MEDIA_GALLERY,
  RENAME_MEDIA_GALLERY,
  UPLOAD_MEDIA_GALLERY,
} from '../utils/definitions';

const VALID_EXTENSIONS = ['jpeg', 'jpg', 'png', 'gif', 'svg', 'webp'];
class MediaLibrary {
  async getGallery(limit: any, start: any, filter?: any) {
    try {
      const postData: any = {};
      postData['limit'] = limit;
      postData['start'] = start;
      postData['where'] = { name_contains: filter };
      const result = await requester.post(GET_MEDIA_GALLERY, postData);
      const finalData = result.data.filter((file: any) =>
        VALID_EXTENSIONS.some((v) => {
          return file.mime.includes(v);
        })
      );
      return Promise.resolve({ data: finalData, count: result.count.count });
    } catch (error) {
      console.log(error);
    }
  }

  async uploadFile(fileData: FormData) {
    try {
      const data = await requester.post(UPLOAD_MEDIA_GALLERY, fileData, {
        'Content-Type': 'multipart/form-data',
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async deleteFile(fileName: DeleteImageData) {
    try {
      const data = await requester.post(DELETE_MEDIA_GALLERY, fileName);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async renameFile(fileData: EditImageNameData) {
    try {
      const data = await requester.post(RENAME_MEDIA_GALLERY, fileData);
      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }
}

export default new MediaLibrary();
