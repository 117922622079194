//@ts-nocheck
import { useEffect, useState } from 'react';
import { ErrorMessages, Validations } from './data';
import { EMAIL_REGEX } from './regex';

const useInput = ({
  className,
  label,
  icon,
  required,
  name,
  value,
  type,
  disablePaste,
  repeatField,
  repeatError,
  validation,
  readonly,
}: any) => {
  const [focus, setFocus] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const TriggerInputEvent = () => {
    if (typeof window !== 'undefined') {
      const event = new Event('focusedInput');
      window.dispatchEvent(event);
    }
  };

  useEffect(() => {
    if (typeof repeatField === 'undefined') return;
    if (!focus && !isMounted) return;
    if (!isMounted) return setIsMounted(true);
    ValidateInput();
    //eslint-disable-next-line
  }, [repeatField]);

  useEffect(() => {
    if (!isMounted) return setIsMounted(true);
    if (!value) return;
    ValidateInput();
    //eslint-disable-next-line
  }, [validation]);

  const ValidateInput = () => {
    const isRequiredAndNotFilled = required && !value;
    const isInvalidEmaild = !EMAIL_REGEX.test(value);
    const isRepeatField = repeatField;
    let isNotValid = false;

    if (validation && Validations[validation]) {
      const validationRegex = Validations[validation];
      isNotValid = !validationRegex.test(value);
    }

    if (isRequiredAndNotFilled) return setError(ErrorMessages.required);
    if (type === 'email' && isInvalidEmaild) return setError(ErrorMessages.email);
    if (isNotValid) return setError(ErrorMessages[validation]);
    if (isRepeatField) return setError(ErrorMessages[repeatError]);
    if (error) setError('');
  };

  const HandleOnBlur = () => {
    if (focus) setFocus(false);
    ValidateInput();
  };

  let isFocused = value || focus ? true : false;
  if (!isFocused && value === 0) {
    isFocused = true;
  }

  if (readonly) isFocused = value ? true : false;

  if (type === 'date') isFocused = true;

  return {
    focus,
    setFocus,
    error,
    setError,
    TriggerInputEvent,
    ValidateInput,
    HandleOnBlur,
    isFocused,
    showPassword,
    setShowPassword,
  };
};

export default useInput;
