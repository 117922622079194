import { FieldError } from 'components';
import { ChildrenProps } from './types';
import { TL } from 'utils';
import styles from './style.module.scss';

const AdvancedInput = ({ key, field, inputs, error }: ChildrenProps) => (
  <div key={key}>
    <p>{field.label}</p>
    <div className={styles.container}>
      {inputs.map((el: any, i: number) => (
        <div className={styles.content} key={i}>
          <p style={{ textTransform: 'capitalize' }}>{el}</p>
          <input className={styles.input} type="number" />
        </div>
      ))}
      <div className={styles.content}>
        <p>{TL('backend_units')}</p>
        <select>
          {field.units.map((unit: string, i: number) => (
            <option style={{ textAlign: 'center' }} key={i}>
              {unit}
            </option>
          ))}
        </select>
      </div>
    </div>
    {error && <FieldError error={error} />}
  </div>
);

export default AdvancedInput;
