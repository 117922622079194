export const isString = function (str) {
  return typeof str === 'string';
};

export const isNumber = function (num) {
  if (/^\d+(\.\d+)?$/.test(num)) return true;
  return typeof num === 'number' && !isNaN(num) && isFinite(num);
};

export const isStringLength = function (str, length) {
  return isString(str) && str.trim().length >= length;
};

export const isRequired = function (str, type) {
  switch (type) {
    case 'string':
      return isString(str) && str.trim().length > 0;

    default:
      return typeof str !== 'undefined';
  }
};

export const getValidationErrorText = function (validation) {
  switch (validation.id) {
    case 'stringRequired':
      return 'backend_form_validation_required';
    case 'length':
      return 'backend_form_validation_length';
    case 'number':
      return 'backend_form_validation_number';
    default:
      return '';
  }
};

export const isValidDate = function (date) {
  const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])[- /.]((0?[1-9]|1[012])[- /.](19|20)?[0-9]{2})*$/;
  return dateRegex.test(date);
};

// yyyy-mm-dd
export const isValidDate2 = (date) => {
  const regex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
  return regex.test(date);
};
