import HeaderTitle from 'containers/tableScreen/components/headertitle';
import styles from './styles.module.scss';
import { HeaderProps } from 'containers/tableScreen/types';
const Header = ({ title, total, filteredTotal, icon, hideResult, children }: HeaderProps) => {
  return (
    <div className={styles.firstContent}>
      <HeaderTitle
        hideResult={hideResult}
        title={title}
        total={total || 0}
        filteredTotal={filteredTotal || 0}
        icon={icon}
      />
      {children}
    </div>
  );
};

export default Header;
