//@ts-nocheck
import { useRef, useEffect } from 'react';
import Ajv from 'ajv';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
const ajv = new Ajv({ allErrors: true, verbose: true });

const JsonEditor = ({ value = {}, onChange, mode }) => {
  const jsonEditorRef = useRef(null);

  useEffect(() => {
    if (jsonEditorRef.current !== null) {
      jsonEditorRef.current.set(value);
      jsonEditorRef.current.setMode(mode);
    }
  }, [value, mode]);

  const setRef = (instance) => {
    if (instance) {
      jsonEditorRef.current = instance.jsonEditor;
    } else {
      jsonEditorRef.current = null;
    }
  };

  return <Editor ref={setRef} value={value} onChange={onChange} ajv={ajv} />;
};

export default JsonEditor;
