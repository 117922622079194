import { getLocalStorage } from '../utils/localStorage';

class Logged {
  isLogged = () => {
    const session = getLocalStorage('session');
    if (!session || !session.isLogged) {
      return false;
    }

    return true;
  };

  getSessionToken = () => {
    const session = getLocalStorage('session');
    return session.token;
  };

  redirect = () => {
    window.location.href = '/';
  };
}

export default new Logged();
