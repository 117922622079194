import { Button } from 'components';
import styles from './styles.module.scss';

interface Props {
  options: option[];
  selectedOptions: [];
  onSelect: (arg: string) => void;
  onDeselect: (arg: string) => void;
}

interface option {
  value: any;
  label: any;
}

const MultiSelection = ({ options, selectedOptions, onSelect, onDeselect }: Props) => {
  return (
    <div className={styles.multiSelection}>
      <span className={'H300Medium'}>Avaiable options:</span>
      <span className={'H300Medium'}>Selected options:</span>
      <div role={'listbox'} className={styles.list}>
        {options.map((option, idx) => {
          const { label, value } = option;
          const alreadySelected = selectedOptions.find((op) => op === value);
          if (alreadySelected) return null;
          return (
            <Button key={`avaiable_${option}_${idx}`} type={'text'} onClick={() => onSelect(value)} text={label} />
          );
        })}
      </div>
      <div role={'listbox'} className={styles.list}>
        {selectedOptions.map((option, idx) => {
          const foundOption = options.find((o) => o.value === option);

          if (!foundOption) return null;

          const { value, label } = foundOption;

          return (
            <Button type="text" key={`selected_${option}_${idx}`} onClick={() => onDeselect(value)} text={label} />
          );
        })}
      </div>
    </div>
  );
};

export default MultiSelection;
