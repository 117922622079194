import BasicInput from './BasicInput';
import AdvancedInput from './AdvancedInput';
import { assertNever } from 'utils/assertNever';
import { Props } from './types';

const InputGroup = ({ error, field }: Props) => {
  const inputs = Object.keys(field.inputGroup);

  switch (field.userType) {
    case 'basic':
      return <BasicInput field={field} inputs={inputs} error={error} />;
    case 'advanced':
      return <AdvancedInput field={field} inputs={inputs} error={error} />;
    default:
      return assertNever(field.userType);
  }
};

export default InputGroup;
