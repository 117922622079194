import { useContext } from 'react';
import LocaleContext from 'context/LocaleContext';

const useConfig = () => {
  const { locale, setLocale, contentLocale, setContentLocale } = useContext(LocaleContext);

  return {
    locale,
    setLocale,
    contentLocale,
    setContentLocale,
  };
};

export default useConfig;
