import { useState } from 'react';
import { DateTime } from 'luxon';
import MobileDateRangePicker from '@mui/lab/MobileDateRangePicker';
import AdapterLuxon from '@mui/lab/AdapterLuxon/';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { isValidDate2, TL } from 'utils';
import styles from './styles.module.scss';

const BasicDateRangePicker = ({ state, setState, outline = false }: any) => {
  const { startDate, endDate } = state;
  const [value, setValue] = useState<any>([
    startDate ? startDate : DateTime.now().minus({ days: 6 }).endOf('day').toISODate(),
    endDate ? endDate : DateTime.now().endOf('day').toISODate(),
  ]);

  const changesOnDate = (SD: any, ED: any) => {
    if (SD !== startDate || ED !== endDate) return true;
    return false;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <MobileDateRangePicker
        inputFormat="dd/MM/yyyy"
        value={value}
        maxDate={DateTime.now()}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        onAccept={() => {
          const a: any = value[0];
          const b: any = value[1];
          const SD: any = a && DateTime.fromISO(a).toFormat('yyyy-MM-dd');
          const ED: any = b && DateTime.fromISO(b).toFormat('yyyy-MM-dd');
          if (changesOnDate(SD, ED))
            setState({
              ...state,
              loading: true,
              startDate: isValidDate2(SD) ? SD : undefined,
              endDate: isValidDate2(ED) ? ED : undefined,
            });
        }}
        renderInput={(startProps, endProps) => {
          // @ts-ignore
          const { type, disabled, onBlur, onClick, onFocus } = startProps.inputProps;
          return (
            <>
              <label className={`${styles.labelRange} H300Medium`}>{TL('backend_range')}</label>
              <input
                ref={startProps.inputRef}
                value={`${startProps.inputProps?.value} - ${endProps.inputProps?.value}`}
                disabled={disabled}
                type={type}
                onBlur={onBlur}
                onClick={onClick}
                onFocus={onFocus}
                className={`${styles.fullInput} H400Light`}
                size={22}
                readOnly
              />
              <input ref={startProps.inputRef} {...startProps.inputProps} style={{ display: 'none' }} />
              <input ref={endProps.inputRef} {...endProps.inputProps} style={{ display: 'none' }} />
            </>
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default BasicDateRangePicker;
