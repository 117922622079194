import { Button } from 'components';
import { TL } from 'utils';
import { CtaBarProps } from './types';
import style from '../style.module.scss';

const CTABAR = ({ onClose, onSave, onEdit, leftText, rightText, saveDisabled = false, float = false }: CtaBarProps) => {
  if (!onClose) return null;

  return (
    <div className={`${style.ctaBar} ${float ? style.float : ''}`}>
      <Button
        type="secondaryLine"
        onClick={onClose}
        text={leftText || TL('backend_cancel')}
        icon={'X'}
        iconAlignment={'right'}
      />
      {onSave && <Button onClick={onSave} disabled={saveDisabled} text={rightText || TL('backend_save')} />}
      {onEdit && <Button onClick={onEdit} disabled={saveDisabled} text={rightText || TL('backend_edit')} />}
    </div>
  );
};

export default CTABAR;
