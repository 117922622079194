import { useState, ReactNode, createContext, useEffect } from 'react';
import { getLocalStorage, setLocalStorage, setColorsProperties } from 'utils';
import configDefaultColors from 'config/config_default_colors.json';
import { Colors, QuickFiltersColors, Route, TableRoute } from 'interfaces/config';
import { IConfigContext } from 'hooks/types';
import { configMenu } from 'services';
import { NETSEASY_STATUS, NEW_SKIDATA_STATUS } from 'utils/definitions';
import { CLIENT } from 'constants/index';

interface Prop {
  children: ReactNode;
}

const defaultColors: Colors = configDefaultColors.colors;

const defaultQuickFiltersColors: QuickFiltersColors = configDefaultColors.quickFiltersColors;

const Context = createContext<IConfigContext>({
  channelIcon: '',
  channelId: 0,
  channels: null,
  clientName: '',
  colors: defaultColors,
  currency: '',
  currentChannel: null,
  destinationId: 0,
  destinationName: '',
  loading: true,
  locales: null,
  logos: null,
  netsEasyClient: '',
  netsEasyStatusHealthLink: '',
  quickFiltersColors: defaultQuickFiltersColors,
  routes: null,
  setChannelId: () => {},
  setChannels: () => {},
  setColors: () => {},
  setCurrentChannel: () => {},
  setDestinationId: () => {},
  setLoading: () => {},
  setLocales: () => {},
  setQuickFiltersColors: () => {},
  setRoutes: () => {},
  setSession: () => {},
  skidataStatusHealtLink: '',
});

export function ConfigContextProvider({ children }: Prop) {
  const configColors = configMenu.config?.colors?.colors || getLocalStorage('colors') || defaultColors;
  const [colors, setColors] = useState<Colors>(configColors);
  setLocalStorage('colors', configColors);
  setColorsProperties(configColors);
  const configQuickFiltersColors =
    configMenu.config?.colors?.quickFiltersColors || getLocalStorage('quickFiltersColors') || defaultQuickFiltersColors;
  const [quickFiltersColors, setQuickFiltersColors] = useState<QuickFiltersColors>(configQuickFiltersColors);
  setLocalStorage('quickFiltersColors', configQuickFiltersColors);
  const [session, setSession] = useState(getLocalStorage('session'));
  const [destinationId, setDestinationId] = useState(getLocalStorage('destinationId') || 0);
  const [channels, setChannels] = useState(
    getLocalStorage('channels') || configMenu.config?.destinations[destinationId].channels
  );
  const [channelId, setChannelId] = useState(getLocalStorage('channelId') || 0);
  const [routes, setRoutes] = useState<Array<TableRoute | Route> | null>(
    getLocalStorage('routes') || configMenu.currentRoutes
  );
  const [currentChannel, setCurrentChannel] = useState(
    getLocalStorage('currentChannel') || configMenu.config?.destinations[destinationId].channels[channelId]
  );
  const [locales, setLocales] = useState(getLocalStorage('locales') || configMenu.config?.locales);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await configMenu.initialize(session?.role || 'viewer');
      setRoutes(configMenu.currentRoutes);
      setLoading(false);
    })();
  }, [channelId, destinationId, session?.role]);

  //@to-do retrive values by config
  const CURRENCY = '€';

  const NETSEASY_STATUS_SLUG = '0042000062177b9d1e524d393daab435';
  const NETSEASY_STATUS_CLIENT = 'flamingo';
  const NETSEASY_STATUS_HEALTH_LINK = `${NETSEASY_STATUS}/${NETSEASY_STATUS_SLUG}?client=${NETSEASY_STATUS_CLIENT}`;

  const SKIDATA_STATUS_CLIENT = 'bjorli';
  const SKIDATA_STATUS_UUID = '288b3370-7397-11ec-8445-00505692ebb7';
  const SKIDATA_STATUS_HEALTH_LINK = `${NEW_SKIDATA_STATUS}?client=${SKIDATA_STATUS_CLIENT}&uuid=${SKIDATA_STATUS_UUID}`;

  const CLIENT_NAME = CLIENT[destinationId];

  return (
    <Context.Provider
      value={{
        channelIcon: configMenu?.config?.destinations[destinationId].icon || '',
        channelId,
        channels,
        clientName: CLIENT_NAME,
        colors,
        currency: CURRENCY,
        currentChannel,
        destinationId,
        destinationName: configMenu.config?.destinations[destinationId]?.label.toLowerCase() || '',
        loading,
        locales,
        logos: configMenu.config?.logos || {},
        netsEasyClient: NETSEASY_STATUS_CLIENT,
        netsEasyStatusHealthLink: NETSEASY_STATUS_HEALTH_LINK,
        quickFiltersColors,
        routes,
        setChannelId,
        setChannels,
        setColors,
        setCurrentChannel,
        setDestinationId,
        setLoading,
        setLocales,
        setQuickFiltersColors,
        setRoutes,
        setSession,
        skidataStatusHealtLink: SKIDATA_STATUS_HEALTH_LINK,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default Context;
