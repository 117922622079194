import { getLocalStorage, setLocalStorage } from './localStorage';

const setSessionId = (value) => {
  return setLocalStorage('sessionId', value);
};

const getSessionId = () => {
  return getLocalStorage('sessionId');
};

export { getSessionId, setSessionId };
