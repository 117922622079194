import { requester } from 'logic';
import { useEffect, useState } from 'react';
import { DTA_DETAILS } from 'utils/definitions';
import { Props } from './types';

const useDTA = ({ client, uuid }: Props) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    (async () => {
      if (!client || !uuid) return;
      setLoading(true);
      try {
        if (error) setError(null);
        const data = await requester.get(`${DTA_DETAILS}?client=${client?.toLowerCase()}&uuid=${uuid}&withContacts=1`);
        if (!data) return setError('No data');
        if (data?.error) return setError(data.error);
        if (data) setData(data);
        setLoading(false);
      } catch (error: any) {
        console.log(error);
        setLoading(false);
        setError(error);
      }
    })();
    // eslint-disable-next-line
  }, [client, uuid]);

  return {
    data,
    loading,
    error,
  };
};

export default useDTA;
