import { Suspense, lazy, useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Loading, Logout, ProtectedRoute } from 'components';
import ThemeWrapper from './themeWrapper';
import { ErrorPage } from 'pages';
import configDefaultColors from 'config/config_default_colors.json';
import { UserContextProvider } from 'context/UserContext';
import { tableReducer, TableScreenProvider } from 'store/tableScreen';
import { assertNever, getLocalStorage /* ,initGA, GApageView */ } from 'utils';
import { useConfig } from 'hooks';

const Login = lazy(() => import('./login'));
const Menu = lazy(() => import('./menu'));
const Analytics = lazy(() => import('./analytics'));
const TableScreen = lazy(() => import('./tableScreen/index_bjorli'));
const MediaLibrary = lazy(() => import('./mediaLibrary'));
const Arrivals = lazy(() => import('./arrivals'));
const Config = lazy(() => import('./config'));
const TestRoute = lazy(() => import('./testRoute'));
const Products = lazy(() => import('./products'));
const WebStatus = lazy(() => import('./WebStatus'));
// initGA();

type RouteType =
  | 'table'
  | 'order'
  | 'analytics'
  | 'media'
  | 'arrivals'
  | 'subitem'
  | 'config'
  | 'entries'
  | 'webStatus'
  | 'testRoute';

const returnRouteByType = (type: RouteType, props: any) => {
  if (props.route.id === '/admin/allProducts') {
    return <Products {...props} />;
  }
  switch (type) {
    case 'table':
    case 'order':
    case 'entries':
      return <TableScreen {...props} />;
    case 'analytics':
      return <Analytics {...props} />;
    case 'media':
      return <MediaLibrary {...props} />;
    case 'arrivals':
      return <Arrivals />;
    case 'subitem':
      return null;
    case 'config':
      return <Config />;
    case 'webStatus':
      return <WebStatus />;
    case 'testRoute':
      return <TestRoute />;
    default:
      return assertNever(type);
  }
};

const _Router = () => {
  const [parsedRoutes, setParsedRoutes] = useState<Array<any>>([]);
  const { routes, loading } = useConfig();

  useEffect(() => {
    if (routes && Array.isArray(routes) && !loading) {
      const res = routes.map((route) => {
        if (route.protected) {
          return (
            <Route
              key={route.id}
              path={route.id}
              element={<ProtectedRoute>{returnRouteByType(route.type, { route })}</ProtectedRoute>}
            />
          );
        } else {
          return <Route key={route.id} path={route.id} element={returnRouteByType(route.type, { route })} />;
        }
      });
      setParsedRoutes(res);
    }
  }, [routes, loading]);

  if (!parsedRoutes.length || loading) return <Loading />;

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <UserContextProvider>
          <TableScreenProvider tableReducer={tableReducer}>
            <ThemeWrapper colors={getLocalStorage('colors') || configDefaultColors.colors}>
              <CssBaseline />
              <Routes>
                <Route
                  path="/admin/dashboard"
                  element={
                    <ProtectedRoute>
                      <Menu />
                    </ProtectedRoute>
                  }
                />
                <Route path="/logout" element={<Logout />} />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Login />} />
                {parsedRoutes}
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </ThemeWrapper>
          </TableScreenProvider>
        </UserContextProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export default _Router;
