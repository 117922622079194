import { ItemMenuIcon } from 'components';
import { useLocation } from 'react-router-dom';
import { useConfig } from 'hooks';
import { TL } from 'utils';
import { Props } from './types';
import styles from './style.module.scss';

const HeaderTitle = ({ total, filteredTotal, title, hideResult }: Props) => {
  const { routes } = useConfig();
  const { pathname } = useLocation();
  const icon = routes?.find((el) => el.id === pathname)?.icon;

  const totalText = `${total}`;
  const boldText = `${filteredTotal}`;
  return (
    <div className={styles.container}>
      {icon && <ItemMenuIcon icon={icon} />}
      <div className={styles.titleAndTotalContainer}>
        <span className={`${styles.title} H600Medium`}>{title}</span>
        {total && filteredTotal && !hideResult ? (
          <div className={styles.totalContainer}>
            <span className={`${styles.total} H300Light`}>
              <span className={`${styles.boldTotal} H300Heavy `}>{boldText}</span>
              <span className={'H300Medium'}>/{totalText}</span>
              {TL('backend_table_screen_erase_bulk_action_elements')}
            </span>
          </div>
        ) : (
          !hideResult && (
            <div className={styles.totalContainer}>
              <span className={`${styles.total} H300Light`}>
                0 {TL('backend_table_screen_erase_bulk_action_elements')}
              </span>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default HeaderTitle;
