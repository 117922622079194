import { cloneDeep } from 'lodash';
import { setNestedValue } from 'utils';
import { HandleInputChangeParamsExtended } from '../types';

const onHandleInputChange = ({
  id,
  value,
  field,
  arrayProp,
  arrayIndex,
  arrayProp2,
  arrayIndex2,
  state,
}: HandleInputChangeParamsExtended) => {
  if (!state.selectedItem || !state.selectedItem.item || !state.selectedItem.open) return;
  let type = '';
  if (field && typeof field.type === 'string') type = field.type.toLowerCase();
  const currentSelectedItem = cloneDeep(state.selectedItem.item);
  if (type === 'image') {
    // TODO handle strapi Media type upload or change schema for string url
  } else if (typeof arrayProp !== 'undefined' && typeof arrayIndex !== 'undefined') {
    // modificar array de objetos dentro de array de objetos
    if (typeof arrayProp2 !== 'undefined' && typeof arrayIndex2 !== 'undefined') {
      const parentArray = currentSelectedItem[arrayProp2];
      if (!parentArray || !Array.isArray(parentArray)) return;
      const parentItem = parentArray[arrayIndex2];
      if (!parentItem) return;
      const childArray = parentItem[arrayProp];
      if (!childArray || !Array.isArray(childArray)) return;
      const childItem = childArray[arrayIndex];
      if (!childItem) return;
      childItem[id] = value;
    } else {
      // modificar array de objetos
      const arr = currentSelectedItem[arrayProp];
      if (!arr || !Array.isArray(arr)) return;
      const item = arr[arrayIndex];
      if (field && field.path && field.path !== '') setNestedValue(item, field.path, value);
      else item[id] = value;
    }
  } else if (field && field.path && field.path !== '') setNestedValue(currentSelectedItem, field.path, value);
  else currentSelectedItem[id] = value;

  return currentSelectedItem;
};

export default onHandleInputChange;
