import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'hooks';

const ProtectedRoute = ({ children }: any) => {
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.isLogged) return navigate('/login');
  }, [user.isLogged, navigate]);

  return children;
};

export default ProtectedRoute;
