import { Props } from './types';
import styles from './styles.module.css';

const RangePicker = ({ name, min, max, value, onChange }: Props) => (
  <div className={styles.container}>
    <span>{min}</span>
    <input className={styles.content} onChange={onChange} type="range" name={name} min={min} max={max} value={value} />
    <span>{value}</span>
  </div>
);

export default RangePicker;
