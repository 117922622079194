import Checkbox from '@material-ui/core/Checkbox';
import { Props } from './types';

import './styles.scss';

const CheckBox = ({ checked, onChange, className, ariaLabel }: Props) => (
  <Checkbox
    checked={checked}
    onChange={onChange || undefined}
    className={className}
    inputProps={{ 'aria-label': ariaLabel }}
  />
);

export default CheckBox;
