import { useTable, useSortBy, usePagination } from 'react-table';
import { Icon } from 'components';
import useTable2 from './useTable2';
import useTableWrapper from './useTableWrapper';
import style from './style.module.scss';
import { StripTags } from 'utils/stripTags';
import { useConfig } from 'hooks';

function Table({ columns, data, onSort, onRowClick, excludedSortByFields, selected, externalSort, sort }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
    },
    useSortBy,
    usePagination
  );
  const { colors } = useConfig();

  if (data) {
    data.forEach((element) => {
      if (element.description) element.description = StripTags(element.description);
    });
  }

  const { handleRowClick, handleSortBy, iconState, setIconState } = useTable2({ onRowClick, onSort, sortBy });

  return (
    <table {...getTableProps()} className={`${style.table} table`}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} onClick={() => setIconState(false)} className="H300Medium">
            {headerGroup.headers.map((column, i) => {
              const needSortByProp = typeof onSort !== 'undefined' && !excludedSortByFields.includes(column.id);
              const colProps = needSortByProp ? handleSortBy(column) : undefined;
              const showChevron = externalSort ? externalSort?.orderBy === column.id : column.isSorted;
              const showChevronDown =
                column.isSortedDesc || (externalSort?.desc && externalSort?.orderBy === column.id);

              return (
                <th {...column.getHeaderProps(colProps)} className={style.headerColumn}>
                  <span className={style.headerCell} style={{ display: 'flex', justifyContent: columns[i].align }}>
                    {column.render('Header')}
                    {showChevron ? (
                      <Icon size="SC" icon={showChevronDown ? 'ChevronDown' : 'ChevronUp'} color={colors.secondary} />
                    ) : (
                      <div>
                        {' '}
                        {iconState && sort && column.id === sort.field ? (
                          <Icon
                            size="SC"
                            icon={sort.order === 'desc' ? 'ChevronDown' : 'ChevronUp'}
                            color={colors.secondary}
                          />
                        ) : (
                          <span className={style.headerCellIconPlaceholder} />
                        )}
                      </div>
                    )}
                  </span>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={`${style.contentRow} ${selected && selected.id === row.original.id ? style.selected : ''}`}
              onClick={() => handleRowClick(row.original.id)}
            >
              {row.cells.map((cell, i) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={`${[style.cell]} H300Light`}
                    style={{ textAlign: columns[i].align }}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default function TableWrapper(props) {
  const {
    columns,
    data,
    onSort,
    onRowClick,
    loading,
    header,
    className = null,
    selected,
    onItemRowClick,
    rowIsChecked,
    checkboxAllIsChecked,
    neutral = false,
    externalSort,
    search,
    filters,
    sort,
  } = props;

  const { cols, excludedSortByFields, rowsData } = useTableWrapper({
    checkboxAllIsChecked,
    columns,
    data,
    onItemRowClick,
    rowIsChecked,
    onRowClick,
    search,
    filters,
  });

  let auxClassName = className;
  if (neutral) {
    auxClassName = style.neutralTable;
  }
  return (
    <div className={[style.tableContainer, auxClassName].join(' ')}>
      {header}
      {data && (
        <Table
          columns={cols}
          data={rowsData}
          onSort={onSort}
          onRowClick={onRowClick}
          loading={loading}
          excludedSortByFields={excludedSortByFields}
          selected={selected}
          externalSort={externalSort}
          sort={sort}
        />
      )}
      {header}
      {loading && <div className={style.loading} />}
    </div>
  );
}
