import { DateTime } from 'luxon';
import { Currency } from 'components';
import { requester } from 'logic';
import { GraphicData } from '../containers/analytics/types';
import { TL } from 'utils';
import { ANALYTICS } from 'utils/definitions';
import { ByDay, ByType } from 'interfaces/analytics';

const getGraphicData = (byDay: ByDay[], limit: number) => {
  const graphicData: GraphicData[] = [];
  byDay.forEach((day) => {
    graphicData.push({
      label: day.day,
      value: day.total,
    });
  });
  return graphicData;
};

const newGetParsedAnalyticsByDay = (byDay: any[], limit: number) => {
  const res = byDay.map((el) => {
    return {
      byProduct: [...el.byProduct],
      byPlatform: { ...el.byPlatform },
      label: el.day,
      value: el.total,
    };
  });
  return res;
};

const getPieData = (byType: Array<ByType>) => {
  const pieData: Array<GraphicData> = [];
  byType.forEach((item) => {
    const type = {
      label: item.type,
      value: item.total,
    };
    pieData.push(type);
  });
  return pieData;
};

export const fetchAnalytics = async ({
  startDate,
  endDate,
  client,
}: {
  startDate?: string;
  endDate?: string;
  client: string;
}) => {
  const MAX_DAYS = startDate && endDate ? 100 : 7;
  const analyticsUrl =
    startDate && endDate ? `${ANALYTICS}?client=${client}&startDate=${startDate}&endDate=${endDate}` : ANALYTICS;

  try {
    const data: any = await requester.get(analyticsUrl);
    const graphicData = getGraphicData(data.byDay, MAX_DAYS);
    const newGraphicData = newGetParsedAnalyticsByDay(data.byDay, MAX_DAYS);

    const lastOrders = data.orders
      .sort((a: any, b: any) => {
        return DateTime.fromISO(b.completedAt).toMillis() - DateTime.fromISO(a.completedAt).toMillis();
      })
      .slice(0, 5);

    const salesTotal = data.summary.amount;
    // for (let i = 0; i < data.orders.length; i++) {
    //   const order = data.orders[i];
    //   salesTotal += order.total;
    // }
    const currency = data.currency;
    // const sales = data.orders.length;
    // const salesAvg = salesTotal / sales;
    const pieData = getPieData(data.byType);
    const overviewData = [
      {
        label: TL('backend_analytics_sales_label') + `(${currency})`,
        value: <Currency amount={String(Math.round(salesTotal))} shortFormat align={'center'} />,
      },
      {
        label: TL('backend_analytics_paid_orders_label'),
        value: data.summary.numOrders,
      },
      {
        label: TL('backend_analytics_average_order_label') + `(${currency})`,
        value: <Currency amount={String(Math.round(data.summary.averages.perOrder))} shortFormat align={'center'} />,
      },
      {
        label: TL('backend_analytics_products_label'),
        value: data.summary.numProducts,
      },
    ];

    const overviewMobile = [
      {
        label: TL('backend_analytics_sales_mobile_total_label') + `(${currency})`,
        value: <Currency amount={String(Math.round(data.platform.mobile.total))} shortFormat align={'center'} />,
      },
      {
        label: TL('backend_analytics_sales_mobile_orders_label'),
        value: Math.round(data.platform.mobile.numOrders),
      },
      {
        label: TL('backend_analytics_sales_mobile_average_label') + `(${currency})`,
        value: (
          <Currency amount={String(Math.round(data.platform.mobile.averages.perOrder))} shortFormat align={'center'} />
        ),
      },
      {
        label: TL('backend_analytics_sales_mobile_products_label'),
        value: Math.round(data.platform.mobile.numProducts),
      },
    ];

    const overviewDesktop = [
      {
        label: TL('backend_analytics_sales_desktop_total_label') + `(${currency})`,
        value: <Currency amount={String(Math.round(data.platform.desktop.total))} shortFormat align={'center'} />,
      },
      {
        label: TL('backend_analytics_sales_desktop_orders_label'),
        value: Math.round(data.platform.desktop.numOrders),
      },
      {
        label: TL('backend_analytics_sales_desktop_average_label') + `(${currency})`,
        value: (
          <Currency amount={String(Math.round(data.platform.desktop.averages.perOrder))} shortFormat align={'center'} />
        ),
      },
      {
        label: TL('backend_analytics_sales_desktop_products_label'),
        value: Math.round(data.platform.desktop.numProducts),
      },
    ];

    const ordersTableData = {
      items: lastOrders,
    };

    const productsTableData = {
      items: data.byProduct,
    };

    const bundleTableData = {
      items: data.byBundle,
    };

    return {
      loading: false,
      pieData,
      overviewData,
      overviewMobile,
      overviewDesktop,
      graphicData,
      ordersTableData,
      productsTableData,
      bundleTableData,
      allData: data,
      newGraphicData,
    };
  } catch (error) {
    if (process.env.NODE_ENV === 'development') console.log('errr', error);
    return Promise.reject(error);
  }
};
