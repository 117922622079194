import { getInitialState } from 'containers/tableScreen/logic';
import { DrawerModes, SelectedItem } from 'containers/tableScreen/types';
import { QuickFilterGroup } from 'interfaces/config';
import { GraphQLSearchParams, GraphQLWhereParams, GraphQLFilterConfig } from 'interfaces/graphql';
import { initialState } from './state';
import {
  State,
  Action,
  setPaginatorProps,
  handleCustomFiltersProps,
  customFilterToSave,
  snackBarProps,
  createItemProps,
  Prompts,
  promptDeleteProps,
  showGalleryProps,
  setGetItemsProps,
} from './types';

export const tableReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_INITIAL_STATE':
      return {
        ...getInitialState(action.payload),
        queryStringMode: state.queryStringMode ? true : false,
      };
    case 'RESET_STATE':
      return {
        ...initialState,
        stateLoaded: false,
        queryStringMode: action.payload.queryStringMode,
      };
    case 'SET_ROW_CLICK':
      return {
        ...state,
        selectedItem: action.payload,
      };
    case 'SET_CLOSE_ROW':
      return {
        ...state,
        selectedItem: {
          open: false,
          item: false,
        },
        selectedDrawerMode: 'view',
      };
    case 'SET_DISPLAY_MAP':
      return {
        ...state,
        showMap: action.payload,
      };
    case 'SET_FILTER_MENU_MODE':
      return {
        ...state,
        selectedFilterBtn: action.payload,
      };
    case 'SET_FILTERS_VISIBILITY':
      return {
        ...state,
        showFilters: action.payload,
      };
    case 'SET_SELECTED_ITEM_CHANGE':
      return {
        ...state,
        selectedItem: {
          open: true,
          item: { ...action.payload },
        },
      };
    case 'SET_SELECTED_CREATE_ITEM_CHANGE':
      return {
        ...state,
        toCreateItem: {
          open: true,
          item: { ...action.payload },
        },
      };
    case 'SET_SHOW_GALLERY':
      return {
        ...state,
        isGalleryOpen: action.payload.show,
        imageAccessor: action.payload.accessor,
      };
    case 'SET_SHOW_SNACKBAR':
      return {
        ...state,
        showSnackbar: action.payload.open,
        snackbarMessage: action.payload.message,
        snackbarType: action.payload.type,
      };
    case 'SET_IDS_TO_DELETE':
      return {
        ...state,
        showPromptDelete: true,
        toDeleteIds: action.payload.ids,
      };
    case 'SET_SHOW_PROMPT_DELETE':
      return {
        ...state,
        showPromptDelete: action.payload.show,
        toDeleteIds: action.payload.deleteIds ? null : state.toDeleteIds,
      };
    case 'SHOW_PROMPT':
      switch (action.payload.which) {
        case 'resend_email':
          return {
            ...state,
            showPromptResendEmail: !state.showPromptResendEmail,
          };
        case 'missing_info':
          return {
            ...state,
            showPromptMissingInfoEmail: !state.showPromptMissingInfoEmail,
          };
        case 'send_qr_code':
          return {
            ...state,
            showPromptQRCode: !state.showPromptQRCode,
          };
        default:
          return state;
      }
    case 'DELETE_IDS':
      return {
        ...state,
        selectedCheckboxs: null,
        showPromptDelete: false,
        toDeleteIds: null,
      };
    case 'HANDLE_IMAGE_SELECTED':
      return {
        ...state,
        isGalleryOpen: action.payload.open,
      };
    case 'SET_SELECTED_CHECKBOX':
      return {
        ...state,
        selectedCheckboxs: action.payload.checkboxs,
      };
    case 'SET_CREATE_ITEM':
      return {
        ...state,
        toCreateItem: {
          open: action.payload.open,
          item: action.payload.item,
        },
        selectedDrawerMode: action.payload?.mode,
        formErrors: undefined,
      };
    case 'ADD_CUSTOM_FILTER':
      return {
        ...state,
        quickFilterToEdit: null,
        customFilters: action.payload?.customFiltersButtons ? action.payload.customFiltersButtons : state.customFilters,
      };
    case 'SET_SELECTED_DRAWER_TAB':
      return {
        ...state,
        selectedDrawerTab: action.payload.selectedDrawerTabProp,
      };
    case 'SET_QUERY_STRING_MODE':
      return {
        ...state,
        queryStringMode: action.payload.mode,
      };
    case 'SET_ERRORS':
      return {
        ...state,
        formErrors: action.payload.errors,
      };
    case 'SET_FILTERS':
      return {
        ...state,
        list: {
          ...state.list,
          filters: action.payload.filters,
        },
      };
    case 'SET_ADVANCED_FILTERS':
      return {
        ...state,
        advancedFilters: action.payload.filters,
      };

    case 'SET_DRAWER_MODE':
      return {
        ...state,
        selectedDrawerMode: action.payload,
      };
    case 'SET_SELECTED_ITEM':
      return {
        ...state,
        selectedItem: {
          open: true,
          item: action.payload.selectedItem,
        },
      };
    case 'SET_LOADING_TABLE':
      return {
        ...state,
        loading: action.payload.loading,
      };
    case 'SET_LOADING_LIST':
      return {
        ...state,
        list: {
          ...state.list,
          loading: action.payload.loading,
        },
      };
    case 'SET_RESET_QUICKFILTERS': {
      return {
        ...state,
        quickFiltersButtons: action.payload._quickFiltersDefault,
        list: {
          ...state.list,
          page: 1,
          filters: undefined,
          paginator: {
            ...state.list.paginator,
            limit: state.list.paginator.limit ? state.list.paginator.limit : 100,
            start: 0,
          },
        },
      };
    }
    case 'SHOW_FILTER_DRAWER': {
      return {
        ...state,
        quickFilterToEdit: action.payload.filter,
      };
    }
    case 'SET_HANDLE_CUSTOM_FILTER': {
      return {
        ...state,
        customFilters: action.payload.customFiltersButton,
      };
    }
    case 'SET_HANDLE_QUICK_FILTER': {
      return {
        ...state,
        quickFiltersButtons: action.payload.quickFiltersButtons,
      };
    }
    case 'REFRESH': {
      return {
        ...state,
        refresh: !state.refresh,
      };
    }
    case 'SET_HANDLE_SEARCH': {
      return {
        ...state,
        list: {
          ...state.list,
          searchQuery: action.payload.query,
        },
      };
    }

    case 'SET_HANDLE_PAGINATOR': {
      return {
        ...state,
        list: {
          ...state.list,
          page: action.payload.page ? action.payload.page : state.list.page,
          paginator: {
            ...state.list.paginator,
            sort: action.payload.sort ? action.payload.sort : state.list.paginator.sort,
            limit: action.payload.limit ? action.payload.limit : state.list.paginator.limit,
            start: typeof action.payload.start !== 'undefined' ? action.payload.start : state.list.paginator.start,
          },
        },
      };
    }

    case 'SET_GET_ITEMS':
      return {
        ...state,
        selectedCheckboxs: action.payload?.selectedCheckboxs
          ? action.payload.selectedCheckboxs
          : state.selectedCheckboxs,
        loading: false,
        list: {
          ...state.list,
          loading: false,
          items: action.payload.result?.data,
          total: action.payload.result?.count?.totalCount,
          filteredTotal: action.payload.result?.count?.count,
          searchQuery: action.payload?.searchQuery ? action.payload.searchQuery : state.list.searchQuery,
          filters: action.payload?.filters ? action.payload.filters : state.list.filters,
          page: action.payload?.page ? action.payload.page : state.list.page,
          paginator: {
            ...state.list.paginator,
            sort: action.payload?.onSortBy ? action.payload.onSortBy : state.list.paginator.sort,
            start: action.payload?.start ? action.payload.start : state.list.paginator.start,
            limit: action.payload?.limit ? action.payload.limit : state.list.paginator.limit,
          },
        },
        selectedItem: {
          open: action.payload?.selectedItem?.open ? action.payload.selectedItem.open : state.selectedItem.open,
          item: {
            ...(action.payload?.selectedItem?.item ? action.payload.selectedItem.item : state.selectedItem.item),
            customer: action.payload?.customer ? action.payload.customer : state.selectedItem.item.customer,
          },
        },
        selectedDrawerMode: action.payload?.selectedDrawerMode
          ? action.payload.selectedDrawerMode
          : state.selectedDrawerMode,
        toCreateItem: {
          open: action.payload?.toCreateItem?.open ? action.payload.toCreateItem.open : state.toCreateItem.open,
          item: action.payload?.toCreateItem?.item ? action.payload.toCreateItem.item : state.toCreateItem.item,
        },
        showSnackbar: action.payload?.showSnackbar ? action.payload.showSnackbar : state.showSnackbar,
        snackbarMessage: action.payload?.snackbarMessage ? action.payload.snackbarMessage : state.snackbarMessage,
        quickFiltersButtons: action.payload?.quickFiltersButtons
          ? action.payload.quickFiltersButtons
          : state.quickFiltersButtons,
      };

    case 'SHOW_MODAL':
      return {
        ...state,
        showModal: action.payload,
      };

    case 'CREATE_MODAL':
      return {
        ...state,
        createModal: action.payload,
      };

    case 'CREATE_MODAL_TAB':
      return {
        ...state,
        selectedCreateTab: action.payload,
      };

    case 'ADD_PRODUCT_LOADING':
      return {
        ...state,
        addProductLoading: action.payload,
      };

    case 'ADD_PRODUCT_STATE':
      return {
        ...state,
        addProductState: action.payload,
      };

    case 'OPEN_READONLY_DRAWER':
      return {
        ...state,
        ...action.payload,
      };

    case 'CLOSE_READONLY_DRAWER':
      return {
        ...state,
        ...action.payload,
        readOnlyDrawerData: undefined,
        loadingReadOnlyDrawer: false,
      };

    case 'SET_READONLY_DRAWERDATA':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const setInitialState = (route: any): Action => {
  return { type: 'SET_INITIAL_STATE', payload: route };
};

export const _rowClick = (el: any): Action => {
  return { type: 'SET_ROW_CLICK', payload: el };
};

export const _closeRow = (): Action => {
  return { type: 'SET_CLOSE_ROW' };
};

export const _displayMap = (n: 0 | 1): Action => {
  return { type: 'SET_DISPLAY_MAP', payload: n };
};

export const _changeFilterMenu = (mode: 0 | 1): Action => {
  return { type: 'SET_FILTER_MENU_MODE', payload: mode };
};

export const _toggleFilters = (showFilters: boolean): Action => {
  return { type: 'SET_FILTERS_VISIBILITY', payload: showFilters };
};

export const _handleSelectedItemChange = (el: any): Action => {
  return { type: 'SET_SELECTED_ITEM_CHANGE', payload: el };
};

export const _handleSelectedCreateItemChange = (el: any): Action => {
  return { type: 'SET_SELECTED_CREATE_ITEM_CHANGE', payload: el };
};

export const _handleDrawerModeChange = (el: DrawerModes): Action => {
  return { type: 'SET_DRAWER_MODE', payload: el };
};

export const _handleShowGallery = ({ show, accessor }: showGalleryProps): Action => {
  return { type: 'SET_SHOW_GALLERY', payload: { show, accessor } };
};

export const _addIdsToDelete = (ids: string[] | null): Action => {
  return { type: 'SET_IDS_TO_DELETE', payload: { ids } };
};

export const _showPromptDelete = ({ show, deleteIds }: promptDeleteProps): Action => {
  return { type: 'SET_SHOW_PROMPT_DELETE', payload: { show, deleteIds } };
};

export const _showPrompt = (which: Prompts): Action => {
  return { type: 'SHOW_PROMPT', payload: { which } };
};

export const _deleteIds = (): Action => {
  return { type: 'DELETE_IDS' };
};

export const _imageSelected = ({ open }: { open: boolean }): Action => {
  return { type: 'HANDLE_IMAGE_SELECTED', payload: { open } };
};

export const _selectedCheckbox = (checkboxs: string[] | null): Action => {
  return { type: 'SET_SELECTED_CHECKBOX', payload: { checkboxs } };
};

export const _handleCreateItem = ({ open, item, mode }: createItemProps): Action => {
  return { type: 'SET_CREATE_ITEM', payload: { open, item, mode } };
};

export const _addCustomFilters = ({ customFiltersButtons }: { customFiltersButtons?: QuickFilterGroup[] }): Action => {
  return { type: 'ADD_CUSTOM_FILTER', payload: { customFiltersButtons } };
};

export const _handleSnackbar = ({ open = true, message = '', type = 'success' }: snackBarProps): Action => {
  return { type: 'SET_SHOW_SNACKBAR', payload: { open, message, type } };
};

export const _selectedDrawerTab = ({ selectedDrawerTabProp }: { selectedDrawerTabProp: number }): Action => {
  return { type: 'SET_SELECTED_DRAWER_TAB', payload: { selectedDrawerTabProp } };
};

export const _queryStringMode = ({ mode }: { mode: boolean }): Action => {
  return { type: 'SET_QUERY_STRING_MODE', payload: { mode } };
};

export const _setErrors = ({ errors }: any): Action => {
  return { type: 'SET_ERRORS', payload: { errors } };
};

export const _setSelectedItem = ({ selectedItem }: { selectedItem: SelectedItem }): Action => {
  return { type: 'SET_SELECTED_ITEM', payload: { selectedItem } };
};

export const _setLoadingTable = ({ loading }: { loading: boolean }): Action => {
  return { type: 'SET_LOADING_TABLE', payload: { loading } };
};

export const _setLoadingList = ({ loading }: { loading: boolean }): Action => {
  return { type: 'SET_LOADING_LIST', payload: { loading } };
};

export const _resetStateFilters = ({ _quickFiltersDefault }: { _quickFiltersDefault: QuickFilterGroup[] }): Action => {
  return { type: 'SET_RESET_QUICKFILTERS', payload: { _quickFiltersDefault } };
};

export const _customFilterDrawer = ({ filter }: customFilterToSave): Action => {
  return { type: 'SHOW_FILTER_DRAWER', payload: { filter } };
};

export const _setFilters = ({ filters }: { filters: GraphQLWhereParams }): Action => {
  return { type: 'SET_FILTERS', payload: { filters } };
};

export const _refresh = (): Action => {
  return { type: 'REFRESH' };
};

export const _setAdvancedFilters = ({ filters }: { filters: GraphQLFilterConfig[] }): Action => {
  return { type: 'SET_ADVANCED_FILTERS', payload: { filters } };
};

export const _handleCustomFilter = ({ filters, filterIndex, buttonIndex }: handleCustomFiltersProps): Action => {
  const customFiltersButton = filters.slice(0);
  const newFilter = customFiltersButton[filterIndex];
  newFilter.buttons[buttonIndex].enabled = !newFilter.buttons[buttonIndex].enabled;
  return { type: 'SET_HANDLE_CUSTOM_FILTER', payload: { customFiltersButton } };
};

export const _handleQuickFilter = ({ filters, filterIndex, buttonIndex }: handleCustomFiltersProps): Action => {
  const quickFiltersButtons = filters.slice(0);
  const newFilter = quickFiltersButtons[filterIndex];
  newFilter.buttons[buttonIndex].enabled = !newFilter.buttons[buttonIndex].enabled;
  return { type: 'SET_HANDLE_QUICK_FILTER', payload: { quickFiltersButtons } };
};

export const _handleSearch = ({ query }: { query: GraphQLSearchParams | null }): Action => {
  return { type: 'SET_HANDLE_SEARCH', payload: { query } };
};

export const _setPaginator = ({ limit, page, start, sort }: setPaginatorProps): Action => {
  return { type: 'SET_HANDLE_PAGINATOR', payload: { limit, page, start, sort } };
};

export const _resetState = ({ queryStringMode = false }): Action => {
  return { type: 'RESET_STATE', payload: { queryStringMode } };
};

export const _showModal = (showModal: boolean): Action => {
  return { type: 'SHOW_MODAL', payload: showModal };
};

export const _toggleCreateModal = (createModal: boolean): Action => {
  return { type: 'CREATE_MODAL', payload: createModal };
};

export const _selectedCreateTab = (selectedCreateTab: number): Action => {
  return { type: 'CREATE_MODAL_TAB', payload: selectedCreateTab };
};

export const _addProductLoading = (addProductLoading: boolean): Action => {
  return { type: 'ADD_PRODUCT_LOADING', payload: addProductLoading };
};

export const _addProductState = (addProductState: any): Action => {
  return { type: 'ADD_PRODUCT_STATE', payload: addProductState };
};

export const _handleOpenReadOnlyDrawer = ({
  readOnlyDrawerOpen,
  routeToQuery,
  filterToQuery,
  selectedDrawerMode,
  loadingReadOnlyDrawer,
}: any): Action => {
  return {
    type: 'OPEN_READONLY_DRAWER',
    payload: { readOnlyDrawerOpen, routeToQuery, filterToQuery, selectedDrawerMode, loadingReadOnlyDrawer },
  };
};

export const _handleCloseReadOnlyDrawer = ({ readOnlyDrawerOpen, routeToQuery, filterToQuery }: any): Action => {
  return {
    type: 'CLOSE_READONLY_DRAWER',
    payload: { readOnlyDrawerOpen, routeToQuery, filterToQuery },
  };
};

export const _setReadOnlyDrawerData = ({ readOnlyDrawerData }: any): Action => {
  return {
    type: 'SET_READONLY_DRAWERDATA',
    payload: { readOnlyDrawerData },
  };
};

export const _setGetItems = ({
  result,
  searchQuery,
  selectedCheckboxs,
  start,
  limit,
  filters,
  onSortBy,
  page,
  customer,
  toCreateItem,
  showSnackbar,
  snackbarMessage,
  selectedItem,
  selectedDrawerMode,
  quickFiltersButtons,
}: setGetItemsProps): Action => {
  return {
    type: 'SET_GET_ITEMS',
    payload: {
      result,
      searchQuery,
      selectedCheckboxs,
      filters,
      start,
      limit,
      page,
      onSortBy,
      customer,
      toCreateItem,
      showSnackbar,
      snackbarMessage,
      selectedItem,
      selectedDrawerMode,
      quickFiltersButtons,
    },
  };
};
