import { State } from '../types';

const getDefaultParams = (state: State) => {
  return {
    filters: state.list.filters,
    filtersToEdit: state.quickFiltersButtons,
    limit: state.list.paginator.limit,
    onSortBy: state.list.paginator.sort,
    page: state.list.page,
    searchQuery: state.list.searchQuery,
    start: state.list.paginator.start,
  };
};

export default getDefaultParams;
