import { useState, useEffect } from 'react';

const useTable2 = ({ onRowClick, onSort, sortBy }: any) => {
  const [iconState, setIconState] = useState(true);

  useEffect(() => {
    if (onSort && sortBy.length) onSort(sortBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const handleSortBy = (column: any) => column.getSortByToggleProps({ title: undefined });

  const handleRowClick = (id: any) => onRowClick && onRowClick(id);

  return {
    handleRowClick,
    handleSortBy,
    iconState,
    setIconState,
  };
};

export default useTable2;
