import { Marker } from 'react-map-gl';
import MarkerPin from './markerpin';

const MarkerComponent = ({ marker, mode, color, onClick, backgroundColor }: any) => (
  //@ts-ignore
  <Marker key={`marker-${marker.index}`} longitude={marker.longitude} latitude={marker.latitude}>
    <span onClick={onClick}>
      <MarkerPin mode={mode} number={marker.index} color={color} size={20} backgroundColor={backgroundColor} />
    </span>
  </Marker>
);
export default MarkerComponent;
