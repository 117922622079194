import { StrictMode } from 'react';
import { render } from 'react-dom';
import App from './containers/app';
import * as serviceWorker from './serviceWorker';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-components/build/theme.default';

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
