import { DateTime } from 'luxon';

const useParserChart = ({ data, allData, view, newGraphicData }: any) => {
  let parsedData: any = [];
  const byDateLabel = data.map((el: any) => DateTime.fromISO(el.label).toFormat('dd/MM/yyyy'));

  if (view === 'byProduct') {
    const products = newGraphicData.map((el: any) => el.byProduct.map((el: any) => el.name));
    const byDay = newGraphicData.map((el: any) => el.byProduct);
    const productsValues = byDay.map((el: any) => el.map((d: any) => d.total));
    parsedData = { xData: byDateLabel, yData: [], mobile: [], desktop: [], products, productsValues };
  } else if (view === 'byPlatform') {
    const mobile = newGraphicData.map((el: any) => el.byPlatform.mobile.total);
    const desktop = newGraphicData.map((el: any) => el.byPlatform.desktop.total);
    parsedData = { xData: byDateLabel, yData: [], mobile, desktop };
  } else {
    const byDateValue = newGraphicData.map((el: any) => el.value);
    parsedData = { xData: byDateLabel, yData: byDateValue, mobile: [], desktop: [], products: [], productsValues: [] };
  }

  return {
    parsedData,
    allData,
  };
};

export default useParserChart;
