import { Passholder } from 'components';
import { TL } from 'utils';
import styles from './styles.module.scss';

const PassholderList = ({ data }: any) => (
  <div className={styles.container}>
    {!data || data.length === 0 ? (
      <span className="H300Medium">{TL('backend_guest_no_guests_found')}</span>
    ) : (
      <div className={styles.passholdersList}>
        {data.map((passholder: any, i: number) => (
          <Passholder key={i} passholder={passholder} />
        ))}
      </div>
    )}
  </div>
);
export default PassholderList;
