interface Props {
  data: any[];
  orderBy: string;
  desc?: boolean;
}

const orderBy = ({ data = [], orderBy = '', desc = false }: Props): any[] => {
  if (data.length === 0 || !orderBy || (!data[0][orderBy] && data[0][orderBy] !== 0)) return [];
  const _data = [...data];

  if (orderBy === 'name') {
    if (desc) {
      return _data.sort((a, b) => b[orderBy][0].localeCompare(a[orderBy][0]));
    }
    return _data.sort((a, b) => a[orderBy][0].localeCompare(b[orderBy][0]));
  }

  if (desc) {
    return _data.sort((a, b) => b[orderBy] - a[orderBy]);
  }
  return _data.sort((a, b) => a[orderBy] - b[orderBy]);
};

export default orderBy;
