import { useState } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { Icon } from 'components';
import { useConfig } from 'hooks';
import { TL } from 'utils';
import { Props } from './types';
import styles from './style.module.scss';
import './styles.scss';

export default function Selector({
  options,
  value,
  onChange,
  id,
  name,
  containerClass,
  className,
  disableUnderline = true,
  hideIcon = true,
}: Props) {
  const [open, setOpen] = useState(false);
  const { colors } = useConfig();

  if (!options || !Array.isArray(options)) return null;

  return (
    <div className={`${styles.limitSelectorContainer} ${containerClass} H300Medium`} onClick={() => setOpen(!open)}>
      <Select
        className={className}
        value={value}
        id={id}
        name={name}
        onChange={onChange}
        classes={{ root: styles.root }}
        disableUnderline={disableUnderline}
        open={open}
        IconComponent={hideIcon ? () => null : undefined}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {TL(option.label)}
          </MenuItem>
        ))}
      </Select>
      {open ? (
        <Icon icon="ChevronUp" color={colors.grey_5} size="SC" />
      ) : (
        <Icon icon="ChevronDown" color={colors.grey_5} size="SC" />
      )}
    </div>
  );
}
