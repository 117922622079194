import { Props } from './types';
import style from './style.module.scss';

const OverlayComponent = ({ onClose }: Props) => (
  <div className={style.overlay} onClick={onClose}>
    &nbsp;
  </div>
);

export default OverlayComponent;
