import { DateTime } from 'luxon';
import { getLocale } from 'logic';
import { configMenu } from 'services';
import { Props } from './types';

const formatDates = ({ date, full = true, format, timeago }: Props) => {
  const dateFormat = configMenu.dateFormat || 'dd/MM/yyyy';
  const fullDateFormat = configMenu.fullDateFormat || 'dd/MM/yyyy HH:mm';
  let parsedDate;

  if (format) parsedDate = DateTime.fromFormat(date, format);
  else parsedDate = DateTime.fromISO(date);

  if (timeago) {
    const units: any = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

    const dateTime = DateTime.fromISO(date);
    const diff = dateTime.diffNow().shiftTo(...units);
    const unit = units.find((unit: any) => diff.get(unit) !== 0) || 'second';

    const relativeFormatter = new Intl.RelativeTimeFormat(getLocale().label, { numeric: 'auto' });
    return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
  }
  if (full) return parsedDate.toFormat(fullDateFormat);
  return parsedDate.toFormat(dateFormat);
};

export default formatDates;
