import styles from './styles.module.scss';
const imageParseURL = process.env.REACT_APP_PASSHOLDER_IMAGE_PARSER;

const PassholderPhoto = ({ src, name = '' }: any) => {
  if (src) {
    return (
      <a href={`${imageParseURL}${src}`} target={'_blank'} rel={'noreferrer'}>
        <picture className={styles.photo}>
          <img src={`${imageParseURL}${src}`} />
        </picture>
      </a>
    );
  } else {
    return (
      <div className={styles.placeholder}>
        <span className="H800Heavy">{name}</span>
      </div>
    );
  }
};

export default PassholderPhoto;
