import ReactEcharts from 'echarts-for-react';

const PieChart = ({ data }: any) => {
  const parsedData = data?.items?.map((el: any) => {
    return { name: el.name, value: el.quantity };
  });

  const option = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '5%',
      left: 'center',
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '20',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: parsedData,
      },
    ],
  };

  return <ReactEcharts option={option} />;
};

export default PieChart;
