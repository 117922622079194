import { StatusIndicator } from 'components';
import useLiveStatus from './useLiveStatus';
import styles from './styles.module.scss';

const LiveStatus = ({ logo, path }: any) => {
  const { loading, now, succeessfulResponse } = useLiveStatus(path);

  if (loading) return null;

  return (
    <div className={styles.content}>
      <img src={logo} alt="source provided by skidata" className={styles.image} />
      {succeessfulResponse ? <StatusIndicator color={'green'} /> : <StatusIndicator color={'red'} />}
      {now && <p className="H300Medium">{now}h.</p>}
    </div>
  );
};
export default LiveStatus;
