import { requester } from 'logic';
import { CONNECTV3 } from 'utils';
import { TableRoute } from 'interfaces';
import { State } from '../types';

const onSaveItem = async (route: TableRoute, state: State) => {
  const currentItem = state.selectedItem.item;
  const { selectedDrawerMode } = state;
  if (!route.queries || (!route.queries.endpoint && !route.queries.set)) return Promise.reject();
  const setEndpoint = route.queries.endpoint || route.queries.set;

  const currentTab = route[selectedDrawerMode];
  if (!currentTab || !currentTab.tabs) return Promise.reject();

  // Guardamos el ID para enviárselo a la ConnectV3
  const postData: any = {};
  postData['data'] = currentItem;
  postData['id'] = currentItem.id;
  try {
    const res = await requester.requestWithBearer('post', `${CONNECTV3}${setEndpoint}`, postData);
    return res;
  } catch (error) {
    return error;
  }

  // Reemplazar si el update nos devuelve el fullName y el PhoneNumber+prefix
  // const currentItems = [...state.list.items];
  // res.id = String(res.id);

  // const newItems = currentItems.map((item) => (String(item.id) === String(res.id) ? res : item));

  // setState({
  //   ...state,
  //   list: { ...state.list, items: newItems },
  //   selectedItem: { open: false, item: false },
  //   selectedDrawerMode: 'view',
  //   showSnackbar: true,
  //   snackbarMessage: TL('backend_table_screen_successful_edit'),
  // });
};

export default onSaveItem;
