import { executeCount, executeQuery } from '../config/apolloClient';
import { logged, requester } from 'logic';
import { QuickFilterGroup, TableRouteField } from '../interfaces/config';
import {
  GraphQLFilter,
  GraphQLFilterConfig,
  GraphQLQuery,
  Mode,
  GraphQLSearchParams,
  TotalGraphQLParams,
} from '../interfaces/graphql';
import { STRAPI } from '../utils/definitions';

class ApiSelector {
  getTotalItems(url: string, mode: Mode, data: TotalGraphQLParams) {
    if (mode === 'apirest') return this.apiRestGet(url);
    if (mode === 'graphql') return this.totalGraphQL(data);
  }

  totalGraphQL = async ({ field, variables }: TotalGraphQLParams) => {
    try {
      const total = await executeCount(field, variables);
      return total;
    } catch (error) {
      return undefined;
    }
  };

  apiRestGet = async (endpoint: string) => {
    const token = logged.getSessionToken();
    const url = `${STRAPI}${endpoint}`;
    try {
      const total = await requester.get(url, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return total;
    } catch (error) {
      console.log('errr', error);
    }
  };

  getItems = async (query: GraphQLQuery | string, data: any, mode: Mode, parser = 'items') => {
    try {
      if (mode === 'apirest' && typeof query === 'string') {
        const result = await this.apiRestGet(query);

        if (parser === 'orders') return this.ordersParser(result);
        return this.itemsParser(result);
      }
      if (mode === 'graphql' && typeof query !== 'string') {
        const result = await this.executeQueryGraphQL(query, data);

        if (parser === 'orders') return this.ordersParser(result);
        return this.itemsParser(result);
      }
    } catch (error) {
      return [];
    }
  };

  executeQueryGraphQL = async (query: GraphQLQuery, data: any) => {
    try {
      const result = await executeQuery(query, data);

      return result;
    } catch (error) {
      return [];
    }
  };

  itemsParser(data: any) {
    const items: Array<any> = [];
    if (data.length) {
      data.forEach((element: any) => {
        const aux = { ...element };
        ['visible', 'enabled'].forEach((field) => {
          if (aux[field] === null) {
            aux[field] = false;
          }
        });
        items.push(aux);
      });
    }

    return items;
  }

  ordersParser(data: any) {
    const items: Array<any> = [];
    if (data.length) {
      data.forEach((element: any) => {
        const aux = { ...element };
        aux.currency = 'EUR';
        ['visible', 'enabled'].forEach((field) => {
          if (aux[field] === null) {
            aux[field] = false;
          }
        });
        items.push(aux);
      });
    }

    return items;
  }

  filtersApirestParser(url: string, filters: Array<GraphQLFilterConfig>) {
    let URL = url;
    const queryString = [];

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      const sufix = filter.condition !== '=' ? filter.condition : '';
      queryString.push(`${filter.field}${sufix}=${encodeURI(filter.value)}`);
    }
    URL = URL + queryString.join('&');

    return URL;
  }

  filtersGraphQLParser(filters: Array<GraphQLFilterConfig>) {
    const data: Array<GraphQLFilter> = [];
    let _data;
    filters.forEach((filter) => {
      let field = filter.sortBy || filter.field;
      // si es un campo "nested", filtramos por su entidad de forma global
      if (field.includes('.')) {
        field = field.split('.')[0];
      }
      if (field === 'faf') {
        _data = [
          {
            purchaser: {
              email: filter.value,
            },
          },
        ];
      }

      const sufix = filter.condition !== '=' ? filter.condition : '';
      const filterLabel = `${field}${sufix}`;
      const alreadyExists = data.findIndex((f) => Object.keys(f).includes(filterLabel));
      // no repetido (lógica AND):
      if (alreadyExists === -1) data.push({ [`${filterLabel}`]: filter.value });
      else {
        // repetido (lógica OR):
        const currentFilterValue = data[alreadyExists][filterLabel];
        if (typeof currentFilterValue === 'string') {
          const arr = [currentFilterValue, filter.value];
          data[alreadyExists][filterLabel] = arr;
        } else if (Array.isArray(currentFilterValue)) {
          currentFilterValue.push(filter.value);
        }
      }
    });
    return { _where: _data || data };
  }

  extractEnabledFilters(filters: QuickFilterGroup[]) {
    const enabledFilters: GraphQLFilterConfig[] = [];
    filters.forEach((group, x) => {
      group.buttons.forEach((btn) => {
        if (btn.enabled === true) {
          btn.filters.forEach((btnFilter) => {
            enabledFilters.push(btnFilter);
          });
        }
      });
    });
    return enabledFilters;
  }

  putSearchFields(fields: Array<TableRouteField>, value: string): GraphQLSearchParams {
    const searchQuery = [];
    for (let i = 0; i < fields.length; i++) {
      const f = fields[i];
      let id = f.sortBy || f.id;
      if (id.includes('.')) {
        id = id.split('.')[0];
      }
      const alreadyExists = searchQuery.findIndex((f) => Object.keys(f).includes(`${id}_contains`));
      if (alreadyExists === -1) {
        searchQuery.push({
          [`${id}_contains`]: value,
        });
      }
    }
    return { _or: searchQuery };
  }
}

export default new ApiSelector();
