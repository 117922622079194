import { requester, getLocale } from 'logic';
import translations from 'utils/locales/translations.json';
import { TRANSLATIONS } from 'utils/definitions';
import { Locales } from 'interfaces/config';

// @to-do hacer opcional los locales que aparecen en el config de traducciones, pero poder controlar que viene uno de ellos
export type Translations = { [lang in Locales]: Translation };

export type Translation = { [x: string]: string };

class TranslationService {
  // @ts-ignore
  currentLanguage: Locales = getLocale();

  translations: Translations = translations;

  async initialize() {
    try {
      const data: Translations | null = await requester.get(TRANSLATIONS);
      if (!data || typeof data !== 'object') return null;

      this.translations = data;
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  translate = (key: string, lang: Locales = this.currentLanguage) => {
    if (!key || typeof key !== 'string' || key === '') return '';
    const currentLangKeys = this.translations[lang];
    if (currentLangKeys && currentLangKeys[key]) return currentLangKeys[key];
    if (process.env.NODE_ENV === 'development') return `${key}`;
    return key;
  };

  changeLanguage = (lang: Locales) => {
    this.currentLanguage = lang;
  };
}

const service = new TranslationService();

export default service;
