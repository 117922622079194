import { SpinnerLoader } from 'components';
import styles from './style.module.scss';
import { Props } from './types';

const Loading = ({ overlay }: Props) => (
  <div className={styles.loadingAnimation}>
    <div className={`${styles.dots} ${overlay ? styles.overlay : ''}`}>
      <div className={styles.logoWrapper}>
        <div className={styles.logoInnerWrapper}>
          <SpinnerLoader />
        </div>
      </div>
    </div>
  </div>
);
export default Loading;
