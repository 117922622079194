export const getNestedValue = (obj, path) => {
  if (!path) return obj;
  if (!path.includes('.')) return obj[path];
  const properties = path.split('.');
  if (properties.length === 0 || !obj[properties[0]]) return undefined;
  return getNestedValue(obj[properties.shift()], properties.join('.'));
};

export const setNestedValue = (obj, path, value) => {
  if (!path.includes('.')) {
    obj[path] = value;
    return;
  }
  const properties = path.split('.');
  if (properties.length === 0 || !obj[properties[0]]) return undefined;
  return setNestedValue(obj[properties.shift()], properties.join('.'), value);
};

export default getNestedValue;
