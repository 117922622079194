export const ErrorMessages = {
  required: 'backend_forms_validations_required',
  email: 'backend_forms_validations_email',
  repeatEmail: 'backend_forms_validations_repeat_field',
  repeatPassword: 'backend_forms_validations_repeat_field',
  '+47': 'backend_forms_validations_norway_prefix_phone',
  Norway: 'backend_forms_validations_norway_country_postal_code',
};

export const Validations = {
  '+47':
    /^[+]?(?=(?:[^\dx]*\d){8}$)(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?)(?:[ -]?(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?))*(?:[ ]?(?:x|ext)\.?[ ]?\d{1,5})?$/,
  Norway: /^\d{4}$/,
};
