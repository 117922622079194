import { useContext } from 'react';
import ConfigContext from 'context/ConfigContext';

const useConfig = () => {
  const {
    channelIcon,
    channelId,
    channels,
    clientName,
    colors,
    currency,
    currentChannel,
    destinationId,
    destinationName,
    loading,
    logos,
    netsEasyClient,
    netsEasyStatusHealthLink,
    quickFiltersColors,
    routes,
    setChannelId,
    setChannels,
    setColors,
    setCurrentChannel,
    setDestinationId,
    setQuickFiltersColors,
    setRoutes,
    setSession,
    skidataStatusHealtLink,
  } = useContext(ConfigContext);

  return {
    channelIcon,
    channelId,
    channels,
    clientName,
    colors,
    currency,
    currentChannel,
    destinationId,
    destinationName,
    loading,
    logos,
    netsEasyClient,
    netsEasyStatusHealthLink,
    quickFiltersColors,
    routes,
    setChannelId,
    setChannels,
    setColors,
    setCurrentChannel,
    setDestinationId,
    setQuickFiltersColors,
    setRoutes,
    setSession,
    skidataStatusHealtLink,
  };
};

export default useConfig;
