import styles from './styles.module.scss';

const DTAImage = ({ src }: any) => {
  return (
    <picture className={styles.image}>
      <img src={src} />
    </picture>
  );
};

export default DTAImage;
