import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { configMenu } from 'services';
import { Props } from './types';
import styles from './styles.module.scss';

const DatepickerInput = ({ onChange, label, value, className, hideLabel = false }: Props) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      autoOk
      variant="inline"
      inputVariant="outlined"
      label={label}
      format={configMenu.dateFormat || 'dd/MM/yyyy'}
      value={value}
      placeholder="DD/MM/YYYY"
      className={`${styles.input} ${className}`}
      InputAdornmentProps={{ position: 'start' }}
      onChange={onChange}
    />
  </MuiPickersUtilsProvider>
);

export default DatepickerInput;
