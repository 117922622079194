import useChip from './useChip';
import { useConfig } from 'hooks';
import { Props } from './types';
import styles from './styles.module.scss';

const Chip = ({ isActive = true, value, children, className, onClick, disabled }: Props) => {
  const { quickFiltersColors } = useConfig();
  const { color, backgroundColor, borderColor } = useChip({ isActive, value, quickFiltersColors });

  const handleClick = !disabled ? onClick : undefined;

  return (
    <div
      onClick={handleClick}
      className={`${handleClick ? styles.container : styles.containerWithoutHover} ${className} H300Medium`}
      style={{ backgroundColor, color, borderColor }}
    >
      {children}
    </div>
  );
};

export default Chip;
