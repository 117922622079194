import mockConfigAdminLocales from 'config/by_role/config_admin_locales.json';
import mockConfigAdminDestinations from 'config/by_role/config_admin_destinations.json';
import mockConfigDefault from 'config/by_role/config_default.json';
import mockConfigBjorliColors from 'config/by_role/config_colors.json';
import mockConfigAdminChannels from 'config/by_role/config_admin_channels.json';
import { getLocalStorage, setLocalStorage } from '../utils/localStorage';
import { Config } from '../interfaces/config';
import { CONNECTV3 } from 'utils';
import { requester } from 'logic';

class ConfigMenu {
  config: Config | null = null;

  initialize = async (role = 'default') => {
    const res = await this.getUserConfig(role);
    return res;
  };

  async getUserConfig(role: string) {
    try {
      const defaultLocale = { id: 'en', label: 'en' };
      const { colors, destinations, links, locales, loginLabel, logos, quickFiltersColors } = await requester.post(
        `${CONNECTV3}/config`,
        { role }
      );

      // const destinations: any = mockConfigAdminDestinations;
      // destinations[0].channels = mockConfigAdminChannels;

      // const logos = mockConfigDefault.logos;

      this.config = {
        defaultLocale: { id: 'en', label: 'en' },
        colors,
        destinations,
        links,
        locales,
        loginLabel,
        logos,
        quickFiltersColors,
      };
      return { defaultLocale, colors, quickFiltersColors, locales, destinations, links, loginLabel, logos };
    } catch (error) {
      console.log({ error });
      const config_default = mockConfigDefault;
      const colors = mockConfigBjorliColors.colors;
      const quickFiltersColors = mockConfigBjorliColors.quickFiltersColors;
      const locales = mockConfigAdminLocales;
      const destinations: any = mockConfigAdminDestinations;
      const channels = mockConfigAdminChannels;
      return { config_default, colors, quickFiltersColors, locales, destinations, channels };
    }
  }

  get currentRoutes() {
    const destinationId = getLocalStorage('destinationId') || 0;
    const channelId = getLocalStorage('channelId') || 0;
    const userConfig = this.config;
    if (!userConfig || destinationId === false || channelId === false) {
      return null;
    }

    const dest = userConfig.destinations.findIndex((d) => d.id === destinationId);
    if (dest === -1) return null;

    const currentDestination = userConfig.destinations[dest];
    if (!currentDestination) return null;

    const channel = currentDestination.channels.find((c) => c.id === channelId);
    if (!channel || !channel.routes || !Array.isArray(channel.routes)) return null;
    const routes = userConfig.destinations[destinationId].channels[channelId].routes;
    const channels = userConfig.destinations[destinationId].channels;
    const currentChannel = userConfig.destinations[destinationId].channels[channelId];
    const locales = userConfig.locales;
    setLocalStorage('routes', routes);
    setLocalStorage('channels', channels);
    setLocalStorage('currentChannel', currentChannel);
    setLocalStorage('locales', locales);
    return routes;
  }

  getDefaultRoute() {
    const routes = this.currentRoutes;
    if (routes && Array.isArray(routes) && routes.length) {
      return routes[0].id;
    }
    return '';
  }

  //Administrator image loginBackground
  get loginBackground() {
    if (this.config && this.config.logos && this.config.logos.loginBackground) {
      return this.config.logos.loginBackground;
    }
    return 'https://ik.imagekit.io/spotlio/bjorli/bjorli-login-background_i_TPcUzox1c.png?updatedAt=1628068491630';
  }

  //Retrieve all availables locales
  get allLocales() {
    if (this.config && this.config.locales) {
      return this.config.locales;
    }
    return [{ id: 'en', label: 'English' }];
  }

  //Spotlio Logo white
  get mainLogo() {
    const destinationId = getLocalStorage('destinationId') || 0;
    if (this.config?.destinations[destinationId].icon) {
      return this.config?.destinations[destinationId].icon;
    }
    if (this.config && this.config.logos && this.config.logos.main) {
      return this.config.logos.main;
    }
    return 'https://ik.imagekit.io/spotlio/logo_spotlio_k6ogcfF-R.png';
  }

  //Spotlio Logo white
  get sidebarLogoCollapsed() {
    const destinationId = getLocalStorage('destinationId') || 0;
    if (this.config?.destinations[destinationId].icon) {
      return this.config?.destinations[destinationId].icon;
    }
    if (this.config && this.config.logos && this.config.logos.collapsedSidebar) {
      return this.config.logos.collapsedSidebar;
    }
    return 'https://ik.imagekit.io/spotlio/logo_spotlio_k6ogcfF-R.png';
  }

  get loginLabel() {
    const destinationId = getLocalStorage('destinationId') || 0;
    if (this.config?.destinations[destinationId].label) {
      return this.config?.destinations[destinationId].label;
    }
    if (this.config && this.config.loginLabel && this.config.loginLabel) {
      return this.config.loginLabel;
    }
    return '';
  }

  get dateFormat() {
    if (this.config && this.config.dateFormat && this.config.dateFormat) {
      return this.config.dateFormat;
    }
    return undefined;
  }

  get fullDateFormat() {
    if (this.config && this.config.fullDateFormat && this.config.fullDateFormat) {
      return this.config.fullDateFormat;
    }
    return undefined;
  }
}

export default new ConfigMenu();
