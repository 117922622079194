import { Icon } from 'components';
import { useConfig } from 'hooks';
import { Props } from './types';
import styles from './style.module.scss';

export default function ItemMenuIcon({ icon }: Props) {
  const { colors } = useConfig();

  return (
    <div className={styles.itemMenuIconContainer}>
      <Icon icon={icon as any} size="M" color={colors.white} />
    </div>
  );
}
