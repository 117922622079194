import Paper from '@material-ui/core/Paper';
import { OverlayComponent } from 'components';
import { DrawerProps } from './types';
import style from '../style.module.scss';

const DrawerComponent = ({ children, open, onClose, openClassName = style.open }: DrawerProps) => (
  <>
    <Paper elevation={6} className={`${style.rightSidebar} ${open ? openClassName : ''}`}>
      {children}
    </Paper>
    {open ? <OverlayComponent onClose={onClose} /> : null}
  </>
);

export default DrawerComponent;
