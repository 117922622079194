import { FieldError } from 'components';
import { ChildrenProps } from './types';
import styles from './style.module.scss';

const BasicInput = ({ key, field, inputs, error }: ChildrenProps) => (
  <div key={key}>
    <p>{field.label}</p>
    <div className={styles.container}>
      {inputs.map((unit: string, i: number) => (
        <div className={styles.content} key={`${key}${unit}${i}`}>
          <p style={{ textTransform: 'capitalize' }}>{unit}</p>
          <select>
            {field.units.map((unit: string, x: number) => (
              <option style={{ textAlign: 'center' }} key={unit}>
                {unit.toUpperCase()}
              </option>
            ))}
          </select>
        </div>
      ))}
    </div>
    {error && <FieldError error={error} />}
  </div>
);

export default BasicInput;
