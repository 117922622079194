import { withShortFormat, withQuantityOfDecimals, withoutDecimals, withThousandSeparator } from './logic';
import { Props } from './types';
import styles from './styles.module.css';

const aligns: any = {
  center: styles.center,
  right: styles.right,
  left: styles.left,
  noalign: '',
};

const useCurrency = ({
  align = 'center',
  amount: _amount,
  numberOfDecimals,
  reverseUnit,
  shortFormat,
  thousandSeparator,
  withDecimals,
  withDecimalSeparator,
}: Props) => {
  let amount: string | number = Number(_amount);

  if (shortFormat) {
    amount = withShortFormat(amount);
  } else {
    if (numberOfDecimals) amount = withQuantityOfDecimals(amount, numberOfDecimals);
    if (!withDecimals) amount = withoutDecimals(amount);
    if (thousandSeparator) amount = withThousandSeparator(amount, thousandSeparator);
  }

  const containerStyles = aligns[align];
  const unitMarginStyles = reverseUnit ? styles.unitMarginLeft : styles.unitMarginRight;

  return {
    containerStyles,
    shortFormat,
    thousandSeparator,
    total: amount,
    unitMarginStyles,
    withDecimals,
    withDecimalSeparator,
  };
};

export default useCurrency;
