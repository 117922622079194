import { Props } from './types';

const LoginFormImage = ({ primaryColor, secondaryColor }: Props) => {
  if (!primaryColor || !secondaryColor) return null;

  return (
    <svg width="138" height="136" viewBox="0 0 138 136" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M84.8989 93.4731C82.686 91.7481 80.1074 90.5528 77.3608 89.9788C76.0916 89.7162 74.6924 89.6082 73.5916 90.2751C73.4658 90.3306 73.3576 90.4193 73.2783 90.5317C73.1991 90.6441 73.152 90.7758 73.1419 90.9129C73.1828 91.1123 73.2945 91.2901 73.4565 91.4134C74.9942 92.7682 76.8734 93.6764 78.8904 94.0394C80.9074 94.4025 82.9853 94.2066 84.8989 93.4731Z"
          fill={primaryColor}
        />
        <path
          d="M84.7259 93.233C83.0526 91.7156 81.5147 90.0554 80.1294 88.2711C78.7442 86.4712 77.8603 84.3366 77.5674 82.0843C77.4994 81.7484 77.5045 81.4019 77.5824 81.0682C77.6602 80.7345 77.8089 80.4214 78.0185 80.1503C81.7208 83.7119 84.4674 88.1054 84.7259 93.233Z"
          fill={primaryColor}
        />
        <path
          d="M84.8605 93.439C84.7142 91.2023 84.9777 88.9577 85.638 86.8157C86.316 84.661 87.7777 82.839 89.7342 81.71C90.2615 83.4086 89.7721 85.2487 89.1045 86.8963C88.0777 89.4525 86.6377 91.8224 84.8419 93.9112"
          fill={primaryColor}
        />
        <path
          d="M88.0818 100.918C85.6742 101.057 83.3691 101.939 81.4846 103.444C79.5267 104.9 78.0621 106.921 77.2879 109.235C79.6779 109.337 81.8121 107.9 83.4542 106.173C85.0962 104.446 86.3016 102.529 88.0662 100.952"
          fill={primaryColor}
        />
        <path
          d="M88.8208 102.134C88.1216 99.6437 88.0561 97.0178 88.63 94.4955C89.204 91.9731 90.3994 89.6342 92.1073 87.6914C92.9739 92.6836 91.9105 98.1193 88.8208 102.134Z"
          fill={primaryColor}
        />
        <path
          d="M93.3955 108.882C91.2199 108.519 88.9854 108.805 86.9715 109.704C84.9576 110.604 83.2537 112.077 82.0727 113.94C81.9737 114.051 81.9063 114.187 81.8775 114.333C81.8488 114.479 81.8598 114.63 81.9094 114.77C81.994 114.868 82.1008 114.945 82.221 114.994C82.3412 115.043 82.4713 115.062 82.6006 115.051C84.7228 115.131 86.7182 114.052 88.4106 112.768C90.1031 111.484 91.6169 109.953 93.4436 108.881"
          fill={primaryColor}
        />
        <path
          d="M93.2639 108.527C92.5177 104.796 92.8469 100.929 94.213 97.378C94.7522 95.9872 95.5666 94.5467 96.9599 94.0119C97.0166 93.9833 97.0785 93.9666 97.1418 93.9628C97.2052 93.959 97.2686 93.9682 97.3283 93.9898C97.4298 94.0559 97.5029 94.1576 97.533 94.2749C97.8728 95.3242 97.9516 96.4406 97.7624 97.5272C97.5728 98.6079 97.2947 99.6712 96.9312 100.706C96.3061 102.64 95.8682 104.738 94.612 106.331C94.3116 106.712 94.0378 107.113 93.7924 107.531C93.54 107.943 93.5235 108.103 93.2986 108.422"
          fill={primaryColor}
        />
        <path
          d="M105.839 111.914C105.586 109.836 105.787 107.728 106.431 105.736C107.074 103.744 108.144 101.916 109.565 100.379C109.773 100.166 110.037 99.9356 110.33 99.9958C110.777 100.084 110.829 100.695 110.764 101.145C110.167 105.126 108.46 108.859 105.839 111.914Z"
          fill={primaryColor}
        />
        <path
          d="M107.016 112.9C109.373 113.715 111.878 114.01 114.36 113.764C116.842 113.518 119.241 112.737 121.392 111.474C119.154 110.052 116.494 109.448 113.862 109.763C111.253 110.095 108.809 110.984 107.01 112.899"
          fill={primaryColor}
        />
        <path
          d="M108.334 110.412C111.426 106.374 116.787 104.081 121.874 104.34C121.068 106.34 119.223 107.764 117.241 108.532C115.199 109.209 113.073 109.596 110.924 109.683C110.094 109.759 108.954 109.816 108.359 110.403"
          fill={primaryColor}
        />
        <path
          d="M107.596 97.6381C108.648 95.2897 109.453 92.8385 109.999 90.3238C110.544 87.7896 110.289 85.1489 109.271 82.7653C106.308 87.2974 105.484 92.8598 107.499 97.8861"
          fill={primaryColor}
        />
        <path
          d="M116.355 94.2846C119.787 89.3327 126.116 85.7411 132.145 85.7673C129.251 91.2607 122.518 95.0448 116.355 94.2846Z"
          fill={primaryColor}
        />
        <path
          d="M114.429 95.1547C118.673 96.2515 122.06 99.3845 125.92 101.468C126.723 101.961 127.639 102.237 128.58 102.27C129.015 102.31 129.452 102.215 129.832 101.998C130.212 101.782 130.516 101.454 130.704 101.06C130.892 100.665 130.954 100.222 130.882 99.7912C130.811 99.36 130.609 98.9612 130.303 98.6485C129.785 98.0644 129.154 97.5912 128.448 97.2573C124.248 94.9767 119.549 94.4512 114.809 95.0797"
          fill={primaryColor}
        />
        <path
          d="M111.62 97.8962C112.571 98.2762 112.852 98.8151 113.556 99.5537C115.962 102.064 118.915 103.987 122.184 105.171C122.525 105.296 122.979 105.378 123.184 105.081C123.388 104.784 123.203 104.483 123.034 104.202C121.747 102.335 119.98 100.849 117.919 99.9007C115.897 98.9065 114.171 97.7747 111.639 97.7181"
          fill={primaryColor}
        />
        <path
          d="M111.682 79.4257C113.283 81.2369 115.244 82.6939 117.441 83.7033C119.637 84.7126 122.02 85.2521 124.437 85.2872C124.655 85.3078 124.873 85.2517 125.054 85.1286C125.424 84.8005 125.083 84.2014 124.723 83.8582C121.151 80.43 116.571 78.6865 111.67 79.4244"
          fill={primaryColor}
        />
        <path
          d="M115.145 78.5457C117.662 77.0632 120.495 76.1978 123.41 76.0201C126.326 75.8424 129.243 76.3576 131.922 77.5235C129.56 79.3034 126.667 80.2342 123.71 80.1652C120.782 80.1322 117.883 79.584 115.145 78.5457Z"
          fill={primaryColor}
        />
        <path
          d="M114.684 70.0185C114.703 66.1017 115.262 63.0625 115.396 59.1395C115.428 58.8247 115.377 58.4414 115.098 58.2987C114.71 58.1027 114.32 58.5667 114.141 58.9683C113.387 60.7202 113.043 62.6215 113.138 64.5265C113.232 66.4315 113.761 68.2896 114.684 69.9585"
          fill={primaryColor}
        />
        <path
          d="M114.361 70.4173C117.064 69.0427 119.467 67.1438 121.429 64.8313C122.879 63.2281 124.161 61.2597 124.089 59.1038C123.194 59.0351 122.295 59.1625 121.454 59.4771C120.613 59.7917 119.851 60.286 119.221 60.9253C117.965 62.2137 116.99 63.748 116.357 65.4316C115.664 67.0827 115.127 68.8038 114.349 70.4161"
          fill={primaryColor}
        />
        <path
          d="M120.83 69.3127C126.836 67.7657 132.516 65.1574 137.604 61.6108C135.794 60.6564 133.585 60.8912 131.618 61.457C127.172 62.6495 123.316 65.4265 120.775 69.265"
          fill={primaryColor}
        />
        <path
          d="M115.918 72.7259C118.788 73.4372 121.741 73.7552 124.696 73.6712C127.59 73.4888 130.396 72.6374 133.172 71.7888C133.411 71.7466 133.625 71.6168 133.773 71.4246C133.829 71.3058 133.847 71.1724 133.824 71.0432C133.8 70.9139 133.737 70.795 133.644 70.7031C133.46 70.5177 133.232 70.3809 132.982 70.305C130.174 69.1939 127.011 69.2525 124.068 69.9218C121.26 70.5671 118.528 71.507 115.918 72.7259Z"
          fill={primaryColor}
        />
        <path
          d="M97.6385 75.7294C96.627 71.3269 97.8494 66.7701 100.499 63.1247C101.231 65.2019 100.851 67.5415 100.117 69.6373C99.3839 71.7332 98.2056 73.7903 97.6508 75.9349"
          fill={primaryColor}
        />
        <path
          d="M90.7944 69.9474C88.3635 69.3339 85.8312 69.2341 83.3595 69.6545C80.8878 70.075 78.531 71.0063 76.4396 72.3891C78.8323 73.259 81.4327 73.3831 83.8974 72.7449C86.3394 72.1188 88.7112 71.2453 90.976 70.1381"
          fill={primaryColor}
        />
        <path
          d="M85.981 66.9452C83.646 66.1854 81.4521 65.3488 78.9618 65.4972C77.7298 65.5465 76.5229 65.8604 75.4231 66.4176C74.3232 66.9748 73.3562 67.7622 72.5878 68.7264C74.786 69.4393 77.135 69.5506 79.3908 69.0487C81.6397 68.5437 83.8381 67.8358 85.9592 66.9338"
          fill={primaryColor}
        />
        <path
          d="M93.7077 68.875C91.899 66.9643 90.045 65.0121 87.6867 63.8355C85.3284 62.659 82.3458 62.4014 80.1578 63.88C81.8956 65.5222 84.3072 66.2696 86.6415 66.8266C88.9758 67.3837 91.8744 68.0447 93.8778 69.3452"
          fill={primaryColor}
        />
        <path
          d="M93.692 68.9938C90.8915 64.5265 91.0432 57.7441 93.6846 53.1822C95.5195 58.2926 95.5221 63.8817 93.692 68.9938Z"
          fill={primaryColor}
        />
        <path
          d="M106.178 61.2148C107.252 59.7713 108.125 58.1996 109.147 56.7189C110.109 55.198 111.344 53.8677 112.79 52.795C114.257 51.7322 116.082 51.2864 117.873 51.5532C116.721 53.8938 115.093 55.9681 113.093 57.6436C111.093 59.3191 108.766 60.5588 106.259 61.2834"
          fill={primaryColor}
        />
        <path
          d="M103.855 62.9005C103.069 61.0498 102.277 58.6083 101.006 57.0654C99.7359 55.5224 97.8901 54.3332 95.8962 54.33C96.4646 56.2792 97.4799 58.0689 98.8616 59.5567C100.243 61.0446 101.953 62.1895 103.855 62.9005Z"
          fill={primaryColor}
        />
        <path
          d="M104.696 60.996C104.323 58.9278 104.187 56.8239 104.29 54.725C104.397 52.607 105.218 50.5878 106.62 48.9965C107.723 53.2735 107.401 57.505 104.789 61.0674"
          fill={primaryColor}
        />
        <path
          d="M108.984 49.5605C111.014 46.068 115.124 44.1214 119.15 43.7379C119.654 43.6569 120.17 43.7001 120.654 43.8637C120.895 43.9491 121.106 44.104 121.259 44.3087C121.412 44.5135 121.501 44.759 121.515 45.0143C121.501 45.6921 120.831 46.1336 120.234 46.4456C116.733 48.2315 112.906 49.2911 108.984 49.5605Z"
          fill={primaryColor}
        />
        <path
          d="M109.759 46.4927C110.484 44.1002 111.222 41.6742 112.561 39.5632C113.899 37.4522 115.946 35.6638 118.386 35.1844C118.173 37.7235 116.513 39.8987 114.708 41.702C112.903 43.5052 110.826 45.1086 109.504 47.2788"
          fill={primaryColor}
        />
        <path
          d="M109.296 48.1498C109.01 45.1787 109.749 41.6726 108.675 38.8939C107.913 36.9444 106.361 35.3712 105.79 33.369C104.79 34.9227 104.429 36.8024 104.783 38.6156C105.141 40.4126 105.818 42.131 106.782 43.6895C107.691 45.2812 108.498 46.5048 109.296 48.1498Z"
          fill={primaryColor}
        />
        <path
          d="M92.1983 119.161C92.1229 119.143 94.2703 110.596 99.6494 88.0297C105.028 65.4637 109.454 47.175 109.524 47.1916C109.594 47.2082 105.298 65.5158 99.9165 88.0935C94.5347 110.671 92.268 119.177 92.1983 119.161Z"
          fill={secondaryColor}
        />
        <path
          d="M120.082 77.5716C119.846 77.6601 119.602 77.7254 119.354 77.7667L117.351 78.2207C115.661 78.6049 113.333 79.1815 110.794 79.9464C108.255 80.7114 105.922 81.3355 104.514 82.3009C103.958 82.6809 103.495 83.1836 103.163 83.7701C102.934 84.1906 102.876 84.4548 102.847 84.4458C102.843 84.3798 102.85 84.3136 102.868 84.2498C102.913 84.0663 102.978 83.888 103.06 83.7176C103.377 83.0893 103.845 82.5496 104.422 82.1474C105.271 81.5665 106.202 81.1142 107.183 80.8051C108.272 80.4251 109.47 80.0383 110.745 79.6653C113.289 78.9129 115.631 78.3678 117.335 78.027C118.186 77.8566 118.877 77.7356 119.361 77.6354C119.599 77.5917 119.84 77.5703 120.082 77.5716Z"
          fill={secondaryColor}
        />
        <path
          d="M126.8 64.9579C126.732 65.0285 126.659 65.0932 126.582 65.1516L125.93 65.6487L123.482 67.4551L115.34 73.3326C112.157 75.6277 109.384 77.8449 107.452 79.5305C106.483 80.3729 105.715 81.074 105.191 81.5602C104.946 81.787 104.746 81.9765 104.584 82.0978C104.421 82.2191 104.372 82.2921 104.36 82.2849C104.412 82.2037 104.474 82.1294 104.545 82.0639C104.674 81.9211 104.863 81.7185 105.117 81.4626C105.623 80.9146 106.373 80.2235 107.326 79.3614C109.827 77.1315 112.447 75.0372 115.173 73.0874C118.36 70.8049 121.26 68.7508 123.368 67.2754L125.869 65.5404L126.553 65.0826C126.63 65.0322 126.713 64.9905 126.8 64.9579Z"
          fill={secondaryColor}
        />
        <path
          d="M111.906 75.6738C111.832 75.6303 112.588 74.2917 113.377 72.5726C114.166 70.8534 114.611 69.3928 114.687 69.4126C114.506 70.5534 114.147 71.6586 113.623 72.6879C113.285 73.4281 112.904 74.1483 112.483 74.8451C112.333 75.1483 112.138 75.4276 111.906 75.6738Z"
          fill={secondaryColor}
        />
        <path
          d="M102.692 86.1282C102.543 85.9527 102.411 85.7622 102.301 85.5598L101.317 83.9822C100.483 82.6362 99.3243 80.7766 98.0221 78.7342C96.72 76.6918 95.5146 74.8754 94.6441 73.5376L93.6061 71.9605C93.4707 71.7728 93.3547 71.5718 93.2598 71.3607C93.4239 71.5238 93.5703 71.7038 93.6966 71.8978C93.9599 72.2549 94.3386 72.774 94.7935 73.4269C95.7046 74.7209 96.9331 76.5457 98.2418 78.5828C99.5505 80.6198 100.677 82.5001 101.472 83.8781C101.869 84.5612 102.184 85.1217 102.395 85.5155C102.518 85.7075 102.618 85.9131 102.692 86.1282Z"
          fill={secondaryColor}
        />
        <path
          d="M99.584 81.1693C98.9405 80.295 98.5238 79.2748 98.3712 78.1999C98.1823 77.2548 98.1644 76.2834 98.3183 75.3319L98.443 75.3447C98.3675 75.553 98.3143 75.6616 98.2906 75.6591C98.2668 75.6567 98.3034 75.5344 98.3266 75.3088L98.4454 75.321C98.4071 76.2619 98.4711 77.2041 98.6363 78.1312C98.8756 79.1662 99.1923 80.1818 99.584 81.1693Z"
          fill={secondaryColor}
        />
        <path
          d="M95.5514 74.867C95.2535 74.5935 94.923 74.3579 94.5674 74.1656C93.6519 73.7857 92.6662 73.6045 91.6755 73.6339C90.6802 73.607 89.6844 73.6652 88.699 73.8078C88.2977 73.891 87.891 73.9454 87.482 73.9706C87.8653 73.797 88.2715 73.6791 88.6882 73.6206C89.6841 73.4206 90.6996 73.3358 91.7148 73.3679C92.7418 73.3344 93.7616 73.5506 94.6866 73.9978C95.0636 74.1832 95.368 74.489 95.5514 74.867Z"
          fill={secondaryColor}
        />
        <path
          d="M94.6094 73.3479C94.6052 73.3895 94.0406 73.0433 93.0672 72.5891C90.5526 71.4374 87.7858 70.9462 85.0284 71.1618C83.9755 71.2455 83.3265 71.3707 83.3189 71.3279C83.4599 71.259 83.6104 71.2118 83.7654 71.1878C84.1686 71.0931 84.5767 71.0208 84.9879 70.9716C87.7854 70.653 90.6147 71.1632 93.1246 72.439C93.5021 72.6289 93.8669 72.8429 94.217 73.0795C94.4773 73.2323 94.6113 73.3301 94.6094 73.3479Z"
          fill={secondaryColor}
        />
        <path
          d="M104.49 68.3094C104.147 66.93 103.89 65.5306 103.721 64.1194C103.47 62.7143 103.309 61.2946 103.24 59.869C103.583 61.2484 103.84 62.6478 104.009 64.059C104.264 65.4635 104.424 66.8835 104.49 68.3094Z"
          fill={secondaryColor}
        />
        <path
          d="M104.567 68.3173C104.279 67.4369 103.929 66.5785 103.517 65.7487C103.046 64.9532 102.471 64.224 101.808 63.5804C102.077 63.6704 102.319 63.8275 102.511 64.0369C103.014 64.4885 103.436 65.0231 103.759 65.6176C104.259 66.4314 104.538 67.3622 104.567 68.3173Z"
          fill={secondaryColor}
        />
        <path
          d="M98.9926 121.965C98.9629 121.962 98.95 121.504 98.824 120.687C98.6235 119.537 98.3094 118.409 97.8862 117.32C96.4337 113.747 94.5276 110.377 92.2149 107.29C90.9104 105.446 89.6377 103.701 88.5536 102.047C87.5963 100.588 86.7506 99.0582 86.0238 97.4715C85.5403 96.4011 85.1505 95.2908 84.859 94.153C84.7568 93.7464 84.687 93.4332 84.6556 93.2139C84.6311 93.1059 84.6163 92.9959 84.6114 92.8853C84.6114 92.8853 84.7277 93.3293 84.9583 94.1212C85.2898 95.2397 85.705 96.3316 86.2005 97.3877C86.9348 98.9509 87.7825 100.458 88.737 101.898C89.8045 103.538 91.0838 105.278 92.4001 107.124C94.7278 110.226 96.6224 113.631 98.032 117.245C98.4385 118.35 98.7231 119.496 98.8805 120.663C98.9197 120.98 98.943 121.299 98.9502 121.618C98.9756 121.732 98.9898 121.848 98.9926 121.965Z"
          fill={secondaryColor}
        />
        <path
          d="M114.488 106.713C114.221 106.846 113.946 106.964 113.666 107.067C112.926 107.389 112.21 107.762 111.522 108.184C110.506 108.82 109.541 109.536 108.638 110.324C107.611 111.202 106.538 112.244 105.439 113.355C103.245 115.602 101.2 117.576 99.9361 119.186C99.2899 119.984 98.8174 120.668 98.4983 121.145C98.3487 121.401 98.181 121.647 97.9966 121.879C97.9966 121.879 98.0045 121.802 98.0905 121.667C98.1764 121.532 98.2631 121.331 98.4069 121.1C98.8235 120.4 99.2817 119.725 99.7789 119.08C101.489 117.012 103.315 115.042 105.247 113.18C106.343 112.092 107.433 111.004 108.477 110.139C109.398 109.359 110.384 108.657 111.423 108.042C112.125 107.626 112.862 107.272 113.626 106.984C113.895 106.88 114.117 106.825 114.248 106.778C114.378 106.732 114.484 106.701 114.488 106.713Z"
          fill={secondaryColor}
        />
        <path
          d="M114.216 112.152C113.665 112.219 113.108 112.242 112.553 112.221C111.224 112.223 109.898 112.346 108.59 112.587C107.296 112.865 106.057 113.36 104.929 114.053C104.063 114.594 103.625 115.065 103.598 115.032C103.67 114.898 103.766 114.778 103.881 114.677C104.168 114.387 104.483 114.124 104.82 113.892C105.946 113.137 107.207 112.604 108.534 112.324C109.859 112.073 111.208 111.975 112.555 112.029C113.111 112.021 113.667 112.062 114.216 112.152Z"
          fill={secondaryColor}
        />
        <path
          d="M104.139 114.5C104.061 114.438 104.914 113.379 105.645 111.93C106.377 110.481 106.6 109.13 106.693 109.158C106.714 109.454 106.683 109.751 106.602 110.036C106.447 110.732 106.216 111.409 105.915 112.054C105.594 112.692 105.21 113.296 104.769 113.857C104.397 114.262 104.174 114.504 104.139 114.5Z"
          fill={secondaryColor}
        />
        <path
          d="M120.77 92.6684C120.682 92.7118 120.59 92.7485 120.495 92.7781L119.687 93.049C119.334 93.1567 118.905 93.2986 118.438 93.5206C117.971 93.7426 117.385 93.9043 116.786 94.1848L115.843 94.5978C115.516 94.7442 115.209 94.9287 114.838 95.0945C114.143 95.4252 113.434 95.8502 112.675 96.2823C111.061 97.259 109.522 98.3544 108.071 99.5593C106.64 100.785 105.3 102.114 104.061 103.534C103.506 104.209 102.967 104.837 102.524 105.47C102.299 105.783 102.066 106.071 101.866 106.374L101.292 107.233C100.916 107.795 100.636 108.3 100.362 108.746C100.088 109.192 99.8732 109.59 99.7078 109.915L99.3064 110.666C99.263 110.754 99.2102 110.837 99.1489 110.914C99.1713 110.817 99.2072 110.724 99.2554 110.637C99.3481 110.436 99.4706 110.179 99.6105 109.869C99.7958 109.459 100.003 109.06 100.232 108.673C100.489 108.213 100.766 107.677 101.135 107.127L101.693 106.254C101.886 105.95 102.121 105.65 102.345 105.337C102.784 104.692 103.318 104.051 103.867 103.376C105.1 101.931 106.442 100.585 107.883 99.3478C109.343 98.1331 110.897 97.0349 112.529 96.0633C113.293 95.6378 114.015 95.2139 114.721 94.8904C115.074 94.7228 115.404 94.5467 115.738 94.395L116.698 93.9897C117.325 93.7242 117.878 93.541 118.37 93.3636C118.863 93.1862 119.296 93.0568 119.654 92.9616L120.475 92.74C120.57 92.7028 120.669 92.6787 120.77 92.6684Z"
          fill={secondaryColor}
        />
        <path
          d="M108.263 99.3211C108.413 98.9543 108.674 98.6434 109.009 98.4317C109.792 97.8794 110.745 97.6224 111.7 97.7064C112.635 97.7983 113.543 98.0732 114.371 98.5154C114.727 98.6685 115.058 98.8745 115.353 99.1265C114.986 99.0119 114.63 98.8669 114.287 98.6928C113.464 98.3086 112.582 98.0657 111.678 97.9742C110.782 97.9026 109.886 98.1171 109.119 98.587C108.818 98.8129 108.532 99.0582 108.263 99.3211Z"
          fill={secondaryColor}
        />
        <path
          d="M106.334 100.923C106.76 100.203 107.09 99.4296 107.315 98.6234C107.452 97.7917 107.483 96.9458 107.406 96.1063C107.541 96.3258 107.62 96.575 107.637 96.8322C107.735 97.4464 107.716 98.0738 107.579 98.6806C107.448 99.297 107.2 99.8826 106.849 100.406C106.58 100.75 106.37 100.927 106.334 100.923Z"
          fill={secondaryColor}
        />
        <path
          d="M93.5052 135.5H43.2603C40.3657 135.484 37.6664 134.206 35.6302 132.328C33.594 130.45 32.9875 127.911 33.0002 125.268L33.0001 10.4341C33.0128 7.78992 34.1747 5.25845 36.2306 3.39579C38.2865 1.53313 41.0682 0.491618 43.9647 0.500051L94.1395 0.710232C97.0341 0.728059 99.8029 1.793 101.839 3.67157C103.875 5.55013 105.011 8.05533 105 10.6978L105 125.828C104.987 128.474 103.303 130.741 101.245 132.604C99.1876 134.468 96.4036 135.509 93.5052 135.5Z"
          fill={secondaryColor}
        />
        <path
          d="M93.9292 4.20105L84.2555 4.15683C83.9402 4.16207 83.629 4.22508 83.3398 4.34226C83.0507 4.45945 82.7891 4.62851 82.5702 4.83981C82.3512 5.0511 82.1791 5.30047 82.0637 5.57369C81.9483 5.8469 81.8918 6.13861 81.8975 6.43214V8.06425C81.9033 8.35778 81.8468 8.64948 81.7314 8.9227C81.616 9.19591 81.4439 9.44529 81.2249 9.65658C81.0059 9.86787 80.7444 10.0369 80.4552 10.1541C80.166 10.2713 79.8549 10.3343 79.5396 10.3396L60.6887 10.2591C60.0522 10.2423 59.4488 9.99124 59.0108 9.56095C58.5729 9.13067 58.3361 8.5563 58.3523 7.96375V6.33164C58.3686 5.73841 58.1312 5.16344 57.6923 4.73305C57.2534 4.30265 56.6489 4.05203 56.0116 4.03624H52.6129L44.1483 4.00006C42.1294 3.99257 40.1898 4.73142 38.7557 6.05427C37.3216 7.37712 36.5103 9.17579 36.5001 11.0551L36.5002 124.672C36.4921 126.55 36.7714 128.354 38.1917 129.688C39.612 131.023 41.5233 131.99 43.5411 132H93.3419C94.3412 132.004 95.3316 131.825 96.2565 131.473C97.1815 131.121 98.0229 130.603 98.7327 129.948C99.4425 129.293 100.007 128.515 100.393 127.657C100.78 126.799 101.495 125.879 101.5 124.949V11.3285C101.507 10.3973 101.317 9.47397 100.94 8.61137C100.563 7.74877 100.008 6.96388 99.3042 6.30172C98.6008 5.63956 97.764 5.11314 96.8417 4.75267C95.9193 4.39219 94.9296 4.20473 93.9292 4.20105Z"
          fill="#FAFAFA"
        />
        <g clipPath="url(#clip1)" filter="url(#filter0_d)">
          <path
            d="M56.6722 124.3C53.9128 128.37 50.0716 131.571 45.5885 133.534C41.1055 135.497 36.1618 136.143 31.3235 135.4C26.4853 134.656 21.9481 132.552 18.2314 129.328C14.5148 126.104 11.769 121.892 10.3084 117.172C8.84788 112.453 8.73161 107.417 9.97294 102.643C11.2143 97.8678 13.763 93.5467 17.3273 90.1741C20.8916 86.8014 25.3274 84.5135 30.1268 83.5724C34.9261 82.6313 39.895 83.0751 44.4644 84.8528L48.5452 78.8317L57.4436 78.783L53.9154 70.9138L59.0472 63.3745L67.9349 63.3273L64.4174 55.4565L68.8214 48.9666L74.2858 48.9273L72.151 44.1311L74.71 40.3514L81.6231 36.603L85.1138 44.4808L87.5375 50.3222L57.1088 95.205C59.8801 99.5682 61.317 104.657 61.2393 109.832C61.1617 115.008 59.5729 120.041 56.6722 124.3ZM24.4171 125.229C25.638 126.077 27.0792 126.54 28.5583 126.561C30.0375 126.581 31.4882 126.158 32.7268 125.345C33.9654 124.532 34.9363 123.366 35.5167 121.994C36.097 120.621 36.2607 119.105 35.9871 117.636C35.7135 116.167 35.0148 114.811 33.9794 113.741C32.9441 112.671 31.6186 111.934 30.1707 111.623C28.7228 111.313 27.2176 111.443 25.8454 111.997C24.4733 112.551 23.2959 113.504 22.4622 114.735C21.9079 115.551 21.5187 116.471 21.317 117.44C21.1152 118.409 21.1048 119.41 21.2864 120.385C21.4679 121.36 21.8379 122.289 22.3751 123.12C22.9123 123.952 23.6062 124.668 24.4171 125.229Z"
            fill={secondaryColor}
          />
          <path
            d="M54.7747 122.894C52.0152 126.965 48.1741 130.165 43.691 132.128C39.2079 134.091 34.2642 134.738 29.426 133.994C24.5878 133.25 20.0505 131.146 16.3339 127.923C12.6173 124.699 9.87148 120.486 8.41093 115.767C6.95037 111.047 6.83409 106.012 8.07542 101.237C9.31675 96.4624 11.8655 92.1413 15.4298 88.7686C18.9941 85.396 23.4299 83.1081 28.2293 82.167C33.0286 81.2259 37.9975 81.6697 42.5669 83.4474L46.6477 77.4263L55.5461 77.3776L52.0178 69.5084L57.1497 61.9691L66.0374 61.9219L62.5199 54.0511L66.9238 47.5612L72.3882 47.5219L70.2535 42.7257L72.8124 38.946L80.7925 37.2339L85.6399 48.9168L55.2112 93.7996C57.9826 98.1628 59.4195 103.251 59.3418 108.427C59.2642 113.603 57.6754 118.635 54.7747 122.894ZM22.5196 123.824C23.7405 124.671 25.1817 125.134 26.6608 125.155C28.14 125.176 29.5907 124.753 30.8293 123.94C32.0679 123.127 33.0388 121.961 33.6192 120.588C34.1995 119.216 34.3632 117.699 34.0896 116.23C33.8159 114.761 33.1172 113.406 32.0819 112.336C31.0466 111.265 29.7211 110.528 28.2732 110.218C26.8253 109.907 25.3201 110.037 23.9479 110.591C22.5758 111.145 21.3984 112.098 20.5647 113.33C20.0104 114.146 19.6212 115.065 19.4195 116.035C19.2177 117.004 19.2073 118.005 19.3889 118.98C19.5704 119.954 19.9404 120.884 20.4776 121.715C21.0148 122.546 21.7087 123.263 22.5196 123.824Z"
            fill={primaryColor}
          />
          <path
            d="M82.677 47.6178C82.542 47.5142 75.6975 57.2911 67.4821 69.4599C59.2667 81.6286 52.648 91.5853 52.7829 91.6889C52.9179 91.7925 59.7624 82.0156 67.9778 69.8468C76.1932 57.678 82.8119 47.7214 82.677 47.6178Z"
            fill="white"
          />
          <path
            d="M22.5192 123.824C22.5192 123.824 21.9965 123.405 21.2261 122.504C20.1512 121.235 19.5258 119.641 19.4488 117.975C19.3932 116.629 19.7092 115.296 20.3615 114.124C21.0138 112.952 21.9766 111.987 23.1423 111.338C24.308 110.688 25.6306 110.38 26.9621 110.447C28.2937 110.513 29.5818 110.953 30.6823 111.716C31.7828 112.479 32.6523 113.536 33.1936 114.768C33.7349 115.999 33.9267 117.358 33.7474 118.692C33.5682 120.025 33.025 121.281 32.1786 122.318C31.3323 123.356 30.2161 124.134 28.9549 124.566C27.3961 125.106 25.7019 125.105 24.1405 124.563C22.9893 124.184 22.4913 123.784 22.455 123.833C22.4187 123.883 22.5725 123.959 22.8313 124.152C23.2033 124.417 23.6037 124.639 24.025 124.812C25.6361 125.472 27.4238 125.539 29.0753 125.001C30.0547 124.687 30.9612 124.178 31.7415 123.503C32.5218 122.829 33.16 122.003 33.6186 121.075C34.0771 120.147 34.3466 119.134 34.4113 118.098C34.4759 117.061 34.3344 116.022 33.995 115.04C33.4699 113.454 32.454 112.08 31.0974 111.12C29.7409 110.16 28.1158 109.665 26.4624 109.709C25.4346 109.743 24.4245 109.985 23.4916 110.419C22.5588 110.854 21.7219 111.472 21.0302 112.239C20.3385 113.006 19.8059 113.905 19.4639 114.883C19.1218 115.862 18.9772 116.9 19.0384 117.937C19.1435 119.689 19.8549 121.351 21.0482 122.63C21.3564 122.991 21.7097 123.309 22.0991 123.577C22.3687 123.769 22.5117 123.847 22.5192 123.824Z"
            fill={secondaryColor}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="-1.85742"
          y="36.2771"
          width="105.715"
          height="107.394"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="3" dy="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <clipPath id="clip0">
          <rect width="137" height="135" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
        <clipPath id="clip1">
          <rect
            width="92"
            height="90"
            fill="white"
            transform="matrix(0.149143 0.988816 0.988816 -0.149143 -1.85742 49.7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LoginFormImage;
