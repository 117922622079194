import { useState } from 'react';
import { DTAImage, Accordion } from 'components';
import { TL } from 'utils';
import Skidata from '../../assets/skidata.png';
import styles from './styles.module.scss';

const DTAOrder = ({ data }: any) => {
  const [orderHistory, setOrderHistory]: any = useState({});

  const { Order } = data;
  const { Id, Date, ContactId, ConfirmationNumber, OrderItems } = Order;
  let { OrderItem } = OrderItems;

  if (OrderItem[0] && OrderItem[0].PackageOrderSubItems) {
    OrderItem = OrderItem[0].PackageOrderSubItems.OrderItem;
  } else {
    OrderItem = OrderItems.OrderItem;
  }

  const toggleAccordion = (idx: number) => {
    let value = true;
    if (orderHistory[idx]) value = false;
    setOrderHistory({ ...orderHistory, [idx]: value });
  };

  return (
    <div className={styles.dtaOrder}>
      <div className={styles.details}>
        <div className={styles.detailsContent}>
          <div>
            <span className={`H300Medium`}>{TL('backend_id')}: </span>
            <span className={`${styles.labelGrey} H300Light`}>{Id}</span>
          </div>
          <div>
            <span className={`H300Medium`}>{TL('backend_date')}: </span>
            <span className={`${styles.labelGrey} H300Light`}>{Date}</span>
          </div>
          <div>
            <span className={`H300Medium`}>{TL('backend_contact_id')}: </span>
            <span className={`${styles.labelGrey} H300Light`}>{ContactId}</span>
          </div>
          <div>
            <span className={`H300Medium`}>{TL('backend_confirmation_number')}: </span>
            <span className={`${styles.labelGrey} H300Light`}>{ConfirmationNumber}</span>
          </div>
          <span className={`H300Medium`}>{TL('backend_order_items')}:</span>
        </div>
        <img src={Skidata} alt="source provided by skidata" />
      </div>
      <div className={styles.items}>
        {OrderItem.map((i: any, idx: number) => {
          const { ProductId, OrderItems, OrderStateHistories, TicketItems, SalesStatus } = i;

          const values: any = {
            TicketItem: [],
            SalesStatus: [],
            OrderStateHistory: [],
          };

          if (TicketItems) values.TicketItem = TicketItems.TicketItem;
          if (OrderItems && OrderItems.OrderItem && OrderItems.OrderItem.SalesStatus) {
            values.SalesStatus.push(OrderItems.OrderItem);
          } else if (SalesStatus) {
            values.SalesStatus.push(SalesStatus);
          }
          if (OrderItems && Array.isArray(OrderItems.OrderItem)) values.SalesStatus = OrderItems.OrderItem;
          if (OrderStateHistories) values.OrderStateHistory = OrderStateHistories.OrderStateHistory;

          return (
            <div className={styles.item} key={`order_item_${idx}`}>
              <div className={styles.ticketItem}>
                {values.TicketItem.map((item: any, idx: number) => {
                  const { Contact } = item;
                  const { image } = Contact || { image: '' };
                  const {
                    FirstName,
                    LastName,
                    Id,
                    BirthDate,
                    AcceptSubmissionOfPersonalDataToThirdParties,
                    AcceptUsageOfPersonalData,
                    AcceptsMailing,
                  } = Contact?.Contact || {};

                  const SalesStatus = values?.SalesStatus[idx]?.SalesStatus
                    ? values?.SalesStatus[idx]?.SalesStatus
                    : values?.SalesStatus[idx];

                  return (
                    <div key={idx}>
                      {image && image.Data ? (
                        <DTAImage src={`data:image/jpeg;base64,${image.Data}`} key={`dta_image_${idx}`} />
                      ) : undefined}
                      <div className={image && image.Data ? '' : styles.doubleColumn}>
                        <div>
                          <span className={'H300Medium'}>
                            {FirstName} {LastName}
                          </span>
                        </div>
                        <div>
                          <span className={`H300Medium`}>{TL('backend_id')}: </span>
                          <span className={`${styles.labelGrey} H300Light`}>{Id}</span>
                        </div>
                        <div>
                          <span className={`H300Medium`}>{TL('backend_birth_date')}: </span>
                          <span className={`${styles.labelGrey} H300Light`}>{BirthDate}</span>
                        </div>
                        {SalesStatus ? (
                          <div>
                            <span className={`H300Medium`}>{TL('backend_sales_status')}: </span>
                            <span className={`${styles.labelGrey} H300Light`}>{SalesStatus}</span>
                          </div>
                        ) : undefined}
                        <div>
                          <span className={`H300Medium`}>{TL('backend_accept_usage_of_personal_data')}: </span>
                          <span className={`${styles.labelGrey} H300Light`}>
                            {AcceptUsageOfPersonalData?.toString()}
                          </span>
                        </div>
                        <div>
                          <span className={`H300Medium`}>{TL('backend_accepts_mailing')}: </span>
                          <span className={`${styles.labelGrey} H300Light`}>{AcceptsMailing?.toString()}</span>
                        </div>
                        <div>
                          <span className={`H300Medium`}>
                            {TL('backend_accept_submission_of_personal_data_to_third_parties')}:{' '}
                          </span>
                          <span className={`${styles.labelGrey} H300Light`}>
                            {AcceptSubmissionOfPersonalDataToThirdParties?.toString()}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                <span className={`H300Medium`}>{TL('backend_product_id')}: </span>
                <span className={`${styles.labelGrey} H300Light`}>{ProductId}</span>
              </div>
              <div>
                <div
                  role="button"
                  tabIndex={1}
                  onClick={() => toggleAccordion(idx)}
                  className={styles.toggleAccordion}
                  onKeyDown={(e) => {
                    const { key } = e;
                    if (key === ' ' || key === 'Enter') {
                      toggleAccordion(idx);
                    }
                  }}
                >
                  <span className="H300Medium">{TL('backend_order_state_history')}: </span>
                  <span className={`${styles.underline} H300Medium`}>
                    {orderHistory[idx] ? `${TL('backend_hide_history')}` : `${TL('backend_show_history')}`}
                  </span>
                </div>
                <Accordion open={orderHistory[idx]}>
                  <div className={styles.accordion}>
                    {values.OrderStateHistory.map((history: any, idx: number) => {
                      return (
                        <div key={`order_state_history_${idx}`}>
                          <span>{`{`}</span>
                          {Object.keys(history).map((h, idx) => {
                            const value = history[h];
                            return (
                              <div className={styles.keyValue} key={`state_${idx}`}>
                                <span className={`H300Medium`}>{h}: </span>
                                <span>{value}</span>
                              </div>
                            );
                          })}
                          <span>
                            {`}`}
                            {idx + 1 !== values.OrderStateHistory.length ? `,` : ''}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </Accordion>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DTAOrder;
