import ReactDOM from 'react-dom';
import { Icon } from 'components';
import { ModalProps } from './types';
import styles from './styles.module.scss';

function Modal({ config }: any) {
  const { closeFn, children, size, title } = config;

  return (
    <div className={`${styles.defaultModal} ${closeFn === undefined && styles.buttonHidden}`} aria-hidden={'false'}>
      <div
        className={`
          ${styles.modalBox}
          ${' '}
          ${size === 'small' ? styles.small : ''}
          ${' '}
          ${title ? styles.fixedTitle : ''}

        `}
      >
        {title && <div className={`${styles.titleRow} H400Heavy`}>{title}</div>}

        <div className={styles.closeBtn} onClick={closeFn} role="button" tabIndex={1}>
          <Icon icon="X" size="M" />
        </div>
        {children}
      </div>
      <div className={styles.modalOverlay} onClick={closeFn} />
    </div>
  );
}

const body = document.getElementsByTagName('body')[0];

export default function ModalComponent(props: ModalProps) {
  return ReactDOM.createPortal(<Modal config={props} />, body);
}
